import {
	createContext,
	useState,
	Dispatch,
	SetStateAction,
	useMemo,
	MutableRefObject,
} from 'react';
import { Geo } from '../../components/SearchLocation/locations.interface';
import { Category } from '../../interfaces/category.interface';
import { ProductInterface } from '../../interfaces/product.interface';
import { OrderInterface } from '../../components/OrdersTable/interfaces/Order.interface';

export interface TruckProps {
	truckType: string | undefined;
	quantity: number | undefined;
	payload: number | undefined;
	deliveryFrom: string | undefined;
	deliveryTo: string | undefined;
	deliveryTimeFrom: string | undefined;
	deliveryTimeTo: string | undefined;
}

export interface OrderProps {
	location?: Geo;
	group?: Category;
	category?: Category;
	product?: ProductInterface;
	locationPhoto?: string;
	trucks?: TruckProps[];
	deliveryIncluded?: boolean;
}

interface AdditionalProps {
	orderModalRef: MutableRefObject<HTMLDivElement | null>;
	order: OrderInterface | undefined;
	dontShowAnymore?: boolean;
}

interface OrderContextProps {
	order: OrderProps | undefined;
	setOrder: Dispatch<SetStateAction<OrderProps | undefined>>;
	search: string | undefined;
	setSearch: Dispatch<SetStateAction<string | undefined>>;
	selectedOrderId: number | undefined;
	setOrderId: Dispatch<SetStateAction<number | undefined>>;
	additional: AdditionalProps;
	setAdditional: Dispatch<SetStateAction<AdditionalProps>>;
}

export const OrderContext = createContext<OrderContextProps>({
	order: undefined,
	setOrder: () => null,
	selectedOrderId: undefined,
	setOrderId: () => null,
	search: undefined,
	setSearch: () => null,
	additional: {
		orderModalRef: { current: null },
		order: undefined,
	},
	setAdditional: () => {},
});

const OrderContextProvider = ({ children }: any) => {
	const [order, setOrder] = useState<OrderProps>();
	const [selectedOrderId, setOrderId] = useState<number>();
	const [search, setSearch] = useState<string>();
	const [additional, setAdditional] = useState<AdditionalProps>({
		orderModalRef: { current: null },
		order: undefined,
		dontShowAnymore: false,
	});

	const value = useMemo<OrderContextProps>(
		() => ({
			order,
			setOrder,
			selectedOrderId,
			setOrderId,
			search,
			setSearch,
			additional,
			setAdditional,
		}),
		[order, search, selectedOrderId, additional],
	);

	return (
		<OrderContext.Provider value={value}>{children}</OrderContext.Provider>
	);
};

export default OrderContextProvider;
