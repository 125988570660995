import { IconInterface } from '../interfaces/icon.interface';

const CardDownArrow = (props: IconInterface) => {
	const { color = '#A2A2A2' } = props;

	return (
		<svg
			width="52"
			height="42"
			viewBox="0 0 52 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M43.5 26V41M43.5 41L48.5 36M43.5 41L38.5 36"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51 21C51 11.5719 51 6.85786 48.0711 3.92893C45.1421 1 40.4281 1 31 1H21C11.5719 1 6.85786 1 3.92893 3.92893C1 6.85786 1 11.5719 1 21C1 30.4281 1 35.1421 3.92893 38.0711C6.85786 41 11.5719 41 21 41H31"
				stroke={color}
				strokeLinecap="round"
			/>
			<path d="M21 31H11" stroke={color} strokeLinecap="round" />
			<path d="M28.5 31H27.25" stroke={color} strokeLinecap="round" />
			<path d="M1 16L51 16" stroke={color} strokeLinecap="round" />
		</svg>
	);
};

export default CardDownArrow;
