import React, { useCallback, useEffect, useState } from 'react';
import {
	SypacBreadcrumbs,
	SypacButton,
	SypacCheckbox,
	SypacInput,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { StylesConfig } from 'react-select';
import { useGetProductsTmpl } from '../../../../hooks/use-get-products-tmpl';
import {
	CreateProductParams,
	ProductService,
	ProductStoreInterface,
} from '../../../../services/product.services';
import { T, useTranslate } from '@tolgee/react';
import { ProductInterface } from '../../../../interfaces/product.interface';
import Close from '../../../../assets/Close';
import { ChevronRightIcon } from '../../../../assets/ChevronRightIcon';
import { CompanyService } from '../../../../services/company.services';
import { toastVariant } from '../../../../components/CompaniesTable/toastVariant/toastVariant';
import { ProductLocation } from '../../../../components/ProductLocation/ProductLocation';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCategories } from '../../../../hooks/use-get-categories';
import Flag from 'react-world-flags';
import { COUNTRIES } from '../../../../components/DropDownOption/countries.constant';

const selectStyle: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		boxShadow: 'none',
		borderRadius: '8px',
	}),
	valueContainer: (styles) => ({
		...styles,
		padding: '6px 12px',
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: '14px',
		color: '#D3D4D5',
	}),
	menu: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		borderRadius: '8px',
		boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
		border: '1px solid #E8E8E8',
		overflow: 'hidden',
	}),
	menuList: (styles) => ({
		...styles,
		padding: '0px',
	}),
};

const selectStyleError: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		backgroundColor: '#ffffff',
		':focus': { borderColor: 'rgb(247, 72, 39)' },
		':hover': { borderColor: 'rgb(247, 72, 39)' },
		':focus-visible': { borderColor: 'rgb(247, 72, 39)' },
		':focus-within': { borderColor: 'rgb(247, 72, 39)' },
		boxShadow: 'none',
		borderColor: 'rgb(247, 72, 39)',
		borderRadius: '8px',
	}),
};

const SelectProduct: React.FC = () => {
	const { t } = useTranslate();
	const { storeId, groupId, categoryId } = useParams();
	const [userCountry, setUserCountry] = useState<string>();
	const [categories] = useGetCategories(
		{ ids: [categoryId!, groupId!], countryCode: userCountry },
		'producer',
	);
	const [groups] = useGetCategories(
		{
			countryCode: userCountry,
		},
		'producer',
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [products, _, isLoading] = useGetProductsTmpl(
		categoryId,
		100,
		0,
		'producer',
		userCountry,
	);
	const [store, setStore] = useState<ProductStoreInterface | undefined>();
	const [maxStockAvailability, setStockAvailability] =
		useState<number>(1000000);
	const [disableSend, setDisableSend] = useState<boolean>(false);
	const [selectedProduct, setSelectedProduct] = useState<ProductInterface>();
	const navigate = useNavigate();

	const getGroupName = (id: string) => {
		return categories?.items?.find((item) => item.id === id)?.name || 'No name';
	};

	const getParentName = () => {
		if (groups?.items?.length) {
			return groups.items[0]?.name;
		}
		return '';
	};

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setUserCountry(
				data.countryCode === 'MD'
					? 'MDA'
					: data.countryCode === 'PL'
					? 'POL'
					: '',
			);
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const getStoresCallback = useCallback(async () => {
		try {
			if (!storeId) {
				return;
			}
			const { data } = await ProductService.getProductStores({
				limit: 1,
				offset: 0,
				ids: [parseFloat(storeId)],
			});
			setStore(data?.items?.at(0));
		} finally {
		}
	}, [storeId]);

	useEffect(() => {
		getStoresCallback().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeId]);

	const formik = useFormik({
		initialValues: {
			sourceProductId: undefined,
			available: undefined,
			availableQuantity: undefined,
			unlimited: false,
			minOrderQuantity: undefined,
			maxOrderCapacity: undefined,
			pricePerUnit: undefined,
		},
		onSubmit: async (values) => {
			setDisableSend(true);
			let lat = store?.location?.coordinates?.at(1);
			let long = store?.location?.coordinates?.at(0);
			let countryCode = store?.countryCode;
			const addressLabel = store?.address;

			await ProductService.createProduct({
				...values,
				pricePerUnit: values.pricePerUnit
					? parseFloat(values.pricePerUnit)
					: undefined,
				minOrderQuantity: values.minOrderQuantity
					? parseFloat(values.minOrderQuantity)
					: undefined,
				availableQuantity: values.available
					? parseFloat(values.available)
					: undefined,
				sourceProductId: values.sourceProductId
					? parseInt(values.sourceProductId)
					: null,
				maxOrderCapacity: values.maxOrderCapacity
					? parseInt(values.maxOrderCapacity)
					: null,
				lat,
				long,
				address: addressLabel,
				countryCode,
				productBaseId: storeId ? parseFloat(storeId) : undefined,
			} as CreateProductParams);
			navigate(`/products/${storeId}`);
			setDisableSend(false);
		},
		validateOnChange: true,
		validationSchema: Yup.object({
			sourceProductId: Yup.number().required(
				t('selectProduct.productTypeIsRequired', 'Product type is required'),
			),
			available: Yup.number()
				.positive(
					t(
						'selectProduct.minimumProductStock',
						'Minimum product stock is 0.1',
					),
				)
				.when(['availableQuantity', 'unlimited'], {
					is: (a: number, b: boolean) => {
						return a !== undefined || !b;
					},
					then: Yup.number().required(
						t(
							'selectProduct.productStockIsRequired',
							'Product stock is required',
						),
					),
				}),
			minOrderQuantity: Yup.number()
				.positive(
					t('selectProduct.minimumCapacityValue', 'Minimum capacity is 0.1'),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maxMinimumCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.minimumCapacityIsRequired',
						'Minimum capacity is required',
					),
				),
			maxOrderCapacity: Yup.number()
				.positive(
					t(
						'selectProduct.minimumMaxCapacityValue',
						'Minimum max order capacity is 0.1',
					),
				)
				.max(
					maxStockAvailability,
					t(
						'selectProduct.maximumMaxCapacityValue',
						'Maximum order capacity is the product stock available',
					),
				)
				.required(
					t(
						'selectProduct.maximumCapacityIsRequired',
						'Maximum capacity is required',
					),
				),
			pricePerUnit: Yup.number()
				.positive(
					t('selectProduct.netPriceMinimumValue', 'Minimum Net price is 0.1'),
				)
				.required(
					t('selectProduct.netPriceIsRequired', 'Net price is required'),
				),
		}),
	});

	const changeAvailableStock = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setStockAvailability(
			formik.values.unlimited ? 1000000 : parseFloat(e.target.value) || 100000,
		);
		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	const changeUnlimited = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		setStockAvailability(
			e.target.checked ? 1000000 : formik.values.available || 100000,
		);
		if (formik.values.maxOrderCapacity) {
			formik.setTouched({ 'maxOrderCapacity': true });
		}
		if (formik.values.minOrderQuantity) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	const changeMaxOrderCapacity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (
			!(value && value > 0.1 && value < maxStockAvailability) &&
			!isNaN(value)
		) {
			formik.setFieldValue('maxOrderCapacity', value, false);
			formik.setFieldTouched('maxOrderCapacity', true, false);
			formik.validateField('maxOrderCapacity');
		}
	};

	const changeMinOrderQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik?.handleChange(e);
		const value = parseFloat(e.target.value);
		if (!(value && value > 0.1 && value < maxStockAvailability)) {
			formik.setTouched({ 'minOrderQuantity': true });
		}
	};

	if (isLoading) {
		return (
			<div className="w-full">
				<SypacText className="m-auto w-max italic">
					<span>Loading...</span>
				</SypacText>
			</div>
		);
	}

	const selectOption = (props: any) => {
		const { innerProps, innerRef, data, isSelected } = props;
		const type = [data.type, data.size]?.filter((r) => !!r)?.join(': ');
		return (
			<div
				ref={innerRef}
				{...innerProps}
				className={`m-1 p-2 hover:bg-gray-10-opacity-50 rounded-md border border-solid flex flex-row ${
					isSelected
						? 'bg-gray-10-opacity-50 border-gray-10'
						: 'bg-white border-white'
				}`}
			>
				<div
					style={{ backgroundImage: `url(${data.photoUrl})` }}
					className="h-11 w-11 rounded-lg bg-cover"
				/>
				<div className="flex flex-col ml-4">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{data.name}</p>
					</SypacText>
					<SypacText variant="overline-regular-large" className="mt-1">
						<p className="text-gray-60">{type}</p>
					</SypacText>
				</div>
			</div>
		);
	};

	const breadcrumbs = [
		{
			name: 'Country store',
			onClick: () => navigate(`/products/${storeId}`),
		},
		{
			name: getParentName(),
			onClick: () => navigate(`/products/${storeId}/groups`),
		},
		{
			name: getGroupName(categoryId!),
			onClick: () =>
				navigate(`/products/${storeId}/groups/${groupId}/categories`),
		},
		{
			name: 'Product info',
			onClick: () => {},
		},
	];

	return (
		<div className="w-[865px] h-full flex justify-center flex-col">
			<div className="flex justify-between items-center mb-12">
				<SypacText variant="body-normal-medium">
					<p className="text-gray-60">
						<T keyName="productGroup.addNewProduct">Add new product</T>
					</p>
				</SypacText>
				<SypacButton variant="secondary" size="medium">
					<button
						type="button"
						onClick={() => navigate(`/products/${storeId}`)}
						className="p-2 border border-solid border-gray-10 transition hover:border-primary-violet"
					>
						<Close />
					</button>
				</SypacButton>
			</div>

			<div className="flex flex-col gap-2 mb-12">
				<SypacText variant="heading-5">
					<p className="text-xl text-gray-80">
						<T keyName="productGroup.addProductInfo">Add product info</T>
					</p>
				</SypacText>

				<SypacBreadcrumbs>
					{breadcrumbs.map((item, i) => (
						<SypacLink key={i} variant="breadcrumbs">
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a
								className={`text-sm ${
									i === breadcrumbs.length - 1
										? 'text-cornflower-blue pointer-events-none'
										: 'text-gray-60 cursor-pointer'
								}`}
								onClick={(e) => {
									e.preventDefault();
									item.onClick?.();
								}}
							>
								{i === 0 ? (
									<div className="flex items-center gap-3">
										<Flag
											className="mb-[2px] object-cover border border-solid border-gray-10 rounded"
											code={userCountry}
											width={20}
											height={14}
										/>
										<SypacText variant="body-regular-medium">
											<p className="text-sm mt-[2.5px]">
												{
													COUNTRIES.find(
														(country) => country['alpha-3'] === userCountry,
													)?.label
												}
											</p>
										</SypacText>
									</div>
								) : (
									item.name
								)}
							</a>
						</SypacLink>
					))}
				</SypacBreadcrumbs>
			</div>

			<form onSubmit={formik.handleSubmit}>
				<div className="flex flex-col gap-4 border border-solid border-gray-10 rounded-xl bg-white py-3">
					<ProductLocation
						defaultValue={store?.address}
						daysTitle={store?.workingDays?.at(0).title}
						hoursTitle={store?.workingHours?.at(0).title}
					/>

					<div className="flex flex-col gap-4 p-3 border border-solid border-gray-10 rounded-10 bg-alabaster mx-3">
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="selectProduct.typeAndAvailability">
									2 — Type & availability:
								</T>
							</p>
						</SypacText>

						<div className="flex flex-col gap-2">
							<div className="grid grid-cols-2 gap-3">
								<div className="w-full">
									<SypacInput
										error={
											!!(
												formik.touched.sourceProductId &&
												formik.errors.sourceProductId
											)
										}
										className="mb-1"
									>
										<SypacText className="mb-1" variant="overline-normal-large">
											<p>
												<T keyName="selectProduct.productType">Product type</T>{' '}
												<span className="text-red">*</span>
											</p>
										</SypacText>

										<Select
											closeMenuOnSelect={true}
											value={
												formik.values.sourceProductId
													? {
															value: formik.values.sourceProductId,
															label: selectedProduct?.name,
													  }
													: ''
											}
											//@ts-ignore
											options={products?.items.map((r) => ({
												...r,
												value: r.id,
												label: r.name,
											}))}
											onChange={(value: any) => {
												setSelectedProduct(value);
												formik.setFieldValue('sourceProductId', value.id);
											}}
											placeholder={t(
												'selectProduct.selectProductType',
												'Select product type',
											)}
											components={{ Option: selectOption }}
											name="sourceProductId"
											onBlur={formik.handleBlur}
											styles={
												formik.touched.sourceProductId &&
												formik.errors.sourceProductId
													? { ...selectStyle, ...selectStyleError }
													: selectStyle
											}
										/>
										{formik.touched.sourceProductId &&
										formik.errors.sourceProductId ? (
											<span className="input-error">
												<T keyName="selectProduct.errorProductType">
													{formik.errors.sourceProductId}
												</T>
											</span>
										) : null}
									</SypacInput>
								</div>
								<div className="w-full">
									<SypacInput
										error={
											!!(formik.touched.available && formik.errors.available)
										}
										className="mb-1"
									>
										<SypacText className="mb-1" variant="overline-normal-large">
											<p>
												<T keyName="selectProduct.productStockAvailability">
													Product stock availability per (ton)
												</T>{' '}
												<span className="text-red">*</span>
											</p>
										</SypacText>

										<input
											className="w-full py-2.5 px-3 rounded-lg placeholder:text-gray-22"
											name="available"
											type="number"
											placeholder={t(
												'selectProduct.enterAvailableProductStock',
												'Enter available product stock',
											)}
											value={formik?.values.available}
											onChange={changeAvailableStock}
											disabled={formik?.values.unlimited}
										/>
										{formik.touched.available && formik.errors.available ? (
											<span className="bottom-helper">
												<T keyName="selectProduct.errorAvailable">
													{formik.errors.available}
												</T>
											</span>
										) : null}
									</SypacInput>
								</div>
							</div>
							<div className="flex items-center gap-4 ml-auto">
								<SypacCheckbox size="md">
									<input
										className="cursor-pointer"
										type="checkbox"
										name="unlimited"
										checked={formik?.values.unlimited}
										onChange={changeUnlimited}
									/>
									{formik.touched.unlimited && formik.errors.unlimited ? (
										<span className="bottom-helper">
											<T keyName="selectProduct.errorUnlimited">
												{formik.errors.unlimited}
											</T>
										</span>
									) : null}
								</SypacCheckbox>
								<SypacText variant="overline-normal-large">
									<p className="text-sm text-gray-40 pt-1">
										<T keyName="selectProduct.unlimitedQuantity">
											Unlimited quantity
										</T>
									</p>
								</SypacText>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-4 p-3 border border-solid border-gray-10 rounded-10 bg-alabaster mx-3">
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="selectProduct.priceAndOrder">
									3 — Price and order:
								</T>
							</p>
						</SypacText>

						<div className="grid grid-cols-3 gap-3">
							<div className="w-full">
								<SypacInput
									error={
										!!(
											formik.touched.minOrderQuantity &&
											formik.errors.minOrderQuantity
										)
									}
								>
									<SypacText className="mb-1" variant="overline-normal-large">
										<p>
											<T keyName="selectProduct.minimumOrderCapacity">
												Minimum order capacity per day (tons)
											</T>{' '}
											<span className="text-red">*</span>
										</p>
									</SypacText>
									<input
										className="w-full py-2.5 px-3 rounded-lg placeholder:text-gray-22"
										type="number"
										name="minOrderQuantity"
										placeholder={t(
											'selectProduct.enterCapacity',
											'Enter capacity',
										)}
										value={formik?.values.minOrderQuantity}
										onChange={changeMinOrderQuantity}
									/>
									{formik.touched.minOrderQuantity &&
									formik.errors.minOrderQuantity ? (
										<span className="bottom-helper">
											<T keyName="selectProduct.errorMinOrderQuantity">
												{formik.errors.minOrderQuantity}
											</T>
										</span>
									) : null}
								</SypacInput>
							</div>
							<div className="w-full">
								<SypacInput
									error={
										!!(
											formik.touched.maxOrderCapacity &&
											formik.errors.maxOrderCapacity
										)
									}
								>
									<SypacText className="mb-1" variant="overline-normal-large">
										<p>
											<T keyName="selectProduct.maximumOrderCapacity">
												Maximum order capacity per day (tons)
											</T>{' '}
											<span className="text-red">*</span>
										</p>
									</SypacText>
									<input
										className="w-full py-2.5 px-3 rounded-lg placeholder:text-gray-22"
										type="number"
										name="maxOrderCapacity"
										placeholder={t(
											'selectProduct.enterCapacity',
											'Enter capacity',
										)}
										value={formik?.values.maxOrderCapacity}
										onChange={changeMaxOrderCapacity}
									/>
									{formik.touched.maxOrderCapacity &&
									formik.errors.maxOrderCapacity ? (
										<span className="bottom-helper">
											<T keyName="selectProduct.errorMaxOrderCapacity">
												{formik.errors.maxOrderCapacity}
											</T>
										</span>
									) : null}
								</SypacInput>
							</div>
							<div className="w-full">
								<SypacText className="mb-1" variant="overline-normal-large">
									<p>
										<T keyName="selectProduct.netPricePerTon">
											Net price per ton (excluding VAT)
										</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>

								<div className="flex flex-col">
									<SypacInput
										error={
											!!(
												formik.touched.pricePerUnit &&
												formik.errors.pricePerUnit
											)
										}
										className={`relative flex border border-solid transition rounded-lg h-[40px] ${
											!!(
												formik.touched.pricePerUnit &&
												formik.errors.pricePerUnit
											)
												? 'border-red-orange'
												: 'border-gray-22 focus-within:border-cornflower-blue hover:border-gray-30 focus-within:hover:border-cornflower-blue'
										}`}
									>
										<input
											type="number"
											step="0.01"
											name="pricePerUnit"
											className="border-0 py-2.5 pl-3 rounded-lg placeholder:text-gray-22 outline-none"
											placeholder={t(
												'selectProduct.enterProductPricePerTon',
												'Enter product price per ton',
											)}
											value={formik?.values.pricePerUnit}
											onChange={(event) => formik?.handleChange(event)}
										/>

										<div className="absolute right-0 flex h-[40px] gap-3 px-[10px] justify-center items-center bg-white border-0 border-solid border-l-[1px] border-gray-10 rounded-r-lg">
											<SypacText variant="overline-normal-large">
												<p className="text-base text-gray-80 mt-[2px]">
													{userCountry === 'MDA' ? 'MDL' : 'PLN'}
												</p>
											</SypacText>
											<span className="flex my-auto transition-all mr-[2px]">
												<ChevronRightIcon />
											</span>
										</div>
									</SypacInput>
									{formik.touched.pricePerUnit && formik.errors.pricePerUnit ? (
										<span className="text-xs text-red-orange mt-[2px]">
											<T keyName="selectProduct.errorPricePerUnit">
												{formik.errors.pricePerUnit}
											</T>
										</span>
									) : null}
								</div>
							</div>
						</div>
					</div>

					<div className="flex gap-3 px-3 items-center justify-center border-0 border-solid border-t-[1px] border-gray-10">
						<SypacButton variant="primary" size="large" className="w-full mt-3">
							<button
								type="button"
								onClick={() =>
									navigate(`/products/new/groups/${groupId}/categories`)
								}
								className="w-full h-[44px] bg-white border border-solid border-gray-10 text-gray-80 rounded-10 transition hover:border-gray-40 hover:text-gray-90"
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="selectProduct.back">Back</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="primary" size="large" className="w-full mt-3">
							<button
								type="submit"
								className="w-full h-[44px] rounded-10 transition"
								disabled={disableSend}
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="modalAddProduct.create">Create</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</form>
		</div>
	);
};

export default SelectProduct;
