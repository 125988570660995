import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	SypacBadge,
	SypacBox,
	SypacButton,
	SypacCheckbox,
	SypacImage,
	SypacText,
} from '@sypac/component-library-react';
import {
	NotificationInterface,
	OrderInterface,
} from '../../../../../../components/OrdersTable/interfaces/Order.interface';
import {
	DriverDto,
	FleetQueryParams,
	TruckService,
} from '../../../../../../services/truck.services';
import { Search } from '../../../../../../assets/Search';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import { toastVariant } from '../../../../../../components/CompaniesTable/toastVariant/toastVariant';
import Map from '../../../../../../components/Map/Map';
import {
	MapMarker,
	PinVariant,
} from '../../../../../../components/Map/Map.interface';
import { T, useTranslate } from '@tolgee/react';
import Emitter, { EventType } from '../../../../../../services/events';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import { LIMIT } from '../../../../../../constants';
import { MapIcon } from '../../../../../../assets/MapIcon';
import { Checkmark } from '../../../../../../assets/Checkmark';
import HideMap from '../../../../../../assets/HideMap';
import TruckSchedule from '../../AdditionalDetails/TruckSchedule';
import { OrdersService } from '../../../../../../services/orders.services';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import LoadingSpinner from '../../../../../../assets/LoadingSpinner';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import TruckDetailsMap from '../../../../../Admin/OrderDetails/components/AdditionalDetails/TruckDetailsMap';
import { TransporterOrderProps } from '../../../transporterOrder.interface';

const TrackStatusMap: Record<string, { color: string; label: string }> = {
	available: { color: 'green', label: 'Available' },
	not_available: { color: 'yellow', label: 'In Progress' },
	returning: { color: 'primary-violet', label: 'Returning' },
};

const Accepted: React.FC<TransporterOrderProps> = (props) => {
	const { t } = useTranslate();
	const { selectedOrder, setOrder } = props;
	const [step, setStep] = useState<number>(1);
	const [page, setPage] = useState<number>(0);
	const [currentStatus, setCurrentStatus] = useState<string>('');
	const [fleet, setFleet] = useState<any>();
	const [activeTruck, setActiveTruck] = useState<number>();
	const [allocatedTruck, setAllocatedTruck] = useState<DriverDto>();
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [loadingMap, setLoading] = useState<boolean>(false);
	const [isMapOpen, setIsMapOpen] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [stepperMargin, setStepperMargin] = useState<number>(0);
	const [boxHeight, setBoxHeight] = useState<number>(0);
	const boxRef = useRef<HTMLDivElement>(null);
	let updateTimeout: string | number | NodeJS.Timeout | undefined;

	const allocateTruck = async (item: DriverDto) => {
		setIsLoading(true);
		try {
			await TruckService.allocateTruck(selectedOrder?.id!, item.uid);

			const { data } = await OrdersService.getOrder(
				selectedOrder?.id!,
				OrderTarget.transporter,
			);
			setOrder?.(data as unknown as OrderInterface);

			toastVariant(t(`acceptedStep.truckAllocated`, 'Truck allocated'), false);
		} catch (e: any) {
			setIsLoading(false);
			const message = e?.response?.data?.message || e?.toString();
			toastVariant(t(`acceptedStep.${message}`, message), true);
		}
	};

	const getFleetCallback = useCallback(async () => {
		try {
			let query: FleetQueryParams = {
				offset: page * LIMIT,
				limit: LIMIT,
				status: currentStatus,
			};
			const { data } = await TruckService.getTrucks(query, 'transporter');
			setFleet(data.items as unknown as DriverDto[]);
		} catch (e) {
			console.log(e);
		}
	}, [currentStatus, page]);

	useEffect(() => {
		getFleetCallback().then(() => {});
	}, [getFleetCallback, currentStatus]);

	const setCurrentTab = (status: string) => {
		setPage(0);
		setCurrentStatus(status);
	};

	const handleRowClick = useCallback(
		(idx: number) => {
			setActiveTruck(idx);
			const selectedTruck = fleet?.find((_: any, i: number) => i === idx);
			if (selectedTruck && selectedTruck?.id) {
				setLoading(true);
				setMarkers([
					{
						latitude: selectedTruck?.lastLocation?.coordinates[1] || 47.04014,
						longitude: selectedTruck?.lastLocation?.coordinates[0] || 28.80058,
						variant: PinVariant.truck,
						truck: selectedTruck,
					},
				]);
				setTimeout(() => {
					setLoading(false);
				}, 300);
			}
		},
		[fleet],
	);

	const handleCheckboxChange = (idx: number) => {
		if (activeTruck === idx) {
			setActiveTruck(undefined);
			markersMap();
		} else {
			setActiveTruck(idx);
			handleRowClick(idx);
		}
	};

	const markersMap = useCallback(() => {
		if (fleet !== undefined) {
			setLoading(true);
			const newMarkers = fleet.map(
				(r: { lastLocation: { coordinates: any[] } }) => ({
					latitude: r?.lastLocation?.coordinates[1] || 47.04014,
					longitude: r?.lastLocation?.coordinates[0] || 28.80058,
					variant: PinVariant.truck,
					truck: r,
				}),
			);
			setMarkers(newMarkers);
			setTimeout(() => {
				setLoading(false);
			}, 300);
		}
	}, [fleet]);

	useEffect(() => {
		markersMap();
	}, [fleet, markersMap]);

	const setUpdateLocation = useCallback(
		(notify: NotificationInterface) => {
			if (!markers.length) {
				return;
			}
			const lastMarkers = JSON.parse(JSON.stringify(markers)) as MapMarker[];
			const newMarkers = lastMarkers
				?.map((r) => {
					if (r.truck?.uid === notify.content?.driverId) {
						return {
							...r,
							latitude: notify.content?.lat
								? parseFloat(notify.content.lat)
								: r.latitude,
							longitude: notify.content?.long
								? parseFloat(notify.content.long)
								: r.longitude,
						};
					}
					return r;
				})
				.filter((r) => r.latitude && r.longitude);
			setMarkers(newMarkers);
		},
		[markers],
	);

	const checkUpdateLocation = useCallback((notify: NotificationInterface) => {
		clearTimeout(updateTimeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		updateTimeout = setTimeout(() => {
			setUpdateLocation(notify);
		}, 2000);
		return () => {
			clearTimeout(updateTimeout);
		};
	}, []);

	useEffect(() => {
		Emitter.on(
			EventType.TRUCK_UPDATE_LOCATION,
			(notify: NotificationInterface) => {
				checkUpdateLocation(notify);
			},
		);
		return () => {
			Emitter.off(EventType.TRUCK_UPDATE_LOCATION);
		};
	}, [checkUpdateLocation]);

	const tableRow = (item: DriverDto, idx: number) => {
		const bgColor =
			TrackStatusMap[item.status]?.color ||
			TrackStatusMap['not_available'].color;
		const label =
			TrackStatusMap[item.status]?.label ||
			TrackStatusMap['not_available'].label;

		return (
			<tr
				key={item.id}
				className="border border-solid border-l-0 border-r-0 border-b-gray-10 border-t-gray-10 hover:bg-gray-10-opacity-50 cursor-pointer"
				onClick={() => handleCheckboxChange(idx)}
			>
				<td className="p-2 border border-solid border-r-gray-10 border-l-0 border-t-0 border-b-0">
					<SypacCheckbox size="md" className="flex items-center justify-center">
						<input
							type="checkbox"
							checked={activeTruck === idx}
							onChange={() => handleCheckboxChange(idx)}
							className="m-0 border-gray-10 cursor-pointer"
						/>
					</SypacCheckbox>
				</td>
				<td className="p-2 border border-solid border-r-gray-10 border-l-0 border-t-0 border-b-0">
					{bgColor && label ? (
						<SypacBadge color={bgColor} outlined={false}>
							<div className="text-xs font-medium whitespace-nowrap min-w-[74px] flex justify-center">
								<T keyName={`acceptedStep.${label}`}>{label}</T>
							</div>
						</SypacBadge>
					) : null}
				</td>
				<td className="p-2 border border-solid border-r-gray-10 border-l-0 border-t-0 border-b-0">
					{item.licensePlates}
				</td>
				<td className="p-2 border border-solid border-r-gray-10 border-l-0 border-t-0 border-b-0">
					{item.name || '-'}
				</td>
				<td className="p-2">
					<div className="flex gap-2 rounded-md items-center py-1 px-2 bg-gray-10-opacity-50">
						<SypacImage className="w-[47px] h-[18px]">
							<img src="/tipper.svg" alt={item.type?.split('_').join(' ')} />
						</SypacImage>

						<div className="flex gap-1">
							<SypacText variant="overline-regular-large">
								<p className="text-xs text-gray-80 capitalize whitespace-nowrap">
									{item.type?.split('_').join(' ')}
								</p>
							</SypacText>
							<div className="text-xs text-gray-80">/</div>
							<SypacText variant="overline-regular-large">
								<p className="text-xs text-gray-80 capitalize whitespace-nowrap">
									{`Payload: ${item.truckPayload} tons`}
								</p>
							</SypacText>
						</div>
					</div>
				</td>
			</tr>
		);
	};

	const toggleMap = () => {
		setIsMapOpen(!isMapOpen);
	};

	const handleNextStep = () => {
		setStep(step + 1);
	};

	const handlePrevStep = () => {
		setStep(step - 1);
	};

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	useEffect(() => {
		const element = boxRef.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setBoxHeight(entry.contentRect.height);
			}
		});

		if (element) {
			resizeObserver.observe(element);
		}

		return () => {
			if (element) {
				resizeObserver.unobserve(element);
			}
		};
	}, []);

	return (
		<div style={{ marginBottom: step === 3 ? '226px' : `${boxHeight}px` }}>
			<div style={{ marginBottom: `${stepperMargin}px` }}>
				<OrderDetailsStepper
					current={step}
					simpleOrder={selectedOrder?.trucks?.length === 1}
					order={selectedOrder}
					onWidthChange={handleWidthChange}
				/>
			</div>

			{step === 2 ? (
				<div className="flex flex-col px-10 pt-6 h-[calc(100vh-296px)]">
					<div className="flex justify-between">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-60">
								<T keyName="acceptedStep.allocateATruckForThisOrder">
									Allocate a truck for this order
								</T>
							</p>
						</SypacText>

						<div className="flex items-center gap-2">
							<span className="-ml-[2px] mr-[2px] scale-[1.2]">
								{isMapOpen ? <HideMap /> : <MapIcon />}
							</span>
							<SypacButton variant="secondary" size="small">
								<button
									className="w-full border-none bg-transparent p-0 group"
									type="button"
									onClick={toggleMap}
								>
									<SypacText variant="body-normal-medium">
										<p className="transition group-hover:text-gray-80 group-hover:-translate-y-[3px]">
											{isMapOpen ? (
												<T keyName="acceptedStep.hideMap">Hide map</T>
											) : (
												<T keyName="acceptedStep.showMap">Show map</T>
											)}
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>

					{isMapOpen ? (
						<div
							className={`w-full h-[65%] pt-6 pb-4 ${
								loadingMap ? 'bg-gray-1' : 'bg-transparent'
							}`}
						>
							{!loadingMap ? <Map markers={markers} /> : null}
						</div>
					) : null}

					<div className="flex gap-[3px] p-3 mt-3 bg-white border border-solid border-gray-10 border-b-0 rounded-t-[10px]">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex justify-center items-center transition hover:border-gray-20 ${
									currentStatus === ''
										? 'border-kimberly'
										: 'border-transparent text-gray-80'
								}`}
								onClick={() => setCurrentTab('')}
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="acceptedStep.tableTabAll">All</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-3 transition hover:border-gray-20 ${
									currentStatus === 'available'
										? 'border-kimberly'
										: 'border-transparent text-gray-80'
								}`}
								onClick={() => setCurrentTab('available')}
							>
								<div className="w-[8px] h-[8px] rounded-full bg-mountain-meadow" />
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="acceptedStep.tableTabAvailable">Available</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-3 transition hover:border-gray-20 ${
									currentStatus === 'not_available'
										? 'border-kimberly'
										: 'border-transparent text-gray-80'
								}`}
								onClick={() => setCurrentTab('not_available')}
							>
								<div className="w-[8px] h-[8px] rounded-full bg-texas-rose" />
								<SypacText variant="body-regular-medium">
									<p className="whitespace-nowrap">
										<T keyName="acceptedStep.tableTabInProgress">In Progress</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								className={`w-full h-[40px] rounded-lg flex gap-3 transition hover:border-gray-20 ${
									currentStatus === 'returning'
										? 'border-kimberly'
										: 'border-transparent text-gray-80'
								}`}
								onClick={() => setCurrentTab('returning')}
								disabled={true}
							>
								<div className="w-[8px] h-[8px] rounded-full bg-primary-violet/30" />
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="acceptedStep.tableTabReturning">Returning</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>

					<div className="bg-white h-full overflow-y-auto border border-solid border-gray-10 border-t-0 border-b-0 mb-[1px]">
						<table className="w-full text-sm text-left border-collapse">
							<thead>
								<tr
									className="whitespace-nowrap [&_p]:text-gray-40"
									key="thead"
								>
									<th
										scope="col"
										className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
									/>
									<th
										scope="col"
										className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
									>
										<SypacText variant="body-regular-small">
											<p>
												<T keyName="acceptedStep.status">Status</T>
											</p>
										</SypacText>
									</th>
									<th
										scope="col"
										className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
									>
										<SypacText variant="body-regular-small">
											<p>
												<T keyName="acceptedStep.truckPlates">Truck plates</T>
											</p>
										</SypacText>
									</th>
									<th
										scope="col"
										className="px-4 py-3 border border-solid border-r-gray-10 border-l-0 border-t-gray-10 border-b-gray-10"
									>
										<SypacText variant="body-regular-small">
											<p>
												<T keyName="acceptedStep.truckName">Truck name</T>
											</p>
										</SypacText>
									</th>
									<th
										scope="col"
										className="px-4 py-3 border border-solid border-r-0 border-l-0 border-t-gray-10 border-b-gray-10"
									>
										<SypacText variant="body-regular-small">
											<p>
												<T keyName="acceptedStep.truckType">Truck type</T>
											</p>
										</SypacText>
									</th>
								</tr>
							</thead>
							<tbody>
								{fleet !== undefined ? (
									fleet?.map(tableRow)
								) : (
									<tr key="opened">
										<td colSpan={5}>
											<div className="flex flex-col items-center mt-[75px] gap-8">
												<Search />
												<SypacText variant="body-normal-small">
													<p className="text-gray-50">
														<T keyName="acceptedStep.notImplementedYetDescription">
															Lorem ipsum dolor sit amet, consectetur adipiscing
															elit.
														</T>
													</p>
												</SypacText>
											</div>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>

					<SypacBox
						hover={false}
						boxColor="primary"
						className="fixed z-50 bottom-0 pb-6 w-[625px] h-fit bg-white box-border border-none rounded-none"
					>
						<div className="border border-solid border-gray-10 rounded-b-[10px]">
							<div className="flex gap-4 items-center justify-center p-2">
								<div className="w-full">
									<SypacButton
										variant="primary"
										size="large"
										className="w-full"
									>
										<button
											className="w-full transition"
											disabled={activeTruck === undefined}
											onClick={() => {
												if (activeTruck === undefined || !fleet) return;
												const item = fleet[activeTruck];
												setAllocatedTruck(item);
												handleNextStep();
											}}
										>
											<Checkmark />
											<SypacText variant="body-regular-medium">
												<p className="ml-3 text-white">
													<T keyName="acceptedStep.allocateTruck">
														Allocate truck
													</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>
								</div>
							</div>
						</div>
					</SypacBox>
				</div>
			) : step === 3 ? (
				<>
					<div className="px-10 pt-7 pb-3.5">
						<TruckDetailsMap
							selectedOrder={selectedOrder}
							allocatedTruck={allocatedTruck}
						/>
					</div>

					<div className="px-10 py-3.5">
						<TruckSchedule
							selectedOrder={selectedOrder}
							allocatedTruck={allocatedTruck}
						/>
					</div>

					<div className="px-10 pb-7 pt-3.5">
						<BaseOrderDetails
							selectedOrder={selectedOrder}
							isOpen={true}
							simpleOrder={selectedOrder?.trucks?.length === 1}
							deliveryIncluded={selectedOrder?.deliveryIncluded!}
							target={selectedOrder?.target!}
						/>
					</div>

					<div className="fixed bottom-0 w-[702px] h-fit bg-alabaster border border-solid border-primary-violet border-l-0 border-r-0 border-b-0 rounded-2xl shadow-order-assign">
						<div className="flex flex-col justify-center p-8 gap-4">
							<SypacText variant="heading-5">
								<p className="text-2xl leading-7 tracking-tighter text-gray-90">
									<T keyName="acceptedStep.confirmSelectedTruck">
										Confirm selected truck
									</T>
								</p>
							</SypacText>
							<SypacText variant="overline-regular-large">
								<p className="text-base leading-6 tracking-tighter text-gray-40">
									<T keyName="acceptedStep.ensureYouSelectSuitableTruckTo">
										Ensure you select a suitable truck to fulfill the order's
										requirements.
									</T>
								</p>
							</SypacText>
							<div className="flex gap-6 justify-center mt-6">
								<SypacButton variant="primary" size="large" className="w-full">
									<button
										className="w-full h-[54px] bg-white border border-solid border-gray-10 text-gray-80 rounded-10 transition hover:border-gray-40 hover:text-gray-90"
										onClick={() => handlePrevStep()}
									>
										<T keyName="acceptedStep.backToTruckSelection">
											Back to truck selection
										</T>
									</button>
								</SypacButton>
								<SypacButton variant="primary" size="large" className="w-full">
									<button
										className={`w-full h-[54px] rounded-10 transition ${
											isLoading
												? 'bg-primary-violet/80 text-gray-500'
												: 'bg-primary-violet text-white hover:bg-primary-violet/70 hover:text-white'
										}`}
										onClick={() => allocateTruck(allocatedTruck!)}
										disabled={isLoading}
									>
										<SypacText
											variant="body-normal-medium"
											className="flex items-center gap-4"
										>
											<p>
												<T keyName="acceptedStep.allocateTruck">
													Allocate truck
												</T>
											</p>
											{isLoading ? <LoadingSpinner /> : null}
										</SypacText>
									</button>
								</SypacButton>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="px-10 pt-7 pb-3.5">
						<TruckSchedule selectedOrder={selectedOrder} />
					</div>

					<div className="px-10 py-3.5">
						<PickUpDeliveryDetails
							selectedOrder={selectedOrder}
							target={selectedOrder?.target}
							isPickUp={false}
							simpleOrder={selectedOrder?.trucks?.length === 1}
						/>
					</div>

					<div className="px-10 py-3.5">
						<PickUpDeliveryDetails
							selectedOrder={selectedOrder}
							target={selectedOrder?.target}
							isPickUp={true}
							simpleOrder={selectedOrder?.trucks?.length === 1}
						/>
					</div>

					<div className="px-10 pb-7 pt-3.5">
						<BaseOrderDetails
							selectedOrder={selectedOrder}
							isOpen={true}
							simpleOrder={selectedOrder?.trucks?.length === 1}
							deliveryIncluded={selectedOrder?.deliveryIncluded!}
							target={selectedOrder?.target!}
						/>
					</div>

					<div
						className="fixed bottom-0 w-[702px] h-fit bg-alabaster border border-solid border-primary-violet border-l-0 border-r-0 border-b-0 rounded-2xl shadow-order-assign"
						ref={boxRef}
					>
						<div className="flex flex-col justify-center p-8 gap-4">
							<SypacText variant="heading-5">
								<p className="text-2xl leading-7 tracking-tighter text-gray-90">
									<T keyName="acceptedStep.pleaseAllocateTruckForThisOrder">
										Please allocate a truck for this order
									</T>
								</p>
							</SypacText>
							<SypacText variant="overline-regular-large">
								<p className="text-base leading-6 tracking-tighter text-gray-40">
									<T keyName="acceptedStep.ensureYouSelectSuitableTruck">
										Make sure to select a truck that meets the order
										requirements
									</T>
								</p>
							</SypacText>
							<div className="flex gap-6 justify-center [&_*]:w-full mt-6">
								<SypacButton variant="primary" size="large">
									<button
										className="h-[54px] rounded-10 transition bg-primary-violet text-white hover:bg-primary-violet/80 hover:text-white"
										onClick={() => handleNextStep()}
									>
										<T keyName="acceptedStep.viewMyFleet">View my fleet</T>
									</button>
								</SypacButton>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Accepted;
