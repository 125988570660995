import React, { useContext, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacButton,
	SypacCheckbox,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import Close from '../../../../assets/Close';
import { useNavigate } from 'react-router-dom';
import Flag from 'react-world-flags';
import { SelectProductProps } from '../productDatabase.interface';
import {
	ProductContext,
	ProductProps,
} from '../../../../context/DatabaseProductContext/database-product.context';

const ProductStoreStep: React.FC<SelectProductProps> = ({ setStep }) => {
	const { t } = useTranslate();
	const navigate = useNavigate();
	const { product, setProduct } = useContext(ProductContext);
	const [selectedCountry, setSelectedCountry] = useState<string | null>(null);

	const countries = [
		{ key: 'POL', label: t('adminProducts.poland', 'Poland'), flag: 'PL' },
		{
			key: 'MDA',
			label: t('adminProducts.moldova', 'Moldova'),
			flag: 'MD',
		},
	];

	const handleCountrySelect = (countryKey: string) => {
		setSelectedCountry(countryKey === selectedCountry ? null : countryKey);
	};

	const handleNextStep = () => {
		if (selectedCountry) {
			setProduct({
				...product,
				countryCode: selectedCountry,
			} as ProductProps);

			setStep((prevStep) => prevStep + 1);
		}
	};

	const renderCountries = (item: any) => {
		return (
			<div
				key={item.key}
				className={`flex gap-3 items-center px-3 py-[17px] border border-solid border-gray-10 rounded-xl cursor-pointer transition hover:border-gray-40 ${
					selectedCountry === item.key ? 'border-primary-violet' : ''
				}`}
				onClick={() => handleCountrySelect(item.key)}
			>
				<SypacCheckbox size="md">
					<input
						type="checkbox"
						className="cursor-pointer"
						checked={selectedCountry === item.key}
						onChange={() => handleCountrySelect(item.key)}
					/>
				</SypacCheckbox>

				<div className="flex gap-3 mt-1">
					<Flag
						className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded mt-[1px]"
						code={item.flag}
						width={22}
						height={16}
					/>
					<SypacText variant="body-regular-medium">
						<p>{item.label}</p>
					</SypacText>
				</div>
				{item.key === 'POL' && (
					<SypacIcon
						iconName="Crown Minimalistic"
						className="text-texas-rose"
						size="custom"
						width="24px"
						height="24px"
					/>
				)}
			</div>
		);
	};

	return (
		<div className="w-full h-full flex items-center justify-center">
			<div className="max-w-[486px] w-full flex flex-col">
				<div className="flex justify-between items-center mb-12">
					<SypacText variant="body-normal-medium">
						<p className="text-gray-60">
							<T keyName="productGroup.manageProduct">
								Manage product groups and categories
							</T>
						</p>
					</SypacText>
					<SypacButton variant="secondary" size="medium">
						<button
							onClick={() => navigate('/product-database')}
							className="p-2 border border-solid border-gray-10 transition hover:border-primary-violet"
						>
							<Close />
						</button>
					</SypacButton>
				</div>

				<div className="flex flex-col gap-2 mb-[62px]">
					<SypacText variant="heading-5">
						<p className="text-xl text-gray-80">
							<T keyName="selectProductStore.selectCountryStore">
								Select country store
							</T>
						</p>
					</SypacText>
				</div>

				<div className="flex flex-col gap-3 p-3 border border-solid border-gray-10 rounded-xl shadow-dropdown-avatar">
					{countries?.map((item) => renderCountries(item))}
				</div>

				<div className="flex justify-center w-full mt-[40px] gap-3">
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className="w-full px-6 py-[10px] rounded-lg"
							onClick={() => navigate(-1)}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="companyDetails.back">Back</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="primary" size="small" className="w-full">
						<button
							type="button"
							className="w-full px-6 py-[10px] rounded-lg"
							onClick={handleNextStep}
							disabled={!selectedCountry}
						>
							<SypacText
								variant="body-regular-medium"
								className="flex items-center gap-4"
							>
								<p>
									<T keyName="companyDetails.nextStep">Next step</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</div>
	);
};
export default ProductStoreStep;
