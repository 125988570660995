import React, { useMemo, useState, useEffect } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SypacIcon, SypacText } from '@sypac/component-library-react';
import { SortingItemInterface, SortingProps } from './Sorting.interface';
import { SortingItem } from './SortingItem';
import { T, useTranslate } from '@tolgee/react';
import Flag from 'react-world-flags';

export const Sorting: React.FC<SortingProps> = ({
	options,
	action,
	current,
	label,
	customDefault,
	shouldReset,
}) => {
	const { t } = useTranslate();
	const [selected, setSelected] = useState<SortingItemInterface>(current!);
	const [isOpen, setIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (shouldReset) {
			setSelected(current!);
		}
	}, [shouldReset, current]);

	const mergedOptions = useMemo(
		() => [
			{
				title: customDefault ? customDefault.title : 'Default',
				value: undefined,
			},
			...options,
		],
		[customDefault, options],
	);

	const closeDropdown = () => {
		setIsOpen(false);
	};

	const ref = useDetectClickOutside({ onTriggered: closeDropdown });

	return (
		<div ref={ref} className="flex gap-x-3 relative justify-start items-start">
			<div
				className="flex gap-2 items-center cursor-pointer"
				onClick={() => setIsOpen(!isOpen)}
			>
				<SypacText variant="body-regular-medium">
					<p className="text-gray-80">
						{label ? (
							<T keyName={`sorting.${label.key}`}>{label.title}</T>
						) : (
							<>
								{customDefault ? (
									<T keyName={`sorting.${customDefault.key}`}>
										{customDefault.title}
									</T>
								) : (
									<T keyName="sorting.sortedBy">Sorted by:</T>
								)}
							</>
						)}
					</p>
				</SypacText>

				<div className="flex gap-2">
					{label?.key === 'country' ? (
						<Flag
							className="w-[24px] h-[16px] object-cover rounded z-10 border border-solid border-gray-10 mx-1.5"
							code={selected?.value}
							width={22}
							height={16}
						/>
					) : null}
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">
							{selected?.title ? (
								<T keyName={`sorting.${selected?.title}`}>{selected?.title}</T>
							) : (
								<>
									{customDefault ? (
										<T keyName={`sorting.${customDefault.key}`}>
											{customDefault.title}
										</T>
									) : (
										<T keyName="sorting.sortedBy">Sorted by:</T>
									)}
								</>
							)}
						</p>
					</SypacText>
				</div>

				<SypacIcon
					iconName="Alt Arrow Down"
					size="custom"
					width="20px"
					height="10px"
				/>
			</div>
			{isOpen && (
				<div className="flex flex-col absolute top-8 p-[3px] border border-solid border-gray-10 rounded-10 shadow-dropdown bg-white min-w-[145px] w-max z-30">
					{mergedOptions.map(({ title, value }) => {
						return (
							<SortingItem
								key={Math.random()}
								title={t(`sorting.${title.toLowerCase()}`, title)}
								value={value}
								action={(item) => {
									setSelected(item);
									action(item);
								}}
								flag={value}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
};
