import EventEmitter from 'eventemitter3';

export enum EventType {
	NOTIFICATION_COUNT = 'notification_count',
	NOTIFICATION_COUNT_DECREASE = 'notification_count_decrease',
	ORDER_NOTIFICATION_COUNT_DECREASE = 'order_notification_count_decrease',
	ORDER_LIST_REFRESH = 'order_list_refresh',
	TRUCK_UPDATE_LOCATION = 'truck_update_location',
	UPDATE_EXPIRED_NOTIFICATION = 'update_expired_notification',
	ORDER_REFRESH = 'order_refresh',
	PRODUCT_DATABASE_REFRESH = 'product_database_refresh',
}

const eventEmitter = new EventEmitter();

const Emitter = {
	on: (event: any, fn: (...args: any[]) => void) => eventEmitter.on(event, fn),
	once: (event: any, fn: (...args: any[]) => void) =>
		eventEmitter.once(event, fn),
	off: (event: any, fn?: ((...args: any[]) => void) | undefined) =>
		eventEmitter.off(event, fn),
	emit: (event: any, payload?: any) => eventEmitter.emit(event, payload),
};

Object.freeze(Emitter);

export default Emitter;
