import React, { useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacAvatar,
	SypacTabs,
	SypacText,
} from '@sypac/component-library-react';
import { ProductStatusEnum } from '../../../../../interfaces/product.interface';
import { Future } from '../../../../../assets/Future';
import EyeIcon from '../../../../../assets/EyeIcon';
import { RejectWeight } from '../../../../../assets/RejectWeight';
import TableStatus from '../../../../../components/TableStatus/TableStatus';
import { BillingTab } from '../../../../../components/BillingTabs/BillingTabs.interface';
import { TabsButton } from '../../../../../components/TabsButton/TabsButton';
import ProductOverview from './components/ProductOverview';
import { ProductDetailsProps } from './ProductDetails.interface';
import { formatDate } from '../../../../../utils/time.util';

const ProductDetails: React.FC<ProductDetailsProps> = ({
	onClose,
	product,
	rejectProduct,
	approveProduct,
	viewOnStore,
	editProduct,
}) => {
	const { t } = useTranslate();
	const [currentTab, setCurrentTab] = useState<string>('overview');

	const tabs = [
		{
			label: t('productDetails.overview', 'Overview'),
			count: '0',
			value: 'overview',
		},
		{ label: t('productDetails.files', 'Files'), count: '0', value: 'files' },
		{
			label: t('productDetails.history', 'Order history'),
			count: '0',
			value: 'history',
		},
		{ label: t('productDetails.notes', 'Notes'), count: '0', value: 'notes' },
	];

	return (
		<div className="fixed min-h-full top-0 right-0 w-[716px] border border-solid border-gray-10 rounded-l-2xl bg-white shadow-order-details z-[100] h-screen overflow-y-auto scroll-smooth box-border">
			<div className="sticky top-0 flex flex-col gap-5 bg-white z-[120] pt-[19px]">
				<div className="flex justify-between px-10">
					{['rejected', 'approved'].includes(product.status!) ? (
						<div className="flex gap-4 items-center">
							<SypacAvatar initials={product.verifiedByUser?.name} size="md">
								{product.verifiedByUser?.avatarUrl ? (
									<img
										src={product.verifiedByUser?.avatarUrl}
										alt={product.verifiedByUser?.name}
									/>
								) : null}
							</SypacAvatar>

							<div className="flex flex-col gap-1 mt-[2px]">
								<SypacText variant="overline-regular-large">
									<p className="text-xs text-gray-40">
										{product.status === 'approved' ? (
											<T keyName="productDetails.approvedBy">Approved by:</T>
										) : (
											<T keyName="productDetails.rejectedBy">Rejected by:</T>
										)}
									</p>
								</SypacText>

								<div className="flex flex-row gap-4">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{product.verifiedByUser?.name}
										</p>
									</SypacText>
									<div className="flex items-center justify-center px-2 py-[2px] bg-gray-1 border border-solid border-gray-10 rounded-full">
										<SypacText variant="body-regular-medium">
											<p className="text-xs text-gray-40">
												{formatDate(product.updatedAt)}
											</p>
										</SypacText>
									</div>
								</div>
							</div>
						</div>
					) : null}

					<div className="flex items-center gap-3 ml-auto">
						<button
							type="button"
							className="flex justify-center items-center w-[42px] h-[42px] bg-white border border-solid border-gray-10 rounded-10 cursor-pointer transition hover:border-gray-30"
							onClick={viewOnStore}
						>
							<EyeIcon color="#A2A2A2" />
						</button>
						<button
							type="button"
							className="flex justify-center items-center w-[42px] h-[42px] bg-white border border-solid border-gray-10 rounded-10 cursor-pointer transition hover:border-gray-30"
							onClick={rejectProduct}
						>
							<RejectWeight color="#A2A2A2" />
						</button>

						{/* <SypacIcon
							iconName="Close Square"
							className="text-gray-10 cursor-pointer"
							size="custom"
							width="32px"
							height="32px"
							onClick={onClose}
						/> */}
					</div>
				</div>
				<div className="w-full flex flex-col gap-3 px-10 py-5 bg-alabaster border border-solid border-gray-10 rounded-10 box-border">
					<div className="w-full flex justify-between items-start">
						<div
							style={{
								backgroundImage: `url(${product.photoUrl})`,
							}}
							className="flex flex-none w-[60px] h-[60px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10"
						/>

						<TableStatus
							state={product?.status || ProductStatusEnum.PENDING}
							isPartial={false}
							insideOut={true}
						/>
					</div>

					<div className="flex flex-col gap-1">
						<SypacText variant="body-regular-medium">
							<p className="text-xl text-gray-80">{product.name}</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-50">
								{product?.type || ''}{' '}
								{product?.size ? `(${product?.size})` : ''}
							</p>
						</SypacText>
					</div>
				</div>
				<div className="px-10">
					<SypacTabs
						variant="gray"
						className="flex w-full p-[3px] gap-[4px box-border"
					>
						{tabs
							? tabs?.map(({ label, value, count }: BillingTab) => {
									return (
										<TabsButton
											key={value}
											label={label}
											buttonId={value!}
											onClick={() =>
												setCurrentTab(value ? value : label.toLowerCase())
											}
											isSelected={
												currentTab === value ||
												currentTab === label.toLowerCase()
											}
											notification={+count}
											color="#E8E8E8"
											classNames="w-full [&_p:first-child]:text-sm"
										/>
									);
							  })
							: null}
					</SypacTabs>
				</div>
			</div>

			<div className="mt-5 mb-10">
				{currentTab === 'overview' ? (
					<ProductOverview
						product={product}
						approveProduct={approveProduct}
						rejectProduct={rejectProduct}
						editProduct={editProduct}
					/>
				) : (
					<div className="flex flex-col justify-center items-center pt-8">
						<Future />
						<SypacText variant="heading-5" className="mt-20">
							<p className="text-gray-90 font-medium">
								<T keyName="productDetails.future">
									Feature is not available at the moment
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium" className="mt-6">
							<p className="text-gray-40">
								<T keyName="productDetails.futureDescription">
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
									do eiusmod tempor incididunt
								</T>
							</p>
						</SypacText>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductDetails;
