import React from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import Close from '../../../../../assets/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	OrdersService,
	UpdateTruckBody,
} from '../../../../../services/orders.services';
import Emitter, { EventType } from '../../../../../services/events';
import { T, useTranslate } from '@tolgee/react';

export interface ModalTruckDetailsProps {
	isOpen: boolean;
	onClose: () => void;
	orderId: number;
	truckId: number;
}

const ModalTruckDetails: React.FC<ModalTruckDetailsProps> = ({
	isOpen,
	onClose,
	orderId,
	truckId,
}) => {
	const { t } = useTranslate();

	const formik = useFormik({
		initialValues: {
			driverFullName: '',
			licensePlates: '',
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			const driverData: UpdateTruckBody = {
				id: truckId,
				type: 'Dump Truck',
				licensePlates: values.licensePlates,
				driverFullName: values.driverFullName,
			};

			await OrdersService.updateTruckDetails(orderId, {
				...driverData,
			});
			Emitter.emit(EventType.ORDER_REFRESH, orderId);
			handleModalClose();
		},
		validationSchema: Yup.object({
			driverFullName: Yup.string().optional(),
			licensePlates: Yup.string().optional(),
		}),
	});

	const handleModalClose = () => {
		formik.resetForm();
		onClose();
	};

	return isOpen ? (
		<div className="modal-overlay" onClick={handleModalClose}>
			<div
				className="modal-inside outline-none w-[468px] h-fit"
				onClick={(e) => e.stopPropagation()}
			>
				<form className="flex flex-col h-full" onSubmit={formik.handleSubmit}>
					<div className="flex items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="modalTruckDetails.addTruckInfo">Add truck info</T>
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={onClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

					<div className="bg-alabaster border border-solid border-gray-10 rounded-10 m-3 p-3">
						<SypacText variant="body-regular-medium" className="mb-4">
							<p className="text-sm text-gray-40">
								<T keyName="modalTruckDetails.driverInformation">
									Driver information:
								</T>
							</p>
						</SypacText>

						<div className="flex flex-col gap-4">
							<SypacInput className="flex">
								<SypacText variant="overline-normal-large" className="mb-1">
									<p className="text-xs">
										<T keyName="modalTruckDetails.driverFirstLastName">
											Driver first & last name
										</T>
									</p>
								</SypacText>
								<input
									type="text"
									name="driverFullName"
									className="py-3 pl-3 border rounded-xl placeholder:text-gray-40 placeholder:text-base"
									placeholder={t(
										'modalTruckDetails.enterFirstLastName',
										'Enter first & last name',
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.driverFullName}
								/>
							</SypacInput>

							<SypacInput className="flex mb-4">
								<SypacText variant="overline-normal-large" className="mb-1">
									<p className="text-xs">
										<T keyName="modalTruckDetails.licensePlates">
											License plates
										</T>
									</p>
								</SypacText>
								<input
									type="text"
									name="licensePlates"
									className="py-3 pl-3 border rounded-xl placeholder:text-gray-40 placeholder:text-base"
									placeholder={t(
										'modalTruckDetails.enterLicensePlates',
										'Enter license plates',
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.licensePlates}
								/>
							</SypacInput>
						</div>
					</div>

					<div className="mt-auto">
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
						<div className="flex w-full py-3 gap-3 [&_button]:transition">
							<SypacButton
								variant="secondary"
								size="small"
								className="w-full pl-3"
							>
								<button
									className="w-full py-2.5 rounded-lg"
									type="reset"
									onClick={handleModalClose}
								>
									<SypacText variant="body-regular-medium">
										<p>
											<T keyName="modalAddTruck.cancel">Cancel</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton
								variant="primary"
								size="small"
								className="w-full pr-3"
							>
								<button className="w-full py-2.75 rounded-lg" type="submit">
									<SypacText variant="body-regular-medium">
										<p className="ml-3 text-white">
											<T keyName="modalTruckDetails.addInfo">Add info</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
				</form>
			</div>
		</div>
	) : null;
};

export default ModalTruckDetails;
