import { IconInterface } from '../interfaces/icon.interface';

export const MapPin = (props: IconInterface) => {
	const { color, fillColor = 'transparent', hoverEffect } = props;

	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{hoverEffect && <circle cx="16" cy="16" r="16" fill="white" />}
			<path
				d="M9.5 14.51464C9.5 10.9167 12.41015 8 16 8C19.5899 8 22.5 10.9167 22.5 14.51464C22.5 18.0844 20.4254 22.2499 17.1886 23.7396C16.4341 24.0868 15.5659 24.0868 14.8114 23.7396C11.5746 22.2499 9.5 18.0844 9.5 14.51464Z"
				fill={fillColor}
				stroke={color || 'gray'}
				strokeWidth="1.5"
			/>
			<ellipse cx="16" cy="14" rx="2.4375" ry="2.4" fill={color || 'gray'} />
		</svg>
	);
};
