import React, { useState } from 'react';
import { CompanyAssigneesProps } from './CompanyAssignees.interface';
import {
	SypacAvatar,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import { Assignee } from '../../services/company.services';
import AssignAccountManager from '../AssignAccountManager/AssignAccountManager';
import { UsersInterface } from '../../interfaces/Users.interface';
import { T } from '@tolgee/react';
import { formatDate } from '../../utils/time.util';

const CompanyAssignees = (props: CompanyAssigneesProps) => {
	const { assignees, assignUser, users } = props;

	const [selectedAssignee, setSelectedAssignee] = useState<Assignee>();
	const [isOpen, setOpenModal] = useState<boolean>(false);

	const selectItem = (item: Assignee) => {
		setSelectedAssignee(item);
	};

	const reasignUser = () => {};
	const getUserRole = (role: string = 'owner') => {
		if (role === 'owner') {
			return 'Administrator';
		}

		return role;
	};

	const addNewUser = () => {
		setOpenModal(true);
	};

	const addUder = (user?: UsersInterface) => {
		if (user) {
			assignUser([user.uid]);
		}
		setOpenModal(false);
	};

	return (
		<div>
			{!assignees?.length ? (
				<div className="flex flex-row justify-between items-center">
					<div className="flex flex-col">
						<div>
							<SypacText variant="body-regular-medium">
								<p className="text-primary-dark-gray">
									<T keyName="companyAssignees.noAssignedAccountManager">
										No assigned account manager
									</T>
								</p>
							</SypacText>
						</div>
						<div>
							<SypacText variant="overline-regular-large" className="mt-1">
								<p className="text-gray-40">
									<T keyName="companyAssignees.pleaseAssignManager.">
										Please assign manager.
									</T>
								</p>
							</SypacText>
						</div>
					</div>
					<SypacButton variant="primary" size="small" onClick={addNewUser}>
						<button className="p-3" type="button">
							<SypacText variant="overline-regular-large">
								<p className="text-white">
									<T keyName="companyAssignees.assignAccountManager">
										Assign account manager
									</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			) : assignees?.length === 1 ? (
				<div className="flex flex-row justify-between items-center">
					<div className="flex flex-row justify-start items-center">
						<div>
							<SypacAvatar initials={assignees[0].assigneeUser?.name} size="sm">
								{assignees[0].assigneeUser?.avatarUrl ? (
									<img
										src={assignees[0].assigneeUser?.avatarUrl}
										alt={assignees[0].assigneeUser?.name}
									/>
								) : null}
							</SypacAvatar>
						</div>
						<div className="flex flex-col ml-5">
							<SypacText variant="body-regular-medium">
								<p className="text-primary-dark-gray">
									{assignees[0].assigneeUser?.name}
								</p>
							</SypacText>
							<SypacText variant="overline-regular-large" className="mt-1">
								<p className="text-gray-40">
									{getUserRole(assignees[0].assigneeUser?.role)}
								</p>
							</SypacText>
						</div>
						<div className="flex flex-row justify-center items-center bg-gray-10 rounded-[46px] px-3 py-1 ml-5">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">
									<T keyName="companyAssignees.assigned">Assigned:</T>{' '}
									{formatDate(assignees[0].createdAt)}
								</p>
							</SypacText>
						</div>
					</div>
					<div>
						<SypacButton variant="primary" size="small" onClick={addNewUser}>
							<button className="p-3" type="button">
								<SypacText variant="overline-regular-large">
									<p className="text-white">
										<T keyName="companyAssignees.assignAccountManager">
											Assign account manager
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			) : (
				<div className="flex flex-col">
					<div>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="assignees.assignedUsers">Assigned users:</T>
							</p>
						</SypacText>
					</div>
					<div className="flex flex-row justify-between items-center mt-5">
						<div className="flex flex-row flex-wrap">
							{assignees?.map((item, index) => (
								<div key={index} className="relative">
									<SypacAvatar
										onClick={() => selectItem(item)}
										initials={item.assigneeUser?.name}
										isGrouped={true}
										size="md"
									>
										{item.assigneeUser?.avatarUrl ? (
											<img
												src={item.assigneeUser?.avatarUrl}
												alt={item.assigneeUser?.name}
											/>
										) : null}
									</SypacAvatar>
									{selectedAssignee?.id === item.id ? (
										<div className="flex flex-col absolute mt-2 left-0 shadow-assignee-view rounded-10 border border-solid border-gray-10 p-1 w-60">
											<div className="bg-gray-10-opacity-50 rounded-7 flex flex-row p-2">
												<div>
													<SypacAvatar
														initials={selectedAssignee.assigneeUser?.name}
														size="sm"
													>
														{selectedAssignee.assigneeUser?.avatarUrl ? (
															<img
																src={selectedAssignee.assigneeUser?.avatarUrl}
																alt={selectedAssignee.assigneeUser?.name}
															/>
														) : null}
													</SypacAvatar>
												</div>
												<div className="flex flex-col justify-start items-start ml-4">
													<SypacText variant="body-regular-medium">
														<p className="text-primary-dark-gray">
															{selectedAssignee.assigneeUser?.name}
														</p>
													</SypacText>
													<SypacText
														variant="overline-regular-large"
														className="mt-2"
													>
														<p className="text-gray-40">
															{getUserRole(selectedAssignee.assigneeUser?.role)}
														</p>
													</SypacText>
													<div className="flex flex-row justify-center items-center bg-gray-10 rounded-[46px] px-3 py-1 w-full mt-2">
														<SypacText variant="overline-regular-large">
															<p className="text-gray-80">
																<T keyName="companyAssignees.assigned">
																	Assigned:
																</T>{' '}
																{formatDate(selectedAssignee.createdAt)}
															</p>
														</SypacText>
													</div>
												</div>
											</div>
											<div className="mt-1 w-full">
												<SypacButton
													variant="primary"
													size="small"
													onClick={reasignUser}
													className="w-full"
												>
													<button className="p-3 w-full" type="button">
														<SypacText variant="overline-regular-large">
															<p className="text-white">
																<T keyName="companyAssignees.reassignUser">
																	Reassign user
																</T>
															</p>
														</SypacText>
													</button>
												</SypacButton>
											</div>
										</div>
									) : null}
								</div>
							))}
						</div>

						<div>
							<SypacButton variant="primary" size="small" onClick={addNewUser}>
								<button className="p-3" type="button">
									<SypacText variant="overline-regular-large">
										<p className="text-white">
											<T keyName="companyAssignees.assignAccountManager">
												Assign account manager
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
				</div>
			)}
			<AssignAccountManager
				isOpen={isOpen}
				onClose={() => setOpenModal(false)}
				onSubmit={addUder}
				users={users}
				assignees={assignees}
			/>
		</div>
	);
};

export default CompanyAssignees;
