export const carrierTexts = {
	main: {
		all: 'noOrdersAvailable',
		to_do: 'noOrdersWithToDo',
		progress: 'noOrdersWithInProgress',
		delivered: 'noOrdersWithDelivered',
		completed: 'noOrdersWithCompleted',
		noFleet: 'noOrdersAvailableAddTrucks',
	},
	sub: {
		all: 'allIncomingOrdersMatchingYourArea',
		noFleet: 'toStartGettingOrders',
	},
};

export const producerTexts = {
	main: {
		all: 'noOrdersAvailableProducer',
		to_do: 'noOrdersWithToDoProducer',
		progress: 'noOrdersWithInProgressProducer',
		delivered: 'noOrdersWithDeliveredProducer',
		completed: 'noOrdersWithCompletedProducer',
		noProducts: 'noOrdersAvailableAddProducts',
	},
	sub: {
		all: 'ordersWillShowUpHereWhenProductsSelected',
		noProducts: 'youHaven’tListedAnyProducts',
	},
};

export const customerTexts = {
	main: {
		all: 'youHaventPlacedAnyOrdersYet',
		created: 'youHaveNoOrdersCurrently',
		preparing: 'noOrdersWithInPreparing',
		delivered: 'noOrdersWithInDelivered',
		completed: 'noOrdersWithInCompleted',
	},
	sub: {
		all: 'yourOrdersWillAppearHere',
		created: 'youHaveNoOrdersCurrentlyDescription',
		preparing: 'noOrdersWithInPreparingDescription',
		delivered: 'noOrdersWithInDeliveredDescription',
		completed: 'noOrdersWithInCompletedDescription',
	},
};

export const adminTexts = {
	main: {
		all: 'noOrdersAvailableProducer',
		to_do: 'noOrdersWithToDoProducer',
		progress: 'noOrdersWithInProgressProducer',
		delivered: 'noOrdersWithDeliveredProducer',
		completed: 'noOrdersWithCompletedProducer',
	},
};
