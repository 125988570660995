import { IconInterface } from '../interfaces/icon.interface';

const FleetTruckThin = (props: IconInterface) => {
	const { color = '#A2A2A2' } = props;

	return (
		<svg
			width="62"
			height="42"
			viewBox="0 0 62 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.33333 24.3333H36V4.33333C36 2.49238 37.4924 1 39.3333 1H44.9374C47.6042 1 50.0145 2.58931 51.065 5.04054L53.7688 11.3494C54.1292 12.1903 54.8195 12.8462 55.6778 13.1632L58.8215 14.3242C60.1306 14.8077 61 16.0556 61 17.4511V30.6054C61 31.8677 60.2869 33.0218 59.158 33.5865L54.3333 36M4.33333 24.3333C2.49238 24.3333 1 25.8257 1 27.6667V32.665C1 34.506 2.49238 35.9984 4.33333 35.9984H6M4.33333 24.3333H31V4.33333C31 2.49238 29.5076 1 27.6667 1H4.33333C2.49238 1 1 2.49238 1 4.33333V21C1 22.8409 2.49238 24.3333 4.33333 24.3333ZM54.3333 36C54.3333 38.7614 52.0948 41 49.3333 41C46.5719 41 44.3333 38.7598 44.3333 35.9984M54.3333 36C54.3333 33.2386 52.0948 31 49.3333 31C46.5719 31 44.3333 33.2369 44.3333 35.9984M6 35.9984C6 38.7598 8.23858 41 11 41C13.7614 41 16 38.7598 16 35.9984M6 35.9984C6 33.2369 8.23858 31 11 31C13.7614 31 16 33.2369 16 35.9984M44.3333 35.9984H16M47.6667 21H44.3333"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default FleetTruckThin;
