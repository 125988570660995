import React, { useState } from 'react';
import SelectProductCategory from './ProductCategoryStep';
import SelectProductGroup from './ProductGroupStep';
import ProductStoreStep from './ProductStoreStep';
import AddProductDetails from './AddProductDetails';
import { Tons } from '../../../../assets/Tons';
import CheckmarkSmall from '../../../../assets/CheckmarkSmall';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { UpdatedProduct } from '../../../../services/product.services';
import { useNavigate } from 'react-router-dom';

const AddNewProduct: React.FC = () => {
	const [step, setStep] = useState<number>(1);
	const [product, setProduct] = useState<UpdatedProduct>();
	const navigate = useNavigate();

	const SuccessMessage = () => (
		<div className="w-full h-full flex items-center justify-center">
			<div className="w-[410px] flex flex-col gap-[60px]">
				<div className="relative flex">
					<span className="scale-[2.3] ml-[10px]">
						<Tons />
					</span>
					<span className="absolute top-[14px] left-[24px] border-[2px] border-solid border-white rounded-full">
						<CheckmarkSmall color="#20AC93" />
					</span>
				</div>

				<div className="flex flex-col gap-6">
					<SypacText variant="body-normal-medium">
						<p className="text-xl text-gray-80">
							<T keyName="addNewProduct.newProductUploaded">
								New product type successfully uploaded
							</T>
						</p>
					</SypacText>
				</div>

				<div className="w-full flex flex-col gap-3 p-[10px] border border-solid border-gray-10 rounded-xl box-border">
					<div
						style={{
							backgroundImage: `url(${product?.photoUrl})`,
						}}
						className="w-full h-[174px] bg-no-repeat bg-cover bg-center border border-solid border-gray-10 rounded-10 box-border"
					/>

					<div className="flex flex-col gap-[10px]">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{product?.name}</p>
						</SypacText>

						{product?.type ? (
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-40">
									{product.type} ({product.size})
								</p>
							</SypacText>
						) : null}
					</div>
				</div>

				<div className="flex w-full gap-3 [&_button]:transition">
					<SypacButton variant="secondary" size="small" className="w-full">
						<button
							type="button"
							className="w-full py-2.5 rounded-lg"
							onClick={() => navigate('/product-database')}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalAddProduct.close">Close</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="primary" size="small" className="w-full">
						<button
							type="button"
							className="w-full py-2.75 rounded-lg"
							onClick={() => navigate('/product-database')}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									<T keyName="modalAddProduct.viewInStore">View in store</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</div>
		</div>
	);

	const renderSteps = () => {
		const stepComponents = [
			<ProductStoreStep setStep={setStep} />,
			<SelectProductGroup setStep={setStep} />,
			<SelectProductCategory setStep={setStep} />,
			<AddProductDetails setStep={setStep} setProduct={setProduct} />,
		];
		return step <= 4 ? stepComponents[step - 1] : <SuccessMessage />;
	};

	return (
		<div className="w-full h-full flex items-center justify-center">
			{renderSteps()}
		</div>
	);
};
export default AddNewProduct;
