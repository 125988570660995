import React, { useCallback, useEffect, useState } from 'react';
import ProductGroup from '../components/ProductGroup/ProductGroup';
import { useGetCategories } from '../../../../hooks/use-get-categories';
import { Category } from '../../../../interfaces/category.interface';
import { useTranslate } from '@tolgee/react';
import { CompanyService } from '../../../../services/company.services';
import { toastVariant } from '../../../../components/CompaniesTable/toastVariant/toastVariant';
import { useNavigate, useParams } from 'react-router-dom';

const SelectProductCategory: React.FC = () => {
	const { storeId, groupId } = useParams();
	const { t } = useTranslate();
	const [userCountry, setUserCountry] = useState<string>();
	const [groups] = useGetCategories(
		{
			countryCode: userCountry,
		},
		'producer',
	);
	const [categories] = useGetCategories(
		{ parentIds: [groupId!], countryCode: userCountry },
		'producer',
	);
	const navigate = useNavigate();

	const onSelect = (category: Category): void => {
		navigate(`${category.id}`);
	};

	const getParentName = () => {
		if (groups?.items?.length) {
			return groups.items[0]?.name;
		}
		return '';
	};

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setUserCountry(
				data.countryCode === 'MD'
					? 'MDA'
					: data.countryCode === 'PL'
					? 'POL'
					: '',
			);
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const breadcrumbs = [
		{
			name: 'Country store',
			onClick: () => navigate(`/products/${storeId}`),
		},
		{
			name: getParentName(),
			onClick: () => navigate(`/products/${storeId}/groups`),
		},
		{
			name: 'Category',
			onClick: () => {},
		},
	];

	return (
		<div className="w-full h-full flex items-center">
			<div className="w-full my-15 lg:my-0 px-7.5 lg:px-0">
				<ProductGroup
					groups={categories?.items}
					onSelect={onSelect}
					title={t(
						'selectProductCategory.selectProductCategory',
						'Select product category',
					)}
					breadcrumbs={breadcrumbs}
					countryCode={userCountry}
					showClose={true}
				/>
			</div>
		</div>
	);
};
export default SelectProductCategory;
