import React, { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { Points } from '../../assets/Points';
import { DotsDropdownProps } from './DotsDropdown.interface';

const DotsDropdown: React.FC<DotsDropdownProps> = ({ options }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);

	const closeDropdown = () => {
		setIsOpen(false);
	};

	const ref = useDetectClickOutside({ onTriggered: closeDropdown });

	const toggleDropdown = () => {
		if (options.length > 0) {
			setIsOpen(!isOpen);
		}
	};

	return (
		<div ref={ref} className="flex gap-x-3 relative">
			<SypacButton variant="subtitle" size="medium">
				<button
					className={`flex items-center p-0 justify-center w-6 h-6 bg-transparent ${
						isOpen
							? 'bg-white outline outline-1 outline-darkViolet-100'
							: 'hover:bg-white hover:outline hover:outline-1 hover:outline-gray-10'
					}`}
					onClick={toggleDropdown}
				>
					<Points />
				</button>
			</SypacButton>
			{isOpen && (
				<div className="absolute top-0 right-10 w-max min-w-[195px] flex flex-col p-[3px] border border-solid border-gray-10 rounded-10 bg-white shadow-dropdown z-30">
					{options.map((option) => {
						return (
							<div
								key={option.id}
								className="no-underline flex py-[11.5px] px-5 border border-solid border-transparent bg-white rounded-lg cursor-pointer hover:bg-alabaster hover:border-gray-10"
								onClick={option.action}
							>
								<SypacText variant="body-regular-medium" className="mr-auto">
									<p className="text-gray-80 leading-[19px]">{option.title}</p>
								</SypacText>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default DotsDropdown;
