import React from 'react';
import { SypacInput, SypacText } from '@sypac/component-library-react';
import { LocationInput } from './components/LocationInput';
import { T } from '@tolgee/react';
import { ProductLocationProps } from './ProductLocation.interface';

export const ProductLocation = ({
	defaultValue,
	daysTitle,
	hoursTitle,
}: ProductLocationProps) => {
	return (
		<div className="flex flex-col gap-4 p-3 border border-solid border-gray-10 rounded-10 bg-alabaster mx-3">
			<SypacText variant="body-regular-medium">
				<p className="text-sm text-gray-40">
					<T keyName="productLocation.location">1 — Location & time:</T>
				</p>
			</SypacText>

			<div className="grid grid-cols-3 gap-3">
				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.selectLocationProduct">
								Select location for the product
							</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>

					<LocationInput
						defaultValue={defaultValue}
						role="producer"
						errorLocation={undefined}
					/>
				</div>

				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.productPickupAvailability">
								Product pickup availability
							</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>
					<SypacInput error={false}>
						<input
							autoComplete="off"
							id="location"
							name="location"
							type="text"
							className="w-full py-2.5 px-3 rounded-lg bg-gray-1 placeholder:text-gray-22 border-gray-10 text-gray-40"
							value={daysTitle}
							disabled={true}
						/>
					</SypacInput>
				</div>

				<div className="w-full">
					<SypacText className="mb-1" variant="overline-normal-large">
						<p>
							<T keyName="productLocation.workingHours">Working hours</T>{' '}
							<span className="text-red">*</span>
						</p>
					</SypacText>
					<SypacInput error={false}>
						<input
							autoComplete="off"
							id="location"
							name="location"
							type="text"
							className="w-full py-2.5 px-3 rounded-lg bg-gray-1 placeholder:text-gray-22 border-gray-10 text-gray-40"
							value={hoursTitle}
							disabled={true}
						/>
					</SypacInput>
				</div>
			</div>
		</div>
	);
};
