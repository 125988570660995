import React, { useContext, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import NewTrash from '../../../../../../assets/NewTrash';
import { ProductContext } from '../../../../../../context/DatabaseProductContext/database-product.context';

interface ModalDeletePhotoProps {
	isOpen: boolean;
	onClose: () => void;
	handleDelete: () => void;
	url: string;
}

const ModalDeletePhoto: React.FC<ModalDeletePhotoProps> = ({
	isOpen,
	onClose,
	handleDelete,
	url,
}) => {
	const { setAdditional } = useContext(ProductContext);
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			modalRefs: [...prev.modalRefs, modalRef],
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={onClose}
				className="modal-inside outline-none w-[516px]"
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div ref={modalRef} className="h-full flex flex-col">
					<div className="flex flex-col gap-[28px] px-3">
						<span className="flex scale-[3.5] w-[18px] ml-[20px] my-[30px]">
							<NewTrash strokeWidth="0.5" />
						</span>
						<div className="flex flex-col gap-4 mb-3">
							<SypacText variant="body-regular-medium">
								<p className="text-2xl text-gray-80">
									<T keyName="modalDeletePhoto.areYouSure">
										Are you sure you want to delete this photo?
									</T>
								</p>
							</SypacText>

							<div
								style={{
									backgroundImage: `url(${url})`,
								}}
								className="relative w-full h-[220px] bg-no-repeat bg-cover bg-center rounded-10 border border-solid border-gray-10 cursor-pointer box-border"
							/>
						</div>
					</div>

					<div className="w-full flex justify-center p-3 gap-3 border-0 border-t border-solid border-gray-10 box-border">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[42px] flex items-center justify-center rounded-lg transition"
								onClick={onClose}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="modalDeleteProduct.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>

						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[42px] flex items-center justify-center bg-primary-violet border-0 rounded-lg transition hover:bg-primary-violet/70"
								onClick={handleDelete}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-white">
										<T keyName="modalSelfPickup.confirm">Confirm</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ModalDeletePhoto;
