const Back = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="5"
			height="10"
			fill="none"
			viewBox="0 0 5 10"
		>
			<path
				stroke="#494C83"
				strokeLinecap="round"
				strokeWidth="1.5"
				d="M4 1 1.414 3.586a2 2 0 0 0 0 2.828L4 9"
			></path>
		</svg>
	);
};

export default Back;
