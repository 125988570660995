import React from 'react';

interface GrLoaderProps {
	primaryColor?: string;
	secondaryColor?: string;
	backgroundColor?: string;
	strokeWidth?: string;
	animationSpeed?: string;
}

export const SypacGrLoader = (props: GrLoaderProps) => {
	const {
		primaryColor = '#494C83',
		secondaryColor = '#7693F4',
		backgroundColor = '#E8E8E8',
		strokeWidth = '2.5',
		animationSpeed = '2s',
	} = props;

	return (
		<svg width="80" height="80" viewBox="0 0 50 50">
			<defs>
				<linearGradient id="gradientArc" x1="0%" y1="0%" x2="100%" y2="0%">
					<stop offset="0%" stopColor={primaryColor} />
					<stop offset="100%" stopColor={secondaryColor} />
				</linearGradient>
			</defs>
			<circle
				cx="25"
				cy="25"
				r="20"
				fill="none"
				stroke={backgroundColor}
				strokeWidth={strokeWidth}
			/>
			<circle
				cx="25"
				cy="25"
				r="20"
				fill="none"
				stroke="url(#gradientArc)"
				strokeWidth={strokeWidth}
				strokeDasharray="100, 200"
				strokeDashoffset="0"
				strokeLinecap="round"
			>
				<animateTransform
					attributeName="transform"
					type="rotate"
					from="0 25 25"
					to="360 25 25"
					dur={animationSpeed}
					repeatCount="indefinite"
				/>
			</circle>
		</svg>
	);
};
