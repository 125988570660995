import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import Close from '../../../../../assets/Close';
import { Checkmark } from '../../../../../assets/Checkmark';
import { OrderInterface } from '../../../../../components/OrdersTable/interfaces/Order.interface';
import { OrderTons } from '../../../../../assets/OrderTons';
import { DumpTruck } from '../../../../../assets/DumpTruck';
import { SemiTrailer } from '../../../../../assets/SemiTrailer';
import { Hooklifter } from '../../../../../assets/Hooklifter';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FileService } from '../../../../../services/file.service';
import { OrderContext } from '../../../../../context/OrderContext/order.context';
import LoadedQuantityIcon from '../../../../../assets/LoadedQuantityIcon';
import { T, useTranslate } from '@tolgee/react';
import PdfIcon from '../../../../../assets/PdfIcon';
import LoadingSpinner from '../../../../../assets/LoadingSpinner';
import AlertSmall from '../../../../../assets/AlertSmall';

export interface ModalLoadDetailsProps {
	isOpen: boolean;
	onClose: () => void;
	selectedOrder?: OrderInterface;
	handleClick: any;
}

const ModalLoadDetails = (props: ModalLoadDetailsProps) => {
	const { t } = useTranslate();
	const { isOpen, onClose, selectedOrder, handleClick } = props;
	const [nextStep, setNextStep] = useState<boolean>(false);
	const [addInfo, setAddInfo] = useState<boolean>(false);
	const [localFiles, setLocalFiles] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
	const [charCount, setCharCount] = useState<number>(0);
	const [uploadComplete, setUploadComplete] = useState<boolean>(false);
	const [confirmWeight, setConfirmWeight] = useState<boolean>(false);
	const [isEditable, setIsEditable] = useState<boolean>(true);
	const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
	const { setAdditional } = useContext(OrderContext);
	const modalRef = useRef<HTMLDivElement>(null);

	const truckType = useMemo(
		() =>
			selectedOrder?.trucks ? selectedOrder?.trucks[0].truckType : undefined,
		[selectedOrder?.trucks],
	);
	const truckPayload = useMemo(
		() =>
			selectedOrder?.trucks ? selectedOrder?.trucks[0].payload : undefined,
		[selectedOrder?.trucks],
	);
	const truckPlates = useMemo(
		() =>
			selectedOrder?.trucks
				? selectedOrder?.trucks[0]?.driver?.licensePlates
				: undefined,
		[selectedOrder?.trucks],
	);

	useEffect(() => {
		setAdditional((prev) => ({
			...prev,
			orderModalRef: modalRef,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const formik = useFormik({
		initialValues: {
			weightNumber: '',
			info: '',
			files: [],
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			handleClick({
				wight: values.weightNumber,
				info: values.info,
				files: values.files,
			});
			handleModalClose();
		},
		validationSchema: Yup.object({
			weightNumber: Yup.string()
				.required(
					t(
						'modalLoadDetails.loadedWeightRequired',
						'Loaded weight is required.',
					),
				)
				.test(
					'is-decimal',
					t(
						'modalLoadDetails.maximumOfTwoDecimal',
						'A maximum of 2 decimal places is allowed.',
					),
					(value) => /^\d+(\.\d{0,2})?$/.test(value!),
				)
				.test(
					'is-minimum',
					t(
						'modalLoadDetails.minimumLoadedQuantity',
						'The loaded quantity must be at least 0.01 tons. Please input a valid amount.',
					),
					(value) => parseFloat(value!) >= 0.01,
				)
				.test(
					'is-maximum',
					t(
						'modalLoadDetails.maximumLoadedQuantity',
						'The loaded quantity cannot exceed 30.00 tons. Please reduce the amount.',
					),
					(value) => parseFloat(value!) <= 30,
				)
				.test(
					'no-leading-zero-without-decimals',
					t(
						'modalLoadDetails.minimumLoadedQuantity',
						'The loaded quantity must be at least 0.01 tons. Please input a valid amount.',
					),
					(value) => {
						if (!value) return false;
						if (value.startsWith('0')) {
							return /^0\.\d{1,2}$/.test(value) && parseFloat(value) > 0.01;
						}
						return true;
					},
				),
		}),
	});

	const toNextStep = () => {
		if (
			formik.isValid &&
			+formik.values.weightNumber <= selectedOrder?.quantity!
		) {
			setNextStep(true);
			setConfirmWeight(false);
			setIsEditable(true);
		} else {
			setConfirmWeight(true);
			setIsEditable(false);
		}
	};

	const handleEditClick = () => {
		setIsEditable(true);
		setConfirmWeight(false);
	};

	const confirmWeightDiff = () => {
		if (formik.isValid) {
			setNextStep(true);
		}
	};

	// @ts-ignore
	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleLocalFiles = (selectedFiles: FileList, fileName?: string) => {
		const dataFiles: any[] = [];
		for (let file of selectedFiles) {
			const values = file.name.split('.');
			let mimeType = values[1]?.toUpperCase() || '';
			if (file.type) {
				mimeType = file.type.split('/').pop()?.toUpperCase() || mimeType;
			}
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;
			dataFiles.push({
				name: fileName || 'Other',
				mimeType,
				size,
				file,
			});
		}

		setLocalFiles((prevFiles) => [...prevFiles, ...dataFiles]);
		setDropDownOpen(false);
	};

	const uploadFiles = async (selectedFiles: any) => {
		setIsLoading(true);
		try {
			const files = selectedFiles.map((item: any) => item.file);
			const resp = await FileService.uploadFiles(files);
			formik.setFieldValue('files', resp.data.items);
			setUploadComplete(true);
		} catch (e) {
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (uploadComplete) {
			formik.handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadComplete]);

	const handleRemoveFile = (index: number) => {
		const updatedFiles = [...localFiles];
		updatedFiles.splice(index, 1);
		setLocalFiles(updatedFiles);
	};

	// @ts-ignore
	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			handleLocalFiles(e.dataTransfer.files);
		}
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		documentName: string,
	) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			handleLocalFiles(e.target.files, documentName);
		}
	};

	const handleModalClose = () => {
		formik.resetForm();
		handleEditClick();
		setDropDownOpen(false);
		onClose();
		setNextStep(false);
		setAddInfo(false);
		setCharCount(0);
		setLocalFiles([]);
		closeDropdown();
	};

	const handleTextareaChange = (event: any) => {
		const text = event.target.value;
		formik.handleChange(event);
		setCharCount(text.length);
	};

	const openDropdown = () => {
		setDropDownOpen(true);
	};

	const closeDropdown = () => {
		setDropDownOpen(false);
	};

	const TruckIcon = ({ type }: { type: string }) => {
		switch (type) {
			case 'aluminium':
				return <DumpTruck />;
			case 'steel':
				return <SemiTrailer />;
			case 'hooklifter':
				return <Hooklifter />;
			default:
				return null;
		}
	};

	const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const formattedValue = value.replace(',', '.');
		formik.setFieldValue('weightNumber', formattedValue);
		formik.validateField('weightNumber');
	};

	return isOpen ? (
		<div className="modal-overlay" onClick={() => handleModalClose()}>
			<div
				className="absolute bg-white outline-none rounded-xl w-[516px] h-fit"
				onClick={(e) => e.stopPropagation()}
				ref={modalRef}
			>
				<form className="flex flex-col h-full overflow-x-hidden">
					<div className="flex items-center justify-between p-3">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90 mt-[4px]">
								{nextStep ? (
									<T keyName="modalLoadDetails.uploadDocument">
										Upload documents
									</T>
								) : (
									<T keyName="modalLoadDetails.enterLoadedWeight">
										Enter loaded weight
									</T>
								)}
							</p>
						</SypacText>
						<SypacButton variant="subTitle">
							<button
								type="button"
								className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
								onClick={handleModalClose}
							>
								<Close width="10" height="10" />
							</button>
						</SypacButton>
					</div>
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

					{nextStep ? (
						<div
							className={`flex flex-col justify-between pt-2 pb-3 ${
								localFiles.length > 8
									? 'w-[531px] h-[695px] overflow-x-hidden overflow-y-scroll'
									: ''
							}`}
						>
							<div className="flex flex-col gap-2">
								<div className="flex justify-between items-center mx-3 py-1">
									<div className="flex items-center gap-2">
										<div className="flex justify-between items-center rounded-md px-1.5 py-1 bg-gray-10">
											<SypacText variant="body-regular-medium">
												<p className="text-xs text-gray-80">
													<T keyName="clientSelectDate.truck">Truck</T> 1
												</p>
											</SypacText>
										</div>

										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80">
												<T keyName="driverConfirmed.truckPlates">
													Truck plates:
												</T>{' '}
												{truckPlates || '-'}
											</p>
										</SypacText>
									</div>

									<div className="relative flex gap-2 items-end">
										<span
											className={`absolute flex ${
												truckType === 'hooklifter' ? 'bottom-[-2px]' : ''
											}`}
										>
											<TruckIcon type={truckType!} />
										</span>
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80 ml-[64px]">
												{truckType &&
													truckPayload &&
													`${
														truckType?.charAt(0).toUpperCase() +
														truckType?.slice(1)
													} / Payload: ${truckPayload} tons`}
											</p>
										</SypacText>
									</div>
								</div>
								<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

								<div className="flex gap-6">
									<div className="flex items-center gap-6 mx-3 pl-[2px]">
										<span className="flex scale-[1.25]">
											<OrderTons />
										</span>
										<div className="flex flex-col gap-1 mt-[8px]">
											<SypacText variant="body-regular-medium">
												<p className="text-xs text-gray-40">
													<T keyName="modalLoadDetails.orderedQuantity">
														Ordered quantity
													</T>
												</p>
											</SypacText>

											<SypacText variant="body-regular-medium">
												<p className="text-base text-gray-80">{`${selectedOrder?.quantity} tons`}</p>
											</SypacText>
										</div>
									</div>
									<div className="flex items-center gap-6 mx-3 pl-[2px]">
										<span className="flex scale-[1.25]">
											<LoadedQuantityIcon />
										</span>
										<div className="flex flex-col gap-1 mt-[8px]">
											<SypacText variant="body-regular-medium">
												<p className="text-xs text-gray-40">
													<T keyName="modalLoadDetails.loadedQuantity">
														Loaded quantity
													</T>
												</p>
											</SypacText>

											<SypacText variant="body-regular-medium">
												<p className="text-base text-gray-80">{`${formik.values.weightNumber} tons`}</p>
											</SypacText>
										</div>
									</div>
								</div>

								<div className="flex flex-col gap-3 bg-alabaster border border-solid border-gray-10 rounded-10 p-3 mx-3">
									<SypacText variant="body-regular-medium">
										<p className="text-xs text-gray-40">
											<T keyName="modalLoadDetails.proofOfLoading">
												Proof of Loading
											</T>
										</p>
									</SypacText>

									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-80">
											<T keyName="modalLoadDetails.pleaseUploadProofLoadingDocuments">
												Please upload proof of loading documents, including the
												weight certificate, certificate of quality, or any other
												documents verifying the loading of the ordered goods.
											</T>
										</p>
									</SypacText>

									<div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-2">
										{localFiles.map((file, index) => (
											<div
												className="flex flex-row"
												key={file?.name || Math.random() + index}
											>
												<div className="w-[66px] h-[66px] flex justify-center items-center border border-solid border-gray-10 rounded-lg">
													<PdfIcon />
												</div>
												<div className="flex flex-col ml-4">
													<SypacText variant="body-regular-medium">
														<p className="text-gray-80">{file.name}</p>
													</SypacText>
													<div className="flex flex-row justify-start items-center mt-1">
														<SypacText variant="overline-regular-large">
															<p className="text-gray-20">{file.mimeType} </p>
														</SypacText>
														<div className="bg-gray-20 w-1 h-1 rounded-full mx-2" />
														<SypacText variant="overline-regular-large">
															<p className="text-gray-20">{file.size} </p>
														</SypacText>
													</div>
													<SypacText
														variant="overline-normal-large"
														className="mt-2 cursor-pointer"
														onClick={() => handleRemoveFile(index)}
													>
														<p className="text-red">
															<T keyName="uploadDocuments.deleteFile">
																Delete file
															</T>
														</p>
													</SypacText>
												</div>
											</div>
										))}
									</div>
									<button
										onDragEnter={handleDrag}
										onDragLeave={handleDrag}
										onDragOver={handleDrag}
										onDrop={handleDrop}
										type="button"
										onClick={openDropdown}
										className="relative w-full py-2.5 bg-white rounded-lg border border-solid border-primary-violet transition hover:border-gray-20 cursor-pointer"
									>
										<SypacText variant="body-regular-medium">
											<p className="text-primary-violet">
												<T keyName="modalLoadDetails.uploadDocumentButton">
													Upload document
												</T>
											</p>
										</SypacText>
									</button>

									{submitAttempted && localFiles.length === 0 ? (
										<div className="flex gap-3">
											<span className="flex scale-[1.1]">
												<AlertSmall color="#F7A2B8" secondColor="#F44A77" />
											</span>
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-red">
													<T keyName="modalLoadDetails.pleaseUploadYourDocumentsBeforeSubmitting">
														Please upload your documents before submitting.
													</T>
												</p>
											</SypacText>
										</div>
									) : null}

									<div
										className={`absolute -bottom-[12px] w-[468px] items-start gap-2 bg-white border border-solid border-gray-10 p-[4px] box-border rounded-[10px] ${
											dropDownOpen ? 'flex flex-col' : 'hidden'
										}`}
									>
										<div className="box-border w-full flex justify-start px-3 py-2 bg-transparent border border-solid border-transparent transition hover:border-cornflower-blue rounded-lg">
											<input
												type="file"
												id="input-file-upload-weight-certificate"
												className="hidden"
												multiple={true}
												accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
												onChange={(e) => {
													handleChange(e, 'Weight certificate');
												}}
											/>
											<label
												htmlFor="input-file-upload-weight-certificate"
												className="flex flex-col justify-center items-center cursor-pointer"
											>
												<p className="m-0 text-base text-gray-80">
													Weight certificate
												</p>
											</label>
										</div>
										<div className="box-border w-full flex justify-start px-3 py-2 bg-transparent border border-solid border-transparent transition hover:border-cornflower-blue rounded-lg">
											<input
												type="file"
												id="input-file-upload-certificate-of-quality"
												className="hidden"
												multiple={true}
												accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
												onChange={(e) => {
													handleChange(e, 'Certificate of quality');
												}}
											/>
											<label
												htmlFor="input-file-upload-certificate-of-quality"
												className="flex flex-col justify-center items-center cursor-pointer"
											>
												<p className="m-0 text-base text-gray-80">
													Certificate of quality
												</p>
											</label>
										</div>
										<div className="box-border w-full flex justify-start px-3 py-2 bg-transparent border border-solid border-transparent transition hover:border-cornflower-blue rounded-lg">
											<input
												type="file"
												id="input-file-upload-other"
												className="hidden"
												multiple={true}
												accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
												onChange={(e) => {
													handleChange(e, 'Other');
												}}
											/>
											<label
												htmlFor="input-file-upload-other"
												className="flex flex-col justify-center items-center cursor-pointer"
											>
												<p className="m-0 text-base text-gray-80">Other</p>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="flex flex-col gap-4 mt-2 mb-3">
							<div className="flex flex-col gap-2">
								<div className="flex justify-between items-center mx-3 py-1">
									<div className="flex items-center gap-2">
										<div className="flex justify-between items-center rounded-md px-1.5 py-1 bg-gray-10">
											<SypacText variant="body-regular-medium">
												<p className="text-xs text-gray-80">
													<T keyName="clientSelectDate.truck">Truck</T> 1
												</p>
											</SypacText>
										</div>

										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80">
												<T keyName="driverConfirmed.truckPlates">
													Truck plates:
												</T>{' '}
												{truckPlates || '-'}
											</p>
										</SypacText>
									</div>

									<div className="relative flex gap-2 items-end">
										<span
											className={`absolute flex ${
												truckType === 'hooklifter' ? 'bottom-[-2px]' : ''
											}`}
										>
											<TruckIcon type={truckType!} />
										</span>
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-80 ml-[64px]">
												{truckType &&
													truckPayload &&
													`${
														truckType?.charAt(0).toUpperCase() +
														truckType?.slice(1)
													} / Payload: ${truckPayload} tons`}
											</p>
										</SypacText>
									</div>
								</div>
								<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

								<div className="flex items-center gap-6 mx-3 pl-[2px]">
									<span className="flex scale-[1.25]">
										<OrderTons />
									</span>
									<div className="flex flex-col gap-1 mt-[8px]">
										<SypacText variant="body-regular-medium">
											<p className="text-xs text-gray-40">
												<T keyName="modalLoadDetails.orderedQuantity">
													Ordered quantity
												</T>
											</p>
										</SypacText>

										<SypacText variant="body-regular-medium">
											<p className="text-base text-gray-80">{`${selectedOrder?.quantity} tons`}</p>
										</SypacText>
									</div>
								</div>

								<div className="bg-alabaster border border-solid border-gray-10 rounded-10 p-3 mx-3">
									<SypacInput
										className="flex gap-2"
										error={
											!!(
												formik.touched.weightNumber &&
												formik.errors.weightNumber
											)
										}
									>
										<SypacText variant="overline-normal-large" className="mb-1">
											<p className="text-sm">
												<T keyName="modalLoadDetails.loadedWeightTons">
													Loaded weight (tons)
												</T>{' '}
												<span className="text-red">*</span>
											</p>
										</SypacText>
										<input
											type="string"
											name="weightNumber"
											className={`py-2.5 pl-3 border rounded-xl placeholder:text-gray-40 placeholder:text-base ${
												formik.errors.weightNumber &&
												formik.touched.weightNumber
													? 'border-red'
													: ''
											}`}
											placeholder={t(
												'modalLoadDetails.enterLoadedWeight',
												'Enter loaded weight',
											)}
											onChange={handleWeightChange}
											onBlur={formik.handleBlur}
											value={formik.values.weightNumber}
											disabled={!isEditable}
										/>
										{formik.touched.weightNumber &&
										formik.errors.weightNumber ? (
											<div className="flex items-center gap-2">
												<span className="flex scale-[1.1]">
													<AlertSmall color="#F7A2B8" secondColor="#F44A77" />
												</span>
												<span className="input-error">
													{formik.errors.weightNumber}
												</span>
											</div>
										) : null}
									</SypacInput>

									{confirmWeight ? (
										<div className="flex flex-col mt-3 gap-3">
											<div className="flex gap-3">
												<span className="flex scale-[1.1]">
													<AlertSmall color="#F7A2B8" secondColor="#F44A77" />
												</span>
												<SypacText variant="body-regular-medium">
													<p className="text-sm text-red">
														<T keyName="modalLoadDetails.areYouSureToSubmitWeight">
															Are you sure you want to submit this weight?
														</T>
													</p>
												</SypacText>
											</div>

											<div className="flex flex-col gap-5 pl-[30px]">
												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-80">
														<T keyName="modalLoadDetails.pleaseReviewTheDifference">
															The loaded quantity differs from the ordered
															amount. Please review the difference:
														</T>
													</p>
												</SypacText>

												<div className="flex flex-col">
													<SypacText variant="body-regular-medium">
														<p className="text-sm text-gray-80">
															1.{' '}
															<T keyName="baseOrderDetails.orderedQuantity">
																Ordered quantity:
															</T>{' '}
															{`${selectedOrder?.quantity} tons`}
														</p>
													</SypacText>
													<SypacText variant="body-regular-medium">
														<p className="text-sm text-gray-80">
															2.{' '}
															<T keyName="baseOrderDetails.loadedQuantity">
																Loaded quantity:
															</T>{' '}
															{`${formik.values.weightNumber} tons`}
														</p>
													</SypacText>
													<SypacText variant="body-regular-medium">
														<p className="text-sm text-gray-80">
															3.{' '}
															<T keyName="modalLoadDetails.weightDifference">
																Weight difference:
															</T>{' '}
															{`${parseFloat(
																(
																	((+formik.values.weightNumber -
																		(selectedOrder?.quantity || 0)) /
																		(selectedOrder?.quantity || 1)) *
																	100
																).toFixed(2),
															)}%`}
														</p>
													</SypacText>
												</div>

												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-80">
														<T keyName="modalLoadDetails.verifyAndConfirm">
															Verify and confirm before proceeding.
														</T>
													</p>
												</SypacText>
											</div>
										</div>
									) : null}

									{addInfo ? (
										<SypacInput className="w-full flex gap-2 mt-3">
											<SypacText
												variant="overline-normal-large"
												className="mb-1"
											>
												<p className="text-sm">
													<T keyName="modalLoadDetails.additionalInformation">
														Additional information
													</T>
												</p>
											</SypacText>
											<div className="relative w-full">
												<textarea
													name="info"
													className="w-full box-border h-[98px] font-sans py-3.5 pl-3 border border-solid border-gray-20 hover:border-gray-40/75 focus:border-[#5682fa] rounded-xl placeholder:text-gray-40 placeholder:text-base resize-none outline-none"
													rows={3}
													maxLength={255}
													placeholder={t(
														'modalLoadDetails.writeAdditionalInformation',
														'Write additional information',
													)}
													onChange={handleTextareaChange}
													onBlur={formik.handleBlur}
													value={formik.values.info}
												/>
												<div className="absolute bottom-[12px] right-[12px] flex border border-solid border-gray-10 text-xs text-gray-40 rounded-2xl px-1 py-[1px]">{`${charCount}/255`}</div>
											</div>
										</SypacInput>
									) : null}

									<div className="w-full flex justify-end pt-3">
										<SypacButton variant="secondary" size="small">
											<button
												className="flex gap-3 items-center bg-transparent border-none p-0"
												type="button"
												onClick={() => {
													setAddInfo((prev) => {
														const newValue = !prev;
														if (!newValue) {
															formik.setFieldValue('info', '');
														}
														return newValue;
													});
												}}
											>
												<p className="m-0 text-[22px]">{addInfo ? '-' : '+'}</p>
												<SypacText variant="body-regular-medium">
													<p className="mt-[2px]">
														{addInfo ? (
															<T keyName="modalLoadDetails.removeAdditionalInfo">
																Remove additional info
															</T>
														) : (
															<T keyName="modalLoadDetails.addAdditionalInfo">
																Add additional info
															</T>
														)}
													</p>
												</SypacText>
											</button>
										</SypacButton>
									</div>
								</div>
							</div>
						</div>
					)}

					<div
						className={`mt-auto ${
							localFiles.length > 8 ? 'shadow-pagination' : ''
						}`}
					>
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
						<div className="flex w-full py-3 gap-4 [&_button]:transition [&_button]:rounded-lg">
							{['Back', 'Cancel'].map(
								(label, index) =>
									nextStep === Boolean(index) && (
										<SypacButton
											key={label}
											variant="secondary"
											size="small"
											className="w-full pl-3"
										>
											<button
												className="w-full py-2.5"
												type="button"
												onClick={() =>
													nextStep
														? setNextStep(false)
														: confirmWeight
														? handleEditClick()
														: handleModalClose()
												}
											>
												<SypacText variant="body-regular-medium">
													<p>
														<T
															keyName={`modalLoadDetails.${
																nextStep
																	? 'back'
																	: confirmWeight
																	? 'edit'
																	: 'cancel'
															}`}
														>
															{nextStep
																? 'Back'
																: confirmWeight
																? 'Edit'
																: 'Cancel'}
														</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
									),
							)}

							{['submitDocuments', 'submitWeight'].map(
								(action, index) =>
									nextStep === !Boolean(index) && (
										<SypacButton
											key={action}
											variant="primary"
											size="small"
											className="w-full pr-3"
										>
											<button
												className="w-full py-2.75"
												type="button"
												onClick={() => {
													if (nextStep) {
														setSubmitAttempted(true);
														if (localFiles.length === 0) {
															return;
														}
														uploadFiles(localFiles).then(() => {});
													} else {
														confirmWeight ? confirmWeightDiff() : toNextStep();
													}
												}}
												disabled={
													nextStep
														? false
														: !formik.isValid || !formik.values.weightNumber
												}
											>
												{nextStep ? (
													isLoading ? (
														<LoadingSpinner />
													) : (
														<Checkmark />
													)
												) : (
													<Checkmark />
												)}
												<SypacText variant="body-regular-medium">
													<p className="ml-3 text-white">
														<T keyName={`modalLoadDetails.${action}`}>
															{nextStep ? 'Submit documents' : 'Submit weight'}
														</T>
													</p>
												</SypacText>
											</button>
										</SypacButton>
									),
							)}
						</div>
					</div>
				</form>
			</div>
		</div>
	) : null;
};

export default ModalLoadDetails;
