import React, { useState, useContext } from 'react';
import { T } from '@tolgee/react';
import {
	SypacBreadcrumbs,
	SypacButton,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';
import Close from '../../../../assets/Close';
import { useNavigate } from 'react-router-dom';
import { useGetCategories } from '../../../../hooks/use-get-categories';
import { SelectProductProps } from '../productDatabase.interface';
import {
	ProductContext,
	ProductProps,
} from '../../../../context/DatabaseProductContext/database-product.context';
import Flag from 'react-world-flags';
import { COUNTRIES } from '../../../../components/DropDownOption/countries.constant';
import NewAddPlus from '../../../../assets/NewAddPlus';

const SelectProductCategory: React.FC<SelectProductProps> = ({ setStep }) => {
	const navigate = useNavigate();
	const { product, setProduct } = useContext(ProductContext);
	const [groups] = useGetCategories({}, 'admin');
	const [categories] = useGetCategories(
		{
			parentIds: [product?.productGroup!],
		},
		'admin',
	);
	const [showBlur, setShowBlur] = useState<string | undefined>(undefined);

	const breadcrumbs = [
		{
			name: 'Country store',
			onClick: () => setStep((prevStep) => prevStep - 2),
		},
		{
			name: groups?.items.find((group) => group.id === product?.productGroup)
				?.name,
			onClick: () => setStep((prevStep) => prevStep - 1),
		},
		{
			name: 'Category',
			onClick: () => {},
		},
	];

	const handleImageBlur = (key?: string) => {
		if (key !== showBlur) {
			setShowBlur(key);
		}
	};

	const handleCategorySelect = (category: any) => {
		setProduct({
			...product,
			categoryId: category.id,
		} as ProductProps);

		setStep((prevStep) => prevStep + 1);
	};

	return (
		<div className="w-full h-full flex items-center justify-center">
			<div className="min-w-[300px] flex flex-col">
				<div className="flex justify-between items-center mb-12">
					<SypacText variant="body-normal-medium">
						<p className="text-gray-60">
							<T keyName="productGroup.manageProduct">
								Manage product groups and categories
							</T>
						</p>
					</SypacText>
					<SypacButton variant="secondary" size="medium">
						<button
							onClick={() => navigate('/product-database')}
							className="p-2 border border-solid border-gray-10 transition hover:border-primary-violet"
						>
							<Close />
						</button>
					</SypacButton>
				</div>

				<div className="flex flex-col gap-2 mb-[62px]">
					<SypacText variant="heading-5">
						<p className="text-xl text-gray-80">
							<T keyName="selectProductStore.selectCategory">Select category</T>
						</p>
					</SypacText>

					<div className="flex justify-between gap-[150px]">
						<SypacBreadcrumbs>
							{breadcrumbs.map((item, i) => (
								<SypacLink key={i} variant="breadcrumbs">
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a
										className={`text-sm ${
											i === breadcrumbs.length - 1
												? 'text-cornflower-blue pointer-events-none'
												: 'text-gray-60 cursor-pointer'
										}`}
										onClick={(e) => {
											e.preventDefault();
											item.onClick?.();
										}}
									>
										{i === 0 ? (
											<div className="flex items-center gap-3">
												<Flag
													className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
													code={product?.countryCode}
													width={22}
													height={16}
												/>
												<SypacText variant="body-regular-medium">
													<p className="text-sm mt-[2px]">
														{
															COUNTRIES.find(
																(country) =>
																	country['alpha-3'] === product?.countryCode,
															)?.label
														}
													</p>
												</SypacText>
											</div>
										) : (
											item.name
										)}
									</a>
								</SypacLink>
							))}
						</SypacBreadcrumbs>

						<SypacButton variant="subTitle" className="ml-auto">
							<button
								type="button"
								className="flex gap-3 items-center p-0 bg-transparent group"
								onClick={() => {}}
							>
								<NewAddPlus hoverEffect={true} />
								<SypacText variant="body-regular-medium">
									<p className="text-primary-violet mt-[2.5px] transition group-hover:text-primary-violet/70">
										<T keyName="selectProductCategory.createNewCategory">
											Create new category
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>

				<div
					className={`grid gap-[20px] grid-cols-${
						categories?.items.length! < 5 ? categories?.items.length : 5
					}`}
				>
					{categories?.items.map((category) => (
						<div
							key={category.id}
							className="w-fit flex flex-col gap-3 p-[10px] border border-solid border-gray-10 rounded-xl cursor-pointer transition hover:bg-gray-10/20"
							onClick={() => handleCategorySelect(category)}
							onMouseEnter={() => handleImageBlur(category.id)}
							onMouseLeave={() => handleImageBlur(undefined)}
						>
							<div
								style={{
									backgroundImage: `url(${category.imageUrl})`,
								}}
								className="relative w-[246px] h-[174px] bg-no-repeat bg-cover bg-center border border-solid border-gray-10 rounded-10 box-border"
							>
								{showBlur === category.id ? (
									<div className="absolute w-full h-full bg-black-20 rounded-10" />
								) : null}
							</div>

							<div className="flex flex-col gap-[10px]">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">{category.name}</p>
								</SypacText>

								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40">
										{category.countPrododucts}{' '}
										<T keyName="clientSelectCategory.productsInThisCategory">
											products in this category
										</T>
									</p>
								</SypacText>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};
export default SelectProductCategory;
