import { CompanyResponse } from '../../../services/company.services';
import React, { memo } from 'react';
import dayjs from 'dayjs';

import { useGetInvoiceStatsByCompnayId } from '../../../hooks/use-get-stats';
import CompanyStats from '../../../components/CompanyStats/CompanyStats';
import { CompanyAllStats } from '../../../components/CompanyStats/CompanyStats.interface';

interface Props {
	company: CompanyResponse;
}

export const StatsTAB: React.FC<Props> = memo(({ company }: Props) => {
	// TODO replace type with company ID when while implemented
	const [stats] = useGetInvoiceStatsByCompnayId(company.id!, {});
	const [stats30] = useGetInvoiceStatsByCompnayId(company.id!, {
		fromDate: dayjs().subtract(30, 'days').format(),
	});
	const [stats60] = useGetInvoiceStatsByCompnayId(company.id!, {
		fromDate: dayjs().subtract(60, 'days').format(),
	});
	const [stats90] = useGetInvoiceStatsByCompnayId(company.id!, {
		fromDate: dayjs().subtract(90, 'days').format(),
	});
	const payload = {
		...stats,
		revenueLast30Days: stats30?.amountTotal?.all || '0',
		revenueLast60Days: stats60?.amountTotal?.all || '0',
		revenueLast90Days: stats90?.amountTotal?.all || '0',
		ordersLast30Days: stats30?.countOrders?.all || '0',
		ordersLast60Days: stats60?.countOrders?.all || '0',
		paymentTerm: company.paymentTerm,
		nextInvoicingDate: company.nextInvoicingDate,
		amount: stats?.amountTotal.pending,
	} as CompanyAllStats;
	return (
		<div className="px-10">
			<CompanyStats
				companyType={company.companyTypes[0]}
				stats={payload}
				countryCode={company.countryCode}
			/>
		</div>
	);
});
