import React, { DragEvent, useState } from 'react';
import Modal from 'react-modal';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import CircleClose from '../../../assets/CircleClose';
import CircleValid from '../../../assets/CircleValid';
import PreviewDocument from '../../../assets/PreviewDocument';
import { T } from '@tolgee/react';
import { toastVariant } from '../../CompaniesTable/toastVariant/toastVariant';

export const MAX_FILE_SIZE_MB: number = 9;

interface UploadLocationPhotoProps {
	isOpen: boolean;
	onSave: (file?: any) => void;
}

const UploadLocationPhoto: React.FC<UploadLocationPhotoProps> = ({
	isOpen,
	onSave,
}) => {
	const [file, setFile] = useState<File>();

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFile = e.dataTransfer.files[0];
			if (droppedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			setFile(droppedFile);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFile = e.target.files[0];
			if (selectedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			setFile(selectedFile);
		}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<>
			<Modal
				isOpen={isOpen}
				onRequestClose={() => onSave()}
				className="modal-inside outline-none max-w-[408px]"
				overlayClassName="modal-overlay"
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between px-6 py-4">
						<SypacText variant="body-regular-medium">
							<p className="text-gray-90">
								<T keyName="uploadLocationPhoto.uploadDeliveryLocationPhoto">
									Do you wish to upload delivery location photo
								</T>
							</p>
						</SypacText>
						<SypacIcon
							iconName="Close Square"
							className="text-gray-10 ml-6 cursor-pointer"
							size="custom"
							width="32px"
							height="32px"
							onClick={() => onSave()}
						/>
					</div>

					<div className="flex flex-col items-start justify-center modal-confirmation-code-border p-6 gap-4">
						{file ? (
							<div className="flex flex-row py-6 justify-between items-center">
								<div className="flex flex-row justify-start">
									<div>
										<PreviewDocument />
									</div>
									<div className="flex flex-col ml-4">
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">{file.name}</p>
										</SypacText>
										<div className="flex flex-row justify-start items-center mt-3">
											<SypacText variant="overline-regular-large">
												<p className="text-gray-20">
													{`${(file.size / (1024 * 1024)).toFixed(1)} MB`}{' '}
												</p>
											</SypacText>
										</div>
									</div>
								</div>
							</div>
						) : null}
						{!file ? (
							<div className="flex flex-col justify-start w-full mt-6">
								<div
									onDragEnter={handleDrag}
									onDragLeave={handleDrag}
									onDragOver={handleDrag}
									onDrop={handleDrop}
									className="border border-gray-90 border-dashed rounded-10 h-24 w-full mt-4"
								>
									<input
										type="file"
										id="input-file-upload"
										className="hidden"
										multiple={true}
										accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
										onChange={handleChange}
									/>
									<label
										htmlFor="input-file-upload"
										className="flex flex-col justify-center items-center"
									>
										<SypacText variant="body-regular-medium">
											<div className="flex">
												<p className="text-gray-80">
													<T keyName="uploadLocationPhoto.dragAndDrop">
														Drag and drop or
													</T>{' '}
													<span className="text-cornflower-blue">
														<T keyName="uploadLocationPhoto.browseFile">
															Browse File
														</T>
													</span>
												</p>
											</div>
										</SypacText>

										<SypacText
											variant="overline-regular-medium"
											className="mt-3"
										>
											<p className="text-gray-40">
												<T keyName="uploadLocationPhoto.noteSupported">
													Note: We support JPG, PDF or PNG files.
												</T>
											</p>
										</SypacText>
									</label>
								</div>
							</div>
						) : null}
					</div>
					<div className="flex justify-center p-6">
						<div className="flex gap-x-4">
							<SypacButton
								variant="secondary"
								size="small"
								onClick={() => onSave()}
							>
								<button
									className="px-10 py-2.5 border-red flex flex-row justify-center items-center"
									type="reset"
								>
									<CircleClose />
									<SypacText variant="body-regular-medium" className="ml-3">
										<p className="text-red">
											<T keyName="uploadLocationPhoto.no">No</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
							<SypacButton
								variant="secondary"
								size="small"
								onClick={() => onSave(file)}
							>
								<button
									className="px-10 py-2.5 border-mountain-meadow flex flex-row justify-center items-center"
									type="reset"
									disabled={!file}
								>
									<CircleValid />
									<SypacText variant="body-regular-medium" className="ml-3">
										<p className="text-mountain-meadow">
											<T keyName="uploadLocationPhoto.save">Save</T>
										</p>
									</SypacText>
								</button>
							</SypacButton>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default UploadLocationPhoto;
