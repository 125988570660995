import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { ListMenu } from '../../../components/ListMenu/ListMenu';
import { useGetCategories } from '../../../hooks/use-get-categories';
import ClientsProductCard from '../../../components/ClientsProductCard/ClientsProductCard';
import '../select-product.scss';
import {
	OrderContext,
	OrderProps,
} from '../../../context/OrderContext/order.context';
import { useGetProducts } from '../../../hooks/use-get-products';
import { GeoService } from '../../../services/geo.services';
import { T } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import { OrdersService } from '../../../services/orders.services';
import ClientStoreBreadcrumbs from '../../../components/ClientStoreBreadcrumbs/ClientStoreBreadcrumbs';
import { CompanyService } from '../../../services/company.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import { Clock } from '../../../assets/Clock';
import BackArrow from '../../../assets/BackArrow';

const ClientSelectCategory: React.FC = () => {
	const { groupId, categoryId } = useParams();
	const [userCountry, setUserCountry] = useState<string>();
	const [groups] = useGetCategories({
		countryCode: userCountry,
	});
	const [coordinates, setCoordinates] = useState<number[]>([]);
	const [localRecentOrders, setLocalRecentOrders] = useState<OrderProps[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [categories, _, isCategoryLoading] = useGetCategories({
		parentIds: [groupId!],
		coordinates,
		countryCode: userCountry,
	});
	const [activeCategory, setActiveCategory] = useState<string>(categoryId!);
	const navigate = useNavigate();
	const { order, setOrder } = useContext(OrderContext);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [products, _e, isLoading] = useGetProducts(
		'all',
		activeCategory,
		100,
		0,
		'customer',
		userCountry!,
		coordinates,
	);

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setUserCountry(
				data.countryCode === 'MD'
					? 'MDA'
					: data.countryCode === 'PL'
					? 'POL'
					: '',
			);
		} catch (error) {
			toastVariant(`Something went wrong. ${error?.toString()}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const getRecentOrders = async () => {
		try {
			const query = {
				limit: 5,
				offset: 0,
			};
			const { data } = await OrdersService.getRecentOrders(query);
			if (data.count) {
				setLocalRecentOrders(data.items.slice(0, 3) || []);
			}
		} catch (e) {}
	};

	useEffect(() => {
		getRecentOrders().then(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectRecentOrder = (order: OrderProps) => {
		setOrder({ ...order });
		navigate(
			`/store/groups/${order?.group?.id}/categories/${order?.category?.id}/products/${order?.product?.id}/quantity`,
		);
	};

	const getLocation = useCallback(async (locationId: string) => {
		if (locationId) {
			const resp = await GeoService.getLocationDetails({
				locationid: locationId,
			});
			setCoordinates([resp.data.Longitude, resp.data.Latitude]);
		}
	}, []);

	useEffect(() => {
		if (order?.location?.locationId) {
			getLocation(order?.location?.locationId).then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order?.location?.locationId]);

	const listItems =
		categories?.items?.map((item) => {
			const handleClick = () => {
				if (item.id !== activeCategory) {
					setActiveCategory(item.id);
					navigate(`/store/groups/${groupId}/categories/${item.id}/products`);
				}
			};

			return {
				id: item.id,
				name: item.name,
				onClick: handleClick,
			};
		}) || [];

	const currentCategory = useMemo(() => {
		return categories?.items?.find((item) => item.id === activeCategory);
	}, [categories, activeCategory]);
	const currentGroup = useMemo(() => {
		return groups?.items?.find((item) => item.id === groupId);
	}, [groupId, groups?.items]);

	const renderRecentOrders = (order: OrderProps) => {
		return (
			<button
				key={order?.product?.id}
				className="flex items-center py-2.75 px-3 bg-transparent border-none rounded-lg cursor-pointer p-0 hover:bg-gray-10"
				onClick={() => selectRecentOrder(order)}
			>
				<div
					style={{
						backgroundImage: `url(${order?.product?.photoUrl})`,
					}}
					className="box-border flex flex-none w-[46px] h-[46px] bg-no-repeat bg-cover rounded-[8px] border border-solid border-gray-10"
				/>
				<div className="flex flex-col ml-3 items-start">
					<SypacText variant="body-normal-medium">
						<p
							className={`w-[197px] text-start truncate text-base text-gray-80 ${
								!order?.product?.type && !order?.product?.size
									? 'pb-[20px]'
									: ''
							}`}
						>
							{order?.product?.name}
						</p>
					</SypacText>
					<SypacText variant="body-normal-medium">
						<p className="text-sm text-gray-40">
							{order?.product?.type}{' '}
							{order?.product?.size && `(${order?.product?.size})`}
						</p>
					</SypacText>
				</div>
			</button>
		);
	};

	return (
		<div className="w-full flex flex-col h-[calc(100vh-30px)] overflow-hidden gap-x-2.5 mt-[68px] sm:mt-0">
			<div className="w-[1172px] xl:self-center flex justify-between items-center mb-8">
				<ClientStoreBreadcrumbs
					currentGroup={currentGroup}
					currentCategory={currentCategory}
				/>

				{/* <SearchBar
					placeholder={t(
						'clientSelectGroups.searchPlacehoder',
						'Search Products',
					)}
					showButton={false}
				/> */}
			</div>

			<div className="flex justify-center border border-solid border-gray-10 border-b-0 border-l-0 border-r-0">
				<div className="flex">
					<div className="h-fit flex flex-col gap-5 mt-5">
						<div className="mr-4">
							<ListMenu
								items={!isCategoryLoading ? listItems : []}
								selected={categoryId}
							/>
						</div>
						<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

						<div className="flex flex-col gap-5">
							<div className="flex gap-3 items-center">
								<Clock />
								<SypacText variant="body-regular-medium">
									<p className="text-base text-gray-40 mt-[2px]">
										<T keyName="clientSelectCategory.recent">Recent</T>
									</p>
								</SypacText>
							</div>

							{localRecentOrders.length ? (
								<div className="w-[255px] flex flex-col">
									{localRecentOrders.map((order: OrderProps) =>
										renderRecentOrders(order),
									)}
								</div>
							) : (
								<div className="w-[232px] flex flex-col gap-6 box-border">
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											<T keyName="clientSelectCategory.noRecentProductsPreviewed">
												You haven’t previewed any products recently
											</T>
										</p>
									</SypacText>
									<SypacText variant="body-regular-medium">
										<p className="text-xs text-gray-40">
											<T keyName="clientSelectCategory.noRecentProductsPreviewedDescription">
												Previously viewed products will be listed here
											</T>
										</p>
									</SypacText>
								</div>
							)}
						</div>
					</div>
					<div className="border border-solid border-gray-10 border-b-0 border-t-0 border-r-0" />

					<div className="h-[100vh] overflow-x-hidden scroll-smooth flex-1 pl-6 pt-[25px] pr-[12px]">
						<SypacButton
							variant="secondary"
							onClick={() => {
								navigate(-1);
							}}
							className="-ml-[8px] mb-6"
						>
							<button className="flex gap-6 border-none h-[24px] bg-transparent group">
								<span className="mt-[4px] transition group-hover:text-gray-80 group-hover:-translate-x-[6px] scale-[1.2]">
									<BackArrow />
								</span>
								<SypacText variant="body-normal-medium">
									<p className="text-gray-80">
										<T keyName="clientSelectCategory.backButton">Back</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>

						{/* <div className="flex justify-end">
							<Sorting
								options={sortOptions}
								action={(item) => setSorting(item)}
								current={sorting}
							/>
						</div> */}

						{isLoading ? (
							<div className="flex w-[864px] h-full justify-center mt-[30%]">
								<MutatingDots
									height="100"
									width="100"
									color="#7693F4"
									secondaryColor="#494C83"
									radius="12.5"
									ariaLabel="mutating-dots-loading"
									wrapperStyle={{}}
									wrapperClass=""
									visible={true}
								/>
							</div>
						) : (
							<div className="grid gap-6 md:grid-cols-[repeat(2,minmax(0,268px))] xl:grid-cols-[repeat(3,minmax(0,272px))] mb-[300px]">
								{products?.items.length ? (
									products?.items?.map((item) => {
										return (
											<ClientsProductCard
												key={item.id}
												data={item}
												onClick={() => {
													navigate(
														`/store/groups/${currentGroup?.id}/categories/${categoryId}/products/${item.id}`,
													);
												}}
											/>
										);
									})
								) : (
									<div className="flex flex-col md:w-[560px] xl:w-[864px] h-[252px] justify-between items-center text-center mt-[212px]">
										<SypacIcon
											icon-name="Magnifer"
											className="text-gray-40"
											size="custom"
											width="88px"
											height="88px"
										/>

										<div className="flex flex-col gap-6">
											<SypacText variant="body-regular-medium">
												<p className="text-2xl text-gray-80">
													<T keyName="clientSelectCategory.noProductsAvailableInYourArea">
														No products available in your area at the moment
													</T>
												</p>
											</SypacText>

											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-40">
													<T keyName="clientSelectCategory.pleaseGetInTouchForFurtherInquiries">
														Please get in touch for further inquiries.
													</T>
												</p>
											</SypacText>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ClientSelectCategory;
