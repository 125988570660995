import { SVGProps } from 'react';

interface CloseProps extends SVGProps<SVGSVGElement> {
	color?: string;
}

const Close = ({ color = '#171717', ...props }: CloseProps) => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 15 15"
			fill="none"
			{...props}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.50099 9.14341L12.5019 14.1552C12.7214 14.3747 13.0191 14.498 13.3295 14.498C13.64 14.498 13.9377 14.3747 14.1572 14.1552C14.3767 13.9358 14.5 13.6381 14.5 13.3277C14.5 13.0173 14.3767 12.7196 14.1572 12.5002L9.14464 7.5L14.1572 2.49983C14.3767 2.28036 14.5 1.98268 14.5 1.6723C14.5 1.36191 14.3767 1.06424 14.1572 0.844765C13.9377 0.625289 13.64 0.501989 13.3295 0.501989C13.0191 0.501989 12.7214 0.625289 12.5019 0.844765L7.50099 5.85659L2.50012 0.844764C2.39175 0.73552 2.26282 0.648811 2.12077 0.589638C1.97872 0.530465 1.82635 0.499999 1.67247 0.499999C1.51858 0.499999 1.36621 0.530465 1.22416 0.589638C1.08211 0.648811 0.953182 0.73552 0.844815 0.844764C0.735556 0.953116 0.648831 1.08203 0.58965 1.22406C0.530469 1.36609 0.500001 1.51843 0.500001 1.6723C0.500001 1.82616 0.530469 1.97851 0.58965 2.12054C0.648831 2.26257 0.735556 2.39148 0.844815 2.49983L5.85735 7.5L0.844815 12.5002C0.735555 12.6085 0.648831 12.7374 0.58965 12.8795C0.530468 13.0215 0.5 13.1738 0.5 13.3277C0.5 13.4816 0.530468 13.6339 0.58965 13.7759C0.648831 13.918 0.735555 14.0469 0.844815 14.1552C0.953182 14.2645 1.08211 14.3512 1.22416 14.4104C1.36621 14.4695 1.51858 14.5 1.67246 14.5C1.82635 14.5 1.97872 14.4695 2.12077 14.4104C2.26282 14.3512 2.39175 14.2645 2.50011 14.1552L7.50099 9.14341Z"
				fill={color}
			/>
		</svg>
	);
};

export default Close;
