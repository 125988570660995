import { useEffect, useState } from 'react';

import { useApiFacade } from './use-api-facade';
import {
	InvoiceList,
	RequestInvoiceListDto,
} from '../interfaces/invoice.interface';
import { BillingService } from '../services/billing.service';

type UseGetInvoiceStats = [InvoiceList | undefined, Error | undefined, boolean];

export const useGetInvoices = ({
	limit,
	offset,
	orderId,
	status,
	assigneeId,
	fromDate,
	toDate,
	sorting,
	target = 'customer',
	refresh = 0,
	search,
}: Partial<RequestInvoiceListDto>): UseGetInvoiceStats => {
	const facade = useApiFacade();
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	let statuses = [status];

	const params: Record<string, any> = {
		limit,
		orderId,
		statuses,
		offset,
		assigneeId,
		fromDate,
		toDate,
		search,
	};
	if (sorting) {
		const sortBy = sorting.split(' ')[0];
		const sortDirection = sorting.split(' ')[1];
		params['sortBy'] = sortBy;
		params['sortDirection'] = sortDirection;
	}

	useEffect(() => {
		setIsLoading(true);
		facade.client
			.get(`/v1/${target}/invoices`, {
				params,
			})
			.then((response) => {
				setData(response.data);
			})
			.catch((err) => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		facade,
		limit,
		orderId,
		status,
		offset,
		assigneeId,
		toDate,
		fromDate,
		sorting,
		target,
		refresh,
		search,
	]);

	return [data, error, isLoading];
};

interface GetInvoiceStatsProps {
	target: string;
	refresh?: number;
	limit: number;
	offset: number;
}
export const useGetInvoiceListAdmin = ({
	target,
	limit,
	offset,
	refresh = 0,
}: GetInvoiceStatsProps): UseGetInvoiceStats => {
	const [data, setData] = useState<any>();
	const [error, setError] = useState<Error>();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		BillingService.getInvoiceListAdmin(target, { limit, offset })
			.then((response) => {
				setData(response.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BillingService.getInvoiceListAdmin, target, limit, offset, refresh]);

	return [data, error, isLoading];
};
