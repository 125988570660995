import { IconInterface } from '../interfaces/icon.interface';

const Wallet = (props: IconInterface) => {
	const { color = '#A2A2A2' } = props;

	return (
		<svg
			width="44"
			height="42"
			viewBox="0 0 44 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.36914 15.7373H17.7902"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M40.5971 17.8418H35.118C31.3616 17.8418 28.3164 20.6695 28.3164 24.1576C28.3164 27.6457 31.3616 30.4734 35.118 30.4734H40.5971C40.7725 30.4734 40.8603 30.4734 40.9343 30.4689C42.0696 30.3998 42.974 29.56 43.0484 28.5058C43.0532 28.437 43.0532 28.3556 43.0532 28.1927V20.1225C43.0532 19.9596 43.0532 19.8781 43.0484 19.8094C42.974 18.7551 42.0696 17.9154 40.9343 17.8463C40.8603 17.8418 40.7725 17.8418 40.5971 17.8418Z"
				stroke={color}
			/>
			<circle cx="34.6307" cy="24.158" r="1.25761" fill={color} />
			<path
				d="M40.8737 17.8417C40.71 13.9001 40.1822 11.4833 38.4808 9.7819C36.0143 7.31543 32.0446 7.31543 24.1052 7.31543H17.7894C9.84993 7.31543 5.8802 7.31543 3.41373 9.7819C0.947266 12.2484 0.947266 16.2181 0.947266 24.1575C0.947266 32.097 0.947266 36.0667 3.41374 38.5332C5.8802 40.9996 9.84993 40.9996 17.7894 40.9996H24.1052C32.0446 40.9996 36.0143 40.9996 38.4808 38.5332C40.1822 36.8318 40.71 34.415 40.8737 30.4733"
				stroke={color}
			/>
			<path
				d="M9.36914 7.31579L17.2333 2.10133C19.448 0.632889 22.4482 0.632889 24.6629 2.10133L32.527 7.31579"
				stroke={color}
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Wallet;
