import React, { useEffect, useState } from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Geo } from '../../../../components/SearchLocation/locations.interface';
import Flag from 'react-world-flags';
import { DaysSelect } from '../../../../components/ProductLocation/Selectors/Days/DaysSelect';
import { VariantInterface } from '../../../../components/ProductLocation/ProductLocation.interface';
import { HoursSelect } from '../../../../components/ProductLocation/Selectors/Hours/HoursSelect';
import { NewStoreCreateProps } from '../productStores.interface';
import {
	CreateStoresParams,
	ProductService,
} from '../../../../services/product.services';
import { toastVariant } from '../../../../components/CompaniesTable/toastVariant/toastVariant';

const NewStoreCreate: React.FC<NewStoreCreateProps> = ({
	onClose,
	onChange,
	locations,
	setStoreLocation,
	storeLocation,
	setStoreName,
	isEditing,
	storeData,
}) => {
	const { t } = useTranslate();
	const [locationResults, setLocationResults] = useState<boolean>(false);

	const daysAndHoursSubmenu = [
		{ title: t('productLocation.monday', 'Monday'), variant: 'monday' },
		{ title: t('productLocation.tuesday', 'Tuesday'), variant: 'tuesday' },
		{
			title: t('productLocation.wednesday', 'Wednesday'),
			variant: 'wednesday',
		},
		{ title: t('productLocation.thursday', 'Thursday'), variant: 'thursday' },
		{ title: t('productLocation.friday', 'Friday'), variant: 'friday' },
		{ title: t('productLocation.saturday', 'Saturday'), variant: 'saturday' },
		{ title: t('productLocation.sunday', 'Sunday'), variant: 'sunday' },
	];
	const daysMenu = [
		{
			title: t('productLocation.businessDays', 'Business days'),
			variant: 'business_days',
		},
		{ title: t('productLocation.fullWeek', 'Full week'), variant: 'full_week' },
		{
			title: t('productLocation.custom', 'Custom'),
			variant: 'custom',
			submenu: daysAndHoursSubmenu,
		},
	];
	const hoursMenu = [
		{ title: t('productLocation.fullTime', 'Full Time'), variant: 'full_time' },
		{
			title: t('productLocation.fromHoursToHours', 'From 9:00 to 18:00'),
			variant: 'working_hours',
		},
		{
			title: t('productLocation.custom', 'Custom'),
			variant: 'custom',
			submenu: daysAndHoursSubmenu,
		},
	];
	const [pickupDays, setPickupDays] = useState<VariantInterface[]>([
		{
			title: t('newStoreCreate.selectWorkingDays', 'Select working days'),
			variant: 'days',
		},
	]);
	const [workHours, setWorkHours] = useState<VariantInterface[]>([
		{
			title: t('newStoreCreate.selectWorkingHours', 'Select working hours'),
			variant: 'hours',
		},
	]);

	const formik = useFormik({
		initialValues: {
			storeName: storeData?.name || '',
			storeLocation: storeData?.address || '',
			days: '',
			hours: '',
		},
		onSubmit: async (values) => {
			const coordinates = storeData?.location?.coordinates;
			const body: CreateStoresParams = {
				address: values.storeLocation,
				lat: storeLocation?.latitude! || coordinates[1],
				long: storeLocation?.longitude! || coordinates[0],
				name: values.storeName,
				countryCode: storeLocation?.store?.countryCode!,
				workingDays: pickupDays?.map((p) => ({ ...p, days: p.submenu })),
				workingHours: workHours?.map((p) => ({ ...p, days: p.submenu })),
			};

			try {
				if (isEditing) {
					await ProductService.editProductStores(storeData.id, {
						...body,
					});
					toastVariant('Store updated successfully', false);
				} else {
					await ProductService.createProductStores({
						...body,
					});
					toastVariant('Store created successfully', false);
				}
				handleModalClose();
			} catch (error) {
				toastVariant(`Error: ${error?.toString()}`, true);
			}
		},
		validateOnMount: true,
	});

	const locationRef = useDetectClickOutside({
		onTriggered: () => setLocationResults(false),
	});

	const onSelectLocation = (location: Geo) => {
		setStoreLocation(location);
		formik.setFieldValue('storeLocation', location.label);
		setLocationResults(false);
	};

	const handleCheckedDays = (data: VariantInterface) => {
		setPickupDays([data]);
		if (data.variant === 'custom') {
			setWorkHours([data]);
		}
	};

	const handleCheckedHours = (data: VariantInterface) => {
		setWorkHours([data]);
		if (data.variant === 'custom') {
			setPickupDays([data]);
		}
	};

	const handleModalClose = () => {
		setStoreLocation(undefined);
		formik.resetForm();
		onClose();
	};

	useEffect(() => {
		if (storeData?.workingDays?.length) {
			setPickupDays(storeData?.workingDays);
		}
		if (storeData?.workingHours?.length) {
			setWorkHours(storeData?.workingHours);
		}
	}, [storeData]);

	return (
		<div className="flex flex-col gap-5 justify-center">
			<div className="flex flex-col gap-6 px-3 xl-2xl:px-5 mb-2 xl-2xl:mb-10">
				<SypacText variant="heading-4">
					<p className="text-gray-90">
						{isEditing ? (
							<T keyName="newStoreCreate.editStore">Edit store</T>
						) : (
							<T keyName="newStoreCreate.addNewStore">Add new store</T>
						)}
					</p>
				</SypacText>

				{/* <SypacText variant="body-regular-medium">
					<p className="text-gray-40">
						<T keyName="fleet.inputTheAddressOrDropAPin">
							Input the address or drop a pin on the map.
						</T>
					</p>
				</SypacText> */}
			</div>

			<form
				onSubmit={formik.handleSubmit}
				className="sm:w-[432px] xl-2xl:w-[544px] flex flex-col border border-solid border-gray-10 rounded-xl mx-3 xl-2xl:mx-5 bg-white box-border"
			>
				<div className="flex flex-col p-3 gap-3">
					<div className="flex bg-alabaster border border-solid border-gray-10 rounded-10 p-3">
						<SypacInput
							error={!!(formik.touched.storeName && formik.errors.storeName)}
							className="w-full"
						>
							<SypacText className="mb-2" variant="overline-normal-large">
								<p>
									<T keyName="newStoreCreate.storeName">Store name</T>{' '}
									<span className="text-red">*</span>
								</p>
							</SypacText>
							<input
								className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
									formik.touched.storeName && formik.errors.storeName
										? 'border-red-orange'
										: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
								}`}
								name="storeName"
								type="text"
								placeholder={t(
									'newStoreCreate.enterStoreName',
									'Enter store name',
								)}
								value={formik?.values.storeName}
								onChange={(event) => {
									formik?.handleChange(event);
									setStoreName(event.target.value);
								}}
							/>
							{formik?.touched.storeName && formik?.errors.storeName ? (
								<span className="input-error">
									{formik.errors.storeName.toString()}
								</span>
							) : null}
						</SypacInput>
					</div>

					<div className="flex flex-col gap-2 bg-alabaster border border-solid border-gray-10 rounded-10 p-3">
						<SypacText variant="body-regular-small">
							<p className="text-xs">
								<T keyName="newStoreCreate.storeLocation">Store location</T>
								<span className="text-red ml-1">*</span>
							</p>
						</SypacText>

						<div ref={locationRef} className="relative">
							<SypacInput
								error={
									!!(
										formik.touched.storeLocation && formik.errors.storeLocation
									)
								}
							>
								<input
									autoComplete="off"
									type="text"
									name="storeLocation"
									className={`py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 placeholder:text-base transition ${
										formik.touched.storeLocation && formik.errors.storeLocation
											? 'border-red-orange'
											: 'border-gray-10 hover:border-gray-30 focus:border-cornflower-blue'
									}`}
									placeholder={t(
										'newStoreCreate.enterStoreLocation',
										'Enter store location',
									)}
									onBlur={formik.handleBlur}
									value={formik.values.storeLocation}
									onFocus={() => setLocationResults(true)}
									onChange={(event) => {
										formik.handleChange(event);
										onChange(event.target.value);
									}}
								/>
								{formik.touched.storeLocation && formik.errors.storeLocation ? (
									<span className="input-error">
										{formik.errors.storeLocation.toString()}
									</span>
								) : null}
							</SypacInput>

							<div
								className={`absolute top-[52px] w-full flex-col shadow-dropdown rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
									locationResults ? 'flex' : 'hidden'
								}`}
							>
								{locations?.length ? (
									<div className="flex flex-col w-full p-3 overflow-y-scroll">
										{locations.map((location: Geo) => (
											<div
												key={location.locationId}
												className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px]"
												onClick={() => onSelectLocation(location)}
											>
												<div className="mx-5">
													<Flag
														className="rounded-[3px]"
														code={location.countryCode.toLocaleUpperCase()}
														width={22}
														height={16}
													/>
												</div>
												<SypacText
													variant="body-normal-medium"
													className="my-[15px]"
												>
													<p>
														{location.address?.city}, {location.address?.street}{' '}
														str.,
														{location.address?.houseNumber
															? ` ${location.address?.houseNumber},`
															: ''}{' '}
														Zip {location.address?.postalCode}
													</p>
												</SypacText>
											</div>
										))}
									</div>
								) : null}
							</div>
						</div>
					</div>

					<div className="flex flex-col bg-alabaster border border-solid border-gray-10 rounded-10 p-3">
						<SypacText className="mb-2" variant="overline-normal-large">
							<p>
								<T keyName="newStoreCreate.workingDays">Working days</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<DaysSelect
							daysMenu={daysMenu}
							pickupDays={pickupDays}
							workHours={workHours}
							handleCheckedDays={handleCheckedDays}
							isError={false}
						/>
						{formik?.touched.days && formik?.errors.days ? (
							<span className="input-error">{formik.errors.days}</span>
						) : null}
					</div>

					<div className="flex flex-col bg-alabaster border border-solid border-gray-10 rounded-10 p-3">
						<SypacText className="mb-2" variant="overline-normal-large">
							<p>
								<T keyName="newStoreCreate.workingHours">Working hours</T>{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<HoursSelect
							hoursMenu={hoursMenu}
							pickupDays={pickupDays}
							workHours={workHours}
							handleCheckedHours={handleCheckedHours}
							isError={false}
						/>
						{formik?.touched.hours && formik?.errors.hours ? (
							<span className="input-error">{formik.errors.hours}</span>
						) : null}
					</div>
				</div>

				<div className="mt-auto flex w-full py-2.5 xl-2xl:py-4 gap-4 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full pl-2.5 xl-2xl:pl-4"
					>
						<button
							type="reset"
							className="w-full py-2.5 rounded-lg"
							onClick={() => handleModalClose()}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="fleet.close">Close</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="primary"
						size="small"
						className="w-full pr-2.5 xl-2xl:pr-4"
					>
						<button type="submit" className="w-full py-2.75 rounded-lg">
							<SypacText variant="body-regular-medium">
								<p className="ml-3 text-white">
									{isEditing ? (
										<T keyName="newStoreCreate.editStore">Edit store</T>
									) : (
										<T keyName="productStores.createStore">Create store</T>
									)}
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>

			<SypacText
				variant="body-regular-medium"
				className="px-3 xl-2xl:px-5 mt-2 xl-2xl:mt-6"
			>
				<p className="text-sm text-gray-40">
					<T keyName="fleet.havingIssues">Having issues?</T>{' '}
					<a
						className="no-underline text-cornflower-blue"
						href="mailto:support@sypac.app"
					>
						<T keyName="fleet.contactUs">Contact us.</T>
					</a>
				</p>
			</SypacText>
		</div>
	);
};

export default NewStoreCreate;
