import React, { useCallback, useEffect, useState } from 'react';
import {
	SypacButton,
	SypacCheckbox,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	CompanyResponse,
	CompanyService,
} from '../../../../services/company.services';
import { toastVariant } from '../../../../components/CompaniesTable/toastVariant/toastVariant';
import { ChevronRightIcon } from '../../../../assets/ChevronRightIcon';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Geo } from '../../../../components/SearchLocation/locations.interface';
import Flag from 'react-world-flags';
import {
	FleetBaseDto,
	TruckService,
	UpdateFleetBase,
} from '../../../../services/truck.services';
import { MapMarker } from '../../../../components/Map/Map.interface';

export interface FleetBaseLocationProps {
	onClose: (value?: boolean) => void;
	onChange: (search: string) => void;
	locations: Geo[];
	setBaseLocation: (location: Geo | undefined) => void;
	setBaseRadius: (radius: number) => void;
	baseRadius: number | undefined;
	setBaseRegistryAddress: (value: string) => void;
	baseLocation: Partial<MapMarker>;
	myFleetBase: FleetBaseDto | undefined;
}

const radiusOptions = [
	{ label: '25 km', value: '25' },
	{ label: '50 km', value: '50' },
	{ label: '75 km', value: '75' },
	{ label: '100 km', value: '100' },
	{ label: '200 km', value: '200' },
];

const FleetBaseLocation = (props: FleetBaseLocationProps) => {
	const { t } = useTranslate();
	const {
		onClose,
		onChange,
		locations,
		setBaseLocation,
		setBaseRadius,
		baseRadius,
		setBaseRegistryAddress,
		baseLocation,
		myFleetBase,
	} = props;
	const [myCompany, setMyCompany] = useState<CompanyResponse>();
	const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [locationResults, setLocationResults] = useState<boolean>(false);

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setMyCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	useEffect(() => {
		if (baseRadius !== undefined) {
			formik.setFieldValue('workingRadius', `${baseRadius}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseRadius]);

	const handleModalClose = () => {
		setBaseLocation(undefined);
		setBaseRegistryAddress('');
		formik.resetForm();
		onClose();
	};

	const formik = useFormik({
		initialValues: {
			baseLocation: myFleetBase?.address,
			workingRadius: String(myFleetBase?.radiusDistance),
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			const baseData: UpdateFleetBase = {
				radiusDistance: +values.workingRadius!,
				address: values.baseLocation!,
				lat: baseLocation?.latitude!,
				long: baseLocation?.longitude!,
			};

			await TruckService.updateFleetBase({
				...baseData,
			});
			handleModalClose();
		},
		validationSchema: Yup.object({
			baseLocation: Yup.string().required(
				t('fleet.fleetBaseLocationRequired', 'Fleet base location is required'),
			),
			workingRadius: Yup.string().required(
				t('fleet.workingRadiusRequired', 'Working radius is required'),
			),
		}),
	});

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const isChecked = event.target.checked;
		if (isChecked && myCompany?.registryAddress) {
			formik.setFieldValue('baseLocation', myCompany.registryAddress);
			setBaseRegistryAddress(myCompany.registryAddress);
			setBaseLocation(undefined);
		} else {
			formik.setFieldValue('baseLocation', '');
			setBaseRegistryAddress('');
		}
		setIsCheckboxChecked(isChecked);
	};

	const handleOutsideClick = () => {
		if (isDropdownOpen && !formik.values.workingRadius) {
			formik.setFieldTouched('workingRadius', true);
			formik.validateField('workingRadius');
		}
		setIsDropdownOpen(false);
	};

	const radiusRef = useDetectClickOutside({ onTriggered: handleOutsideClick });
	const locationRef = useDetectClickOutside({
		onTriggered: () => setLocationResults(false),
	});

	const onSelectLocation = (location: Geo) => {
		setBaseLocation(location);
		formik.setFieldValue('baseLocation', location.label);
		setLocationResults(false);
	};

	return (
		<div className="flex flex-col gap-5 justify-center">
			<div className="flex flex-col gap-6 px-3 xl-2xl:px-5 mb-2 xl-2xl:mb-10">
				<SypacText variant="heading-4">
					<p className="text-gray-90">
						<T keyName="fleet.addYourFleetBaseLocation">
							Add your fleet base location
						</T>
					</p>
				</SypacText>

				<SypacText variant="body-regular-medium">
					<p className="text-gray-40">
						<T keyName="fleet.inputTheAddressOrDropAPin">
							Input the address or drop a pin on the map.
						</T>
					</p>
				</SypacText>
			</div>

			<form
				onSubmit={formik.handleSubmit}
				className="sm:w-[432px] xl-2xl:w-[544px] flex flex-col border border-solid border-gray-10 rounded-xl mx-3 xl-2xl:mx-5 bg-white box-border"
			>
				<div className="flex flex-col gap-2 bg-alabaster border border-solid border-gray-10 rounded-10 m-2.5 xl-2xl:m-4 p-3">
					<SypacText variant="body-regular-small">
						<p className="text-xs">
							<T keyName="fleet.fleetBaseLocation">Fleet base location</T>
							<span className="text-red">*</span>
						</p>
					</SypacText>

					<div ref={locationRef} className="relative">
						<SypacInput
							error={
								!!(formik.touched.baseLocation && formik.errors.baseLocation)
							}
						>
							<input
								autoComplete="off"
								type="text"
								name="baseLocation"
								className={`py-3 pl-3 rounded-lg placeholder:text-gray-40 placeholder:text-base ${
									isCheckboxChecked ? 'bg-gray-2 border-gray-10' : ''
								}`}
								placeholder={t(
									'fleet.enterFleetBaseAddress',
									'Enter fleet base address',
								)}
								onBlur={formik.handleBlur}
								value={formik.values.baseLocation}
								disabled={isCheckboxChecked}
								onFocus={() => setLocationResults(true)}
								onChange={(event) => {
									formik.handleChange(event);
									onChange(event.target.value);
								}}
							/>
							{formik.touched.baseLocation && formik.errors.baseLocation ? (
								<span className="input-error">
									{formik.errors.baseLocation}
								</span>
							) : null}
						</SypacInput>

						<div
							className={`absolute top-[52px] w-full flex-col shadow-dropdown rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
								locationResults ? 'flex' : 'hidden'
							}`}
						>
							{locations?.length ? (
								<div className="flex flex-col w-full p-3 overflow-y-scroll">
									{locations.map((location: Geo) => (
										<div
											key={location.locationId}
											className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px]"
											onClick={() => onSelectLocation(location)}
										>
											<div className="mx-5">
												<Flag
													className="rounded-[3px]"
													code={location.countryCode.toLocaleUpperCase()}
													width={22}
													height={16}
												/>
											</div>
											<SypacText
												variant="body-normal-medium"
												className="my-[15px]"
											>
												<p>
													{location.address?.city}, {location.address?.street}{' '}
													str.,
													{location.address?.houseNumber
														? ` ${location.address?.houseNumber},`
														: ''}{' '}
													Zip {location.address?.postalCode}
												</p>
											</SypacText>
										</div>
									))}
								</div>
							) : null}
						</div>
					</div>
				</div>

				<div className="flex flex-col gap-4 px-2.5 pb-2.5 xl-2xl:px-4 xl-2xl:pb-3">
					<div className="flex gap-3 items-center">
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40 whitespace-nowrap">
								<T keyName="companyInformation.companyAddress">
									Company address:
								</T>
							</p>
						</SypacText>

						<div className="flex items-center gap-2">
							<span className="ml-[2px] mt-[2px]">
								<Flag
									className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
									code={myCompany?.countryCode}
									width={24}
									height={16}
								/>
							</span>
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-80 sm:w-[238px] xl-2xl:w-[350px] truncate">
									{myCompany?.registryAddress}
								</p>
							</SypacText>
						</div>
					</div>

					<div className="flex gap-2 items-center ml-auto">
						<SypacCheckbox size="md">
							<input
								type="checkbox"
								name="sameAddress"
								checked={
									formik.values.baseLocation === myCompany?.registryAddress
								}
								onChange={handleCheckboxChange}
								className="cursor-pointer"
							/>
						</SypacCheckbox>
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-80 mt-[3px]">
								<T keyName="fleet.theCompanyAddressIsTheAddress">
									The company address is the address.
								</T>
							</p>
						</SypacText>
					</div>
				</div>
				<div className="border-0 border-solid border-t-[1px] border-gray-10" />

				<div className="flex flex-col gap-2 bg-alabaster border border-solid border-gray-10 rounded-10 m-2.5 xl-2xl:m-4 p-3">
					<SypacText variant="body-regular-small">
						<p className="text-xs">
							<T keyName="fleet.workingRadius">Working radius</T>
							<span className="text-red">*</span>
						</p>
					</SypacText>

					<div ref={radiusRef} className="relative">
						<div
							className={`flex justify-between items-center h-[46px] box-border border border-solid border-gray-10 bg-white rounded-lg py-2.5 px-3 cursor-pointer transition ${
								isDropdownOpen
									? 'border-cornflower-blue'
									: 'hover:border-gray-30'
							} ${
								formik.touched.workingRadius && formik.errors.workingRadius
									? 'border-red-orange'
									: 'border-gray-10'
							}`}
							onClick={() => setIsDropdownOpen(true)}
						>
							<SypacText variant="body-normal-small">
								<p
									className={`text-base ${
										formik.values.workingRadius
											? 'text-gray-80'
											: 'text-gray-40'
									}`}
								>
									{formik.values.workingRadius ? (
										radiusOptions.find(
											(option) => option.value === formik.values.workingRadius,
										)?.label
									) : (
										<T keyName="fleet.selectWorkingRadius">
											Select working radius
										</T>
									)}
								</p>
							</SypacText>

							<span
								className={`flex my-auto transition-all mr-[2px] ${
									isDropdownOpen ? 'transform rotate-90' : ''
								}`}
							>
								<ChevronRightIcon />
							</span>
						</div>

						{isDropdownOpen && (
							<div className="absolute -top-[292px] xl-2xl:top-[52px] w-full h-fit xl-2xl:h-[162px] 3xl:h-fit overflow-x-hidden overflow-y-scroll 3xl:overflow-auto flex flex-col p-3 rounded-lg shadow-dropdown bg-white z-30 box-border">
								{radiusOptions.map((radius) => (
									<div
										key={radius.value}
										className="no-underline flex hover:bg-gray-10-opacity-50 bg-white border-none pt-4 pb-[17px] px-5 rounded-md cursor-pointer"
										onClick={() => {
											formik.setFieldValue('workingRadius', radius.value);
											setBaseRadius(+radius.value);
											setIsDropdownOpen(false);
										}}
									>
										<SypacText
											variant="body-regular-medium"
											className="mr-auto"
										>
											<p className="text-gray-80 leading-[19px]">
												{radius.label}
											</p>
										</SypacText>
									</div>
								))}
							</div>
						)}

						{formik.touched.workingRadius && formik.errors.workingRadius ? (
							<span className="input-error">{formik.errors.workingRadius}</span>
						) : null}
					</div>
				</div>

				<div className="mt-auto flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full pl-2.5 xl-2xl:pl-3"
					>
						<button
							type="reset"
							className="w-full py-2.5 rounded-lg"
							onClick={() => handleModalClose()}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="fleet.close">Close</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="primary"
						size="small"
						className="w-full pr-2.5 xl-2xl:pr-3"
					>
						<button
							className="w-full py-2.75 rounded-lg"
							type="submit"
							disabled={
								!formik.values.baseLocation || !formik.values.workingRadius
							}
						>
							<SypacText variant="body-regular-medium">
								<p className="ml-3 text-white">
									{myFleetBase ? (
										<T keyName="fleet.updateFleetBase">Update fleet base</T>
									) : (
										<T keyName="fleet.createFleetBase">Create fleet base</T>
									)}
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>

			<SypacText
				variant="body-regular-medium"
				className="px-3 xl-2xl:px-5 mt-2 xl-2xl:mt-6"
			>
				<p className="text-sm text-gray-40">
					<T keyName="fleet.havingIssues">Having issues?</T>{' '}
					<a
						className="no-underline text-cornflower-blue"
						href="mailto:support@sypac.app"
					>
						<T keyName="fleet.contactUs">Contact us.</T>
					</a>
				</p>
			</SypacText>
		</div>
	);
};

export default FleetBaseLocation;
