import React, { useContext, useEffect, useState } from 'react';
import Table from 'rc-table';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import 'react-toastify/dist/ReactToastify.css';
import { NumericFormat } from 'react-number-format';
import {
	SypacAvatar,
	SypacIcon,
	SypacLink,
	SypacText,
} from '@sypac/component-library-react';
import './payments-table.css';
import { OrderContext } from '../../context/OrderContext/order.context';
import { PaymentsTableInterface } from './interfaces/PaymentsTable.interface';
import { InvoiceInterface } from './interfaces/Payment.interface';
import { PaymentTypeAction } from './interfaces/PaymentStatus.interface';
import { RenderExpandIconProps } from 'rc-table/lib/interface';
import { TableStatusEnum } from '../TableStatus/TableStatus.enum';
import { LineHorizontal } from '../../assets/LineHorizontal';
import TableStatus from '../TableStatus/TableStatus';
import { T, useTranslate } from '@tolgee/react';
import { Checkmark } from '../../assets/Checkmark';
import DocumentsViewer from '../DocumentsViewer/DocumentsViewer';
import CompanyDetails from '../../containers/Admin/ComanyDetails';
import { AuthContext } from '../../context/context';
import { Tooltip } from 'react-tooltip';
import { PlusIcon } from '../../assets/PlusIcon';
import { MinusIcon } from '../../assets/MinusIcon';
import { calculateRemainingDays, formatDate } from '../../utils/time.util';
import Download from '../../assets/Download';
import EyeIcon from '../../assets/EyeIcon';
import ClockSmall from '../../assets/ClockSmall';
import AlertSmall from '../../assets/AlertSmall';
import CheckmarkSmall from '../../assets/CheckmarkSmall';
import NoResults from './components/NoResults';

export const PaymentsTable = ({
	rows,
	rowClick,
	search,
	target = 'customer',
	additionalClick,
	currentTab = 'all',
}: PaymentsTableInterface) => {
	dayjs.locale(localStorage.getItem('lang') || 'en');
	const { t } = useTranslate();
	const { user } = useContext(AuthContext);
	const { selectedOrderId } = useContext(OrderContext);
	const [keyRows, setRows] = useState<InvoiceInterface[]>([]);
	const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
	const [openView, setShowView] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const [selectedCompany, setSelectedCompany] = useState<number | undefined>(
		undefined,
	);
	const [showDetails, setShowDetails] = useState<boolean>(false);

	useEffect(() => {
		const newRows = rows
			? rows.map((row: InvoiceInterface) => ({
					...row,
					key: row?.id,
			  }))
			: [];
		setRows(newRows);
	}, [rows]);

	const getTrProps = (
		record: InvoiceInterface,
		_index: number,
		_indent: number,
	): string => {
		const baseClass =
			record.id === selectedOrderId
				? `payment-table-row-selected payment-table-row-${record.id}`
				: `payment-table-row-${record.id}`;
		const isOverdue =
			record.status === TableStatusEnum.PENDING &&
			dayjs(record.dueDate).toISOString() < dayjs().toISOString();
		const status = isOverdue ? TableStatusEnum.OVERDUE : record.status;
		const bgClass = ['customer'].includes(target)
			? ` payment-table-row-${status}`
			: '';

		return `${baseClass}${bgClass}`;
	};

	const companyClick = (id: number) => {
		return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			e.stopPropagation();
			e.preventDefault();
			setSelectedCompany(id);
			setShowDetails(true);
		};
	};

	const actionClick = (type: string, payment: InvoiceInterface | number) => {
		switch (type) {
			case PaymentTypeAction.download:
				return downloadInvoice(payment as InvoiceInterface);
			case PaymentTypeAction.view_invoice:
				return viewInvoice((payment as InvoiceInterface)?.invoiceUrl);
			case PaymentTypeAction.view_order:
				return (
					additionalClick &&
					additionalClick(payment, PaymentTypeAction.view_order)
				);
			case PaymentTypeAction.confirm_payment:
				return additionalClick && additionalClick(payment!);
			case PaymentTypeAction.extend_invoice:
				return onExpandForAdmin(payment as InvoiceInterface);
			case PaymentTypeAction.paid_payment:
				return additionalClick && additionalClick(payment!);
		}
	};

	const downloadInvoice = async (invoice: InvoiceInterface) => {
		try {
			if (!invoice.invoiceUrl) {
				return;
			}

			const companyName = user?.company.name;
			const invoiceSort =
				companyName
					?.split(' ')
					?.map((r) => r[0])
					?.join('')
					?.toLocaleUpperCase() || 'FV';
			const response = await fetch(invoice.invoiceUrl);
			const data = (await response.blob()) as any;
			const downloadUrl = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = downloadUrl;
			const fileName = invoice.invoiceNr
				? `${invoice.invoiceNr}.pdf`
				: `${invoiceSort}${formatDate(invoice.createdAt, 'DD-MM-YYYY')}${
						invoice.id
				  }.pdf`;
			link.setAttribute('download', fileName);

			document.body.appendChild(link);

			link.click();

			link?.parentNode?.removeChild(link);
		} catch (e) {}
	};

	const viewInvoice = (url?: string) => {
		setUrl(url);
		setShowView(!!url);
	};

	const onExpandForAdmin = (record: InvoiceInterface) => {
		const exist = expandedRowKeys.some((r) => r === record.id);
		if (exist) {
			setExpandedRowKeys([]);
		} else {
			setExpandedRowKeys([record.id]);
		}
	};

	const onExpand = (expanded: boolean, record: InvoiceInterface) => {
		if (expanded) {
			setExpandedRowKeys([record.id]);
		} else {
			setExpandedRowKeys([]);
		}
	};

	const expandedRowRender = (record: InvoiceInterface) => {
		return (
			<div className="relative w-[calc(100%-2px)] table-payment table-payment-admin z-10 bg-transparent border border-t-0 border-solid border-primary-violet rounded-b-10 shadow-drop-shadow">
				<table>
					<tbody>
						<tr>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.createdDate">Created date</T>
									</p>
								</SypacText>
							</td>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.amountPaid">Amount paid</T>
									</p>
								</SypacText>
							</td>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.amountOpen">Amount open</T>
									</p>
								</SypacText>
							</td>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-left px-5">
										<T keyName="paymentsTable.paymentStatus">Payment status</T>
									</p>
								</SypacText>
							</td>
							{['admin_customer', 'admin_producer'].includes(target) && (
								<td>
									<SypacText variant="body-regular-medium">
										<p className="text-sm text-gray-40 text-left px-5">
											<T
												keyName={`paymentsTable.${
													target === 'admin_customer'
														? 'approvedBy'
														: 'solvedBy'
												}`}
											>
												{target === 'admin_customer'
													? 'Approved by'
													: 'Solved by'}
											</T>
										</p>
									</SypacText>
								</td>
							)}
						</tr>

						{record.payments.map((r, index) => {
							const paymentDateFormatted = formatDate(r.createdAt);
							const isOverdue = dayjs(record.dueDate).isBefore(
								paymentDateFormatted,
							);
							const days = dayjs(record.dueDate).diff(r.paymentDate, 'day');
							const firstPartial =
								index === record.payments.length - 1 &&
								record.payments.length > 1;
							const secondPartial =
								record.status === TableStatusEnum.PENDING ||
								record.payments.length > 1;

							const secondStatus =
								index === 0 && record.status === TableStatusEnum.PAID
									? TableStatusEnum.PAID
									: isOverdue
									? TableStatusEnum.OVERDUE
									: TableStatusEnum.PENDING;

							const firsStatus = isOverdue
								? TableStatusEnum.OVERDUE
								: TableStatusEnum.PENDING;

							const paymentAmount =
								secondStatus === TableStatusEnum.PAID &&
								r.paymentAmount !== record.totalAmount
									? record.totalAmount
									: r.paymentAmount;

							const previousPayments = record.payments
								.slice(0, index)
								.reduce((acc, payment) => acc + payment.paymentAmount!, 0);
							const amountDue =
								record.totalAmount - previousPayments - r.paymentAmount!;
							const user = r?.confirmedUser;

							const payDate = r.paymentDate ? paymentDateFormatted : '';
							const payTime = r.paymentDate
								? dayjs(r.createdAt).format('HH:mm')
								: '';

							return (
								<tr>
									<td>
										<SypacText
											variant="body-regular-medium"
											className="px-5 py-3"
										>
											<p className="text-gray-80 text-center">
												{paymentDateFormatted}
											</p>
										</SypacText>
									</td>
									<td>
										<SypacText
											variant="body-regular-medium"
											className="px-5 py-3"
										>
											<p className="text-gray-80 text-center font-bold">
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator="."
													decimalSeparator=","
													value={parseFloat(paymentAmount?.toFixed(2) || '0')}
													suffix={record.countryCode === 'MD' ? ' MDL' : ' PLN'}
												/>
											</p>
										</SypacText>
									</td>
									<td>
										<SypacText
											variant="body-regular-medium"
											className="px-5 py-3"
										>
											<p className="text-gray-80 text-center font-bold">
												{amountDue > 0 ? (
													<NumericFormat
														type="text"
														displayType="text"
														thousandSeparator="."
														decimalSeparator=","
														value={parseFloat(amountDue?.toFixed(2) || '0')}
														suffix={
															record.countryCode === 'MD' ? ' MDL' : ' PLN'
														}
													/>
												) : (
													'—'
												)}
											</p>
										</SypacText>
									</td>
									<td>
										<div className="grid grid-cols-4 px-5 py-3">
											<div
												className={`flex flex-row justify-start ${
													['admin_customer', 'admin_producer'].includes(target)
														? 'col-span-3'
														: 'col-span-4'
												}`}
											>
												<div className={secondPartial ? 'w-[255px]' : ''}>
													<TableStatus
														days={days}
														state={firsStatus}
														isPartial={firstPartial}
														insideOut={true}
													/>
												</div>
												<div className="flex items-center justify-start mx-10">
													<LineHorizontal />
												</div>
												<TableStatus
													days={days}
													state={secondStatus}
													isPartial={secondPartial}
													insideOut={true}
												/>
											</div>
										</div>
									</td>
									{['admin_customer', 'admin_producer'].includes(target) && (
										<td>
											<div className="flex gap-4 items-center h-full px-4">
												{user && user.name && (
													<div className="flex items-center gap-4">
														<SypacAvatar
															size="sm"
															initials={user.email || user.name}
														>
															{user.avatarUrl && (
																<img
																	src={user.avatarUrl}
																	alt={user.email || user.name}
																/>
															)}
														</SypacAvatar>
														<SypacText variant="body-regular-medium">
															<p className="text-gray-80">{user.name}</p>
														</SypacText>
													</div>
												)}
												<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">{payDate}</p>
												</SypacText>
												<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80">{payTime}</p>
												</SypacText>
											</div>
										</td>
									)}
								</tr>
							);
						})}
					</tbody>
				</table>

				<div className="absolute top-0 w-full h-[56px] bg-transparent -mt-[57px] -ml-[1px] border border-b-0 border-solid border-primary-violet rounded-t-10" />
			</div>
		);
	};

	const expandedRowRenderHistory = (record: InvoiceInterface) => {
		return (
			<div className="relative w-[calc(100%-2px)] table-payment table-payment-admin z-10 bg-transparent border border-t-0 border-solid border-primary-violet rounded-b-10 shadow-drop-shadow">
				<table>
					<tbody>
						<tr>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.paymentStatus">Payment status</T>
									</p>
								</SypacText>
							</td>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.date">Date</T>
									</p>
								</SypacText>
							</td>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.amount">Amount</T>
									</p>
								</SypacText>
							</td>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.assignee">Assignee</T>
									</p>
								</SypacText>
							</td>
							<td>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-gray-40 text-center px-2">
										<T keyName="paymentsTable.user">User</T>
									</p>
								</SypacText>
							</td>
						</tr>

						{record.invoiceLines.map((r) => {
							const isOverdue =
								record.status === TableStatusEnum.PENDING &&
								dayjs(record.dueDate).toISOString() < dayjs().toISOString();
							const status = isOverdue
								? TableStatusEnum.OVERDUE
								: record.status;
							const user = r?.assigneeUser;
							const orderId = r?.orderId;

							return (
								<tr>
									<td>
										<div className="px-5">
											<TableStatus
												state={status}
												isPartial={false}
												insideOut={true}
											/>
										</div>
									</td>
									<td>
										<SypacText
											variant="body-regular-medium"
											className="px-5 py-3"
										>
											<p className="text-gray-80">{formatDate(r.createdAt)}</p>
										</SypacText>
									</td>
									<td>
										<SypacText
											variant="body-regular-medium"
											className="px-5 py-3"
										>
											<p className="text-gray-80">
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator="."
													decimalSeparator=","
													value={parseFloat(
														record.totalAmount?.toFixed(2) || '0',
													)}
													suffix={record.countryCode === 'MD' ? ' MDL' : ' PLN'}
												/>
											</p>
										</SypacText>
									</td>
									<td>
										<SypacText
											variant="body-regular-medium"
											className="px-5 py-3"
										>
											<p className="text-gray-80">
												{record?.companyType === 'transporter' ? (
													<T keyName="invoiceLinesTable.transportService">
														Transport service
													</T>
												) : (
													record?.invoiceLines?.at(0)?.description
												)}
											</p>
										</SypacText>
									</td>
									<td>
										<div className="flex gap-2 px-5 justify-center items-center">
											{user && user.name ? (
												<div className="flex gap-2 items-center h-full">
													<SypacAvatar
														size="sm"
														initials={user.email || user?.name}
													>
														{user?.avatarUrl ? (
															<img
																src={user?.avatarUrl}
																alt={user.email || user?.name}
															/>
														) : null}
													</SypacAvatar>
													<SypacText variant="body-regular-medium">
														<p className="w-[80px] text-gray-80 truncate">
															{user?.name}
														</p>
													</SypacText>
												</div>
											) : null}
											{orderId ? (
												<button
													type="button"
													className={`flex items-center justify-center w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
														expandedRowKeys[0] === record.id ? 'z-[100]' : ''
													}`}
													onClick={() =>
														actionClick(PaymentTypeAction.view_order, orderId)
													}
												>
													<EyeIcon />
												</button>
											) : null}
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>

				<div className="absolute top-0 w-full h-[56px] bg-transparent -mt-[57px] -ml-[1px] border border-b-0 border-solid border-primary-violet rounded-t-10" />
			</div>
		);
	};

	const expandIcon = (props: RenderExpandIconProps<InvoiceInterface>) => {
		const hasLines =
			target === 'withdraw_history'
				? props.record.invoiceLines?.length
				: props.record.payments?.length;
		if (!hasLines) return null;
		const Icon = props.expanded ? MinusIcon : PlusIcon;

		return (
			<div className="w-full h-full flex justify-center items-center">
				<button
					type="button"
					onClick={() => onExpand(!props.expanded, props.record)}
					className={`flex items-center justify-center w-[32px] h-[32px] rounded-lg outline-0 cursor-pointer p-0 transition ${
						props.expanded
							? 'bg-primary-violet border-0 hover:bg-primary-violet/80 z-[100]'
							: 'bg-transparent border-[1px] border-solid border-gray-10 hover:border-gray-20'
					}`}
				>
					<Icon color={props.expanded ? '#FFFFFF' : '#454545'} />
				</button>
			</div>
		);
	};

	const getColumns = () => {
		switch (target) {
			case 'withdraw_history':
				return columnsWithdrawHistory(rowClick, actionClick, search);
			case 'admin_customer':
				return columnsClientAdmin(rowClick, actionClick, search);
			case 'admin_producer':
				return columnsProducerAdmin(rowClick, actionClick, search);
			default:
				return columns(rowClick, actionClick, search);
		}
	};

	const columnsWithdrawHistory = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface) => void,
		search?: string,
	) => [
		{
			title: t('paymentsWithdrawHistory.state', 'State'),
			dataIndex: '',
			className: 'text-center',
			key: 'stateIcon',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === TableStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const status = isOverdue ? TableStatusEnum.OVERDUE : value.status;

				return (
					<div
						className="flex w-full justify-center py-[19px]"
						onClick={() => rowClick(value)}
					>
						{status === 'pending' ? (
							<ClockSmall color="#FFB352" />
						) : status === 'overdue' ? (
							<AlertSmall color="#F44A77" />
						) : status === 'paid' ? (
							<CheckmarkSmall color="#20AC93" />
						) : null}
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.createdDate', 'Created'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = formatDate(value.createdAt);
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsWithdrawHistory.amount', 'Amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'totalAmount',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center h-full px-6"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.totalAmount?.toFixed(2) || '0')}
									suffix={value?.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsWithdrawHistory.orders', 'Orders'),
			dataIndex: '',
			className: 'text-left',
			key: 'description',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex items-center h-full px-6"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value?.invoiceLines?.length}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: ' ',
			dataIndex: '',
			className: 'text-left',
			key: 'action',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;
				return (
					<div className="flex flex-row justify-start items-center h-full px-4">
						<div className="flex flex-row justify-start md:gap-2 2xl:gap-5">
							<button
								type="button"
								data-tooltip-id="invoice-table"
								data-tooltip-content={t(
									'paymentsWithdrawHistory.downloadInvoice',
									'Download Invoice',
								)}
								className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
									expandedRowKeys[0] === value.id ? 'z-[100]' : ''
								}`}
								onClick={() => actionClick(PaymentTypeAction.download, value)}
							>
								<Download />
							</button>

							{orderId ? (
								<button
									type="button"
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsWithdrawHistory.viewInvoice',
										'View Invoice',
									)}
									className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
										expandedRowKeys[0] === value.id ? 'z-[100]' : ''
									}`}
									onClick={() =>
										actionClick(PaymentTypeAction.view_invoice, value)
									}
								>
									<EyeIcon />
								</button>
							) : null}
						</div>
					</div>
				);
			},
		},
	];

	const columnsClientAdmin = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface) => void,
		search?: string,
	) => [
		{
			title: t('paymentsTableClientAdmin.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === TableStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value.dueDate, 'day')
					: dayjs(value.dueDate).diff(dayjs(), 'day');
				const status = isOverdue ? TableStatusEnum.OVERDUE : value.status;
				let isPartial =
					value.status === TableStatusEnum.PENDING && !!value.payments?.length;
				isPartial =
					value.status === TableStatusEnum.PAID
						? value?.payments?.length > 1
						: isPartial;

				return (
					<div
						className="flex items-center justify-start h-full w-fit py-4 px-5"
						onClick={() => rowClick(value)}
					>
						<TableStatus
							days={days}
							state={status}
							isPartial={isPartial}
							insideOut={true}
						/>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex flex-row justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.id}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.created', 'Created'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = formatDate(value.createdAt);
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.dueDate', 'Due date'),
			dataIndex: '',
			className: 'text-left',
			key: 'dueDate',
			render(value: InvoiceInterface) {
				const date = formatDate(value.dueDate);
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.user', 'User'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceInterface) {
				const user = value?.invoiceLines?.at(0)?.assigneeUser;
				const company = value?.company;

				return (
					<div
						className="flex gap-4 items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						{user && user.name ? (
							<div>
								<SypacAvatar size="sm" initials={user.email || user?.name}>
									{user?.avatarUrl ? (
										<img src={user?.avatarUrl} alt={user.email || user?.name} />
									) : null}
								</SypacAvatar>
							</div>
						) : null}

						{company && company?.name ? (
							<SypacLink>
								<a href="/" onClick={companyClick(company.id)}>
									{company?.name}
								</a>
							</SypacLink>
						) : null}
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.invoiceAmount', 'Invoice amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountOpen',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.balanceDue?.toFixed(2) || '0')}
									suffix={value?.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.amountPaid', 'Amount paid'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountPaid',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								{value.totalAmount - value.balanceDue === 0 ? (
									'—'
								) : (
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(
											(value.totalAmount - value.balanceDue).toFixed(2),
										)}
										suffix={value?.countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								)}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.type', 'Type'),
			dataIndex: '',
			className: 'text-left',
			key: 'transaction',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{value.invoiceType.charAt(0).toUpperCase() +
									value.invoiceType.slice(1)}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.order', 'Order'),
			dataIndex: '',
			className: 'text-left',
			key: 'order',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;

				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{orderId}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.approvedBy', 'Approved by'),
			dataIndex: '',
			className: 'text-left',
			key: 'approvedBy',
			render(value: InvoiceInterface) {
				const payment = value?.payments?.length
					? value?.payments?.at(value?.payments?.length - 1)
					: null;
				const payDate = payment?.createdAt
					? formatDate(payment?.createdAt)
					: '';
				const payTime = payment?.createdAt
					? dayjs(payment?.createdAt).format('HH:mm')
					: '';

				return (
					<div
						className="flex gap-4 items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						{payment ? (
							<>
								<div className="flex items-center gap-4">
									{payment?.confirmedUser && payment?.confirmedUser?.name && (
										<div>
											<SypacAvatar
												size="sm"
												initials={
													payment?.confirmedUser?.email ||
													payment?.confirmedUser?.name
												}
											>
												{payment?.confirmedUser?.avatarUrl ? (
													<img
														src={user?.avatarUrl}
														alt={
															payment?.confirmedUser?.email ||
															payment?.confirmedUser?.name
														}
													/>
												) : null}
											</SypacAvatar>
										</div>
									)}

									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{payment?.confirmedUser?.name}
										</p>
									</SypacText>
								</div>

								<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">{payDate}</p>
								</SypacText>

								<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">{payTime}</p>
								</SypacText>
							</>
						) : (
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 font-bold">—</p>
							</SypacText>
						)}
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			className: 'text-left',
			key: 'action',
			render(value: InvoiceInterface) {
				const isConfirmed =
					value?.payments?.length > 0 && value.status === TableStatusEnum.PAID;
				return (
					<div className="flex items-center justify-center h-full gap-3 px-6 py-3 flex-row">
						{isConfirmed ? (
							<div className="px-4 py-[3px] border border-solid border-gray-10 bg-gray-1 rounded-full">
								<SypacText variant="overline-regular-large">
									<p className="text-base text-gray-80">Confirmed</p>
								</SypacText>
							</div>
						) : (
							<button
								type="button"
								className="px-4 py-[3px] border border-solid border-gray-10 bg-white rounded-md cursor-pointer transition hover:border-gray-40"
								onClick={() =>
									actionClick(PaymentTypeAction.confirm_payment, value)
								}
							>
								<SypacText variant="overline-regular-large">
									<p className="text-base text-gray-80">Confirm</p>
								</SypacText>
							</button>
						)}

						{value?.invoiceUrl ? (
							<button
								type="button"
								data-tooltip-id="invoice-table"
								data-tooltip-content={t(
									'paymentsWithdrawHistory.downloadInvoice',
									'Download Invoice',
								)}
								className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
									expandedRowKeys[0] === value.id ? 'z-[100]' : ''
								}`}
								onClick={() => actionClick(PaymentTypeAction.download, value)}
							>
								<Download />
							</button>
						) : null}

						{value?.invoiceUrl ? (
							<button
								type="button"
								data-tooltip-id="invoice-table"
								data-tooltip-content={t(
									'paymentsWithdrawHistory.viewInvoice',
									'View Invoice',
								)}
								className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
									expandedRowKeys[0] === value.id ? 'z-[100]' : ''
								}`}
								onClick={() =>
									actionClick(PaymentTypeAction.view_invoice, value)
								}
							>
								<EyeIcon />
							</button>
						) : null}
					</div>
				);
			},
		},
	];

	const columnsProducerAdmin = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface) => void,
		search?: string,
	) => [
		{
			title: t('paymentsTableAdminProducer.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === TableStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? dayjs().diff(value.dueDate, 'day')
					: dayjs(value.dueDate).diff(dayjs(), 'day');
				const status = isOverdue ? TableStatusEnum.OVERDUE : value.status;
				let isPartial =
					value.status === TableStatusEnum.PENDING && !!value.payments?.length;
				isPartial =
					value.status === TableStatusEnum.PAID
						? value?.payments?.length > 1
						: isPartial;

				return (
					<div
						className="flex items-center justify-start h-full w-fit py-4 px-5"
						onClick={() => rowClick(value)}
					>
						<TableStatus
							days={days}
							state={status}
							isPartial={isPartial}
							insideOut={true}
						/>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex flex-row justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.id}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.created', 'Created'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = formatDate(value.createdAt);
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.dueDate', 'Due date'),
			dataIndex: '',
			className: 'text-left',
			key: 'dueDate',
			render(value: InvoiceInterface) {
				const date = formatDate(value.dueDate);
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.company', 'Company'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceInterface) {
				const company = value?.company;

				return (
					<div
						className="flex gap-4 items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						{company ? (
							<div>
								<SypacAvatar size="sm" initials={company?.name} />
							</div>
						) : null}

						{company && company?.name ? (
							<SypacLink>
								<a href="/" onClick={companyClick(company.id)}>
									{company?.name}
								</a>
							</SypacLink>
						) : null}
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.amount', 'Amount'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountOpen',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.totalAmount?.toFixed(2) || '0')}
									suffix={value?.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClientAdmin.amountPaid', 'Amount paid'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountPaid',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								{value.totalAmount - value.balanceDue === 0 ? (
									'—'
								) : (
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(
											(value.totalAmount - value.balanceDue).toFixed(2),
										)}
										suffix={value?.countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								)}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.paymentMethod', 'Payment method'),
			dataIndex: '',
			className: 'text-left',
			key: 'paymentMethod',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								{value.paymentMethod.charAt(0).toUpperCase() +
									value.paymentMethod.slice(1)}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.order', 'Order'),
			dataIndex: '',
			className: 'text-left',
			key: 'order',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;

				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{orderId}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableAdminProducer.solvedBy', 'Solved by'),
			dataIndex: '',
			className: 'text-left',
			key: 'solvedBy',
			render(value: InvoiceInterface) {
				const payment = value?.payments?.length
					? value?.payments?.at(value?.payments?.length - 1)
					: null;
				const payDate = payment?.createdAt
					? formatDate(payment?.createdAt)
					: '';
				const payTime = payment?.createdAt
					? dayjs(payment?.createdAt).format('HH:mm')
					: '';

				return (
					<div
						className="flex gap-4 items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						{payment ? (
							<>
								<div className="flex items-center gap-4">
									{payment?.confirmedUser && payment?.confirmedUser?.name && (
										<div>
											<SypacAvatar
												size="sm"
												initials={
													payment?.confirmedUser?.email ||
													payment?.confirmedUser?.name
												}
											>
												{payment?.confirmedUser?.avatarUrl ? (
													<img
														src={user?.avatarUrl}
														alt={
															payment?.confirmedUser?.email ||
															payment?.confirmedUser?.name
														}
													/>
												) : null}
											</SypacAvatar>
										</div>
									)}

									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">
											{payment?.confirmedUser?.name}
										</p>
									</SypacText>
								</div>

								<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">{payDate}</p>
								</SypacText>

								<div className="w-[2px] h-[2px] bg-gray-80 rounded-full" />

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">{payTime}</p>
								</SypacText>
							</>
						) : (
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 font-bold">—</p>
							</SypacText>
						)}
					</div>
				);
			},
		},
		{
			title: '',
			dataIndex: '',
			className: 'text-left',
			key: 'action',
			render(value: InvoiceInterface) {
				const isConfirmed =
					value?.payments?.length > 0 && value.status === TableStatusEnum.PAID;
				return (
					<div className="flex items-center justify-center gap-3 px-6 py-3 flex-row">
						{isConfirmed ? (
							<div className="flex gap-3 px-4 py-[3px] justify-center items-center bg-mountain-meadow-10 rounded-full">
								<Checkmark color="#20AC93" />
								<SypacText variant="overline-regular-large">
									<p className="text-gray-80">Paid</p>
								</SypacText>
							</div>
						) : (
							<button
								type="button"
								className="px-4 py-[3px] border border-solid border-gray-10 bg-white rounded-md cursor-pointer transition hover:border-gray-40"
								onClick={() =>
									actionClick(PaymentTypeAction.paid_payment, value)
								}
							>
								<SypacText variant="overline-regular-large">
									<p className="text-base text-gray-80">Pay</p>
								</SypacText>
							</button>
						)}

						{value?.invoiceUrl ? (
							<button
								type="button"
								data-tooltip-id="invoice-table"
								data-tooltip-content={t(
									'paymentsWithdrawHistory.downloadInvoice',
									'Download Invoice',
								)}
								className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
									expandedRowKeys[0] === value.id ? 'z-[100]' : ''
								}`}
								onClick={() => actionClick(PaymentTypeAction.download, value)}
							>
								<Download />
							</button>
						) : null}

						{value?.invoiceUrl ? (
							<button
								type="button"
								data-tooltip-id="invoice-table"
								data-tooltip-content={t(
									'paymentsWithdrawHistory.viewInvoice',
									'View Invoice',
								)}
								className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
									expandedRowKeys[0] === value.id ? 'z-[100]' : ''
								}`}
								onClick={() =>
									actionClick(PaymentTypeAction.view_invoice, value)
								}
							>
								<EyeIcon />
							</button>
						) : null}
					</div>
				);
			},
		},
	];

	const columns = (
		rowClick: (payment: InvoiceInterface) => void,
		actionClick: (type: string, payment: InvoiceInterface | number) => void,
		search?: string,
	) => [
		{
			title: t('paymentsTableClient.paymentStatus', 'Payment status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: InvoiceInterface) {
				const isOverdue =
					value.status === TableStatusEnum.PENDING &&
					dayjs(value.dueDate).toISOString() < dayjs().toISOString();
				const days = isOverdue
					? Math.abs(calculateRemainingDays(value.dueDate))
					: dayjs(value.dueDate).diff(dayjs(), 'day');
				const status = isOverdue ? TableStatusEnum.OVERDUE : value.status;
				let isPartial =
					value.status === TableStatusEnum.PENDING && !!value.payments?.length;
				isPartial =
					value.status === TableStatusEnum.PAID
						? value?.payments?.length > 1
						: isPartial;

				return (
					<div
						className="flex items-center justify-start h-full w-fit py-4 px-5"
						onClick={() => rowClick(value)}
					>
						<TableStatus
							days={days}
							state={status}
							isPartial={isPartial}
							insideOut={true}
						/>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex flex-row justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{value.id}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.paymentMethod', 'Payment method'),
			dataIndex: '',
			className: 'text-center',
			key: 'paymentMethod',
			render(value: InvoiceInterface) {
				const paymentMethod = `${value.paymentMethod
					.charAt(0)
					.toUpperCase()}${value.paymentMethod.slice(1)}`;

				return (
					<div
						className="flex flex-row justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								<T keyName={`paymentsTable.${value.paymentMethod}`}>
									{paymentMethod}
								</T>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.createdDate', 'Created'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdAt',
			render(value: InvoiceInterface) {
				const date = formatDate(value.createdAt);
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsWithdrawHistory.paymentDate', 'Due date'),
			dataIndex: '',
			className: 'text-left',
			key: 'dueDate',
			render(value: InvoiceInterface) {
				const date = formatDate(value.dueDate);
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{date}</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.amountOpen', 'Amount open'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountOpen',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={parseFloat(value.balanceDue?.toFixed(2) || '0')}
									suffix={value?.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.amountPaid', 'Amount paid'),
			dataIndex: '',
			className: 'text-left',
			key: 'amountPaid',
			render(value: InvoiceInterface) {
				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 font-bold">
								{value.totalAmount - value.balanceDue === 0 ? (
									'—'
								) : (
									<NumericFormat
										type="text"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={parseFloat(
											(value.totalAmount - value.balanceDue).toFixed(2),
										)}
										suffix={value?.countryCode === 'MD' ? ' MDL' : ' PLN'}
									/>
								)}
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.transaction', 'Transaction'),
			dataIndex: '',
			className: 'text-left',
			key: 'transaction',
			render(value: InvoiceInterface) {
				const invoiceType = `${value.invoiceType
					.charAt(0)
					.toUpperCase()}${value.invoiceType.slice(1)}`;

				return (
					<div
						className="flex justify-start items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">
								<T keyName={`paymentsTable.${value.invoiceType}`}>
									{invoiceType}
								</T>
							</p>
						</SypacText>
					</div>
				);
			},
		},
		{
			title: t('paymentsTableClient.user', 'User'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdBy',
			render(value: InvoiceInterface) {
				const user = value?.invoiceLines?.at(0)?.assigneeUser;

				return user && user.name ? (
					<div
						className="flex gap-4 items-center h-full px-4"
						onClick={() => rowClick(value)}
					>
						<SypacAvatar size="sm" initials={user.email || user?.name}>
							{user?.avatarUrl ? (
								<img src={user?.avatarUrl} alt={user.email || user?.name} />
							) : null}
						</SypacAvatar>
						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{user.name}</p>
						</SypacText>
					</div>
				) : null;
			},
		},
		{
			title: ' ',
			dataIndex: '',
			className: 'text-left',
			key: 'actions',
			render(value: InvoiceInterface) {
				const orderId = value?.invoiceLines?.at(0)?.orderId;
				return (
					<div className="flex flex-row justify-start items-center h-full px-4">
						<div className="flex flex-row justify-start md:gap-2 2xl:gap-5">
							<button
								type="button"
								data-tooltip-id="invoice-table"
								data-tooltip-content={t(
									'paymentsWithdrawHistory.downloadInvoice',
									'Download Invoice',
								)}
								className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
									expandedRowKeys[0] === value.id ? 'z-[100]' : ''
								}`}
								onClick={() => actionClick(PaymentTypeAction.download, value)}
							>
								<Download />
							</button>

							<button
								type="button"
								data-tooltip-id="invoice-table"
								data-tooltip-content={t(
									'paymentsWithdrawHistory.viewInvoice',
									'View Invoice',
								)}
								className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
									expandedRowKeys[0] === value.id ? 'z-[100]' : ''
								}`}
								onClick={() =>
									actionClick(PaymentTypeAction.view_invoice, value)
								}
							>
								<EyeIcon />
							</button>

							{orderId ? (
								<button
									type="button"
									data-tooltip-id="invoice-table"
									data-tooltip-content={t(
										'paymentsTable.viewOrderDetails',
										'View order details',
									)}
									className={`w-[32px] h-[32px] bg-white border-[1px] border-solid border-gray-10 rounded-lg p-0 cursor-pointer transition hover:border-gray-20 ${
										expandedRowKeys[0] === value.id ? 'z-[100]' : ''
									}`}
									onClick={() =>
										actionClick(PaymentTypeAction.view_order, orderId)
									}
								>
									<SypacIcon iconName="Document Add" />
								</button>
							) : null}
						</div>
					</div>
				);
			},
		},
	];

	return (
		<>
			<Table
				className={`w-full payment-table ${
					rows.length === 0 ? 'h-[calc(100%-1px)]' : ''
				} ${
					target === 'customer'
						? 'payment-table-buyer'
						: target === 'withdraw_history'
						? 'payment-table-withdraw'
						: target.includes('admin')
						? 'payment-table-admin'
						: ''
				}`}
				expandable={{
					expandedRowKeys,
					expandIcon,
					expandedRowRender:
						target !== 'withdraw_history'
							? expandedRowRender
							: expandedRowRenderHistory,
				}}
				columns={getColumns()}
				data={keyRows}
				rowClassName={getTrProps}
				emptyText={<NoResults target={target} currentTab={currentTab} />}
			/>
			{openView ? (
				<DocumentsViewer
					url={url!}
					isPdf={true}
					isOpen={openView}
					onClose={() => setShowView(false)}
				/>
			) : null}
			{selectedCompany !== undefined && showDetails ? (
				<CompanyDetails
					onClose={() => setShowDetails(false)}
					companyId={selectedCompany}
					onVerification={() => {
						setShowDetails(false);
					}}
				/>
			) : null}
			<Tooltip
				place="top"
				id="invoice-table"
				style={{
					backgroundColor: '#E8E8E8',
					color: '#000000',
				}}
			/>
		</>
	);
};
