import React, { useContext, useMemo, useState } from 'react';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import { MapIcon } from '../../assets/MapIcon';
import Flag from 'react-world-flags';
import Close from '../../assets/Close';
import { ClientStoreBreadcrumbsProps } from './ClientStoreBreadcrumbs.interface';
import { OrderContext } from '../../context/OrderContext/order.context';
import { useNavigate } from 'react-router-dom';
import ModalDeleteAddress from './components/ModalDeleteAddress';

const ClientStoreBreadcrumbs: React.FC<ClientStoreBreadcrumbsProps> = ({
	currentGroup,
	currentCategory,
	categoryId,
	currentProduct,
}) => {
	const { order, additional, setAdditional } = useContext(OrderContext);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const navigate = useNavigate();

	const deliveryAddress = useMemo(() => {
		if (!order?.location) return '';

		const { street, houseNumber, postalCode, city, country }: any =
			order.location.address;

		return [
			[street, houseNumber].filter(Boolean).join(' '),
			postalCode,
			city,
			country,
		]
			.filter(Boolean)
			.join(', ');
	}, [order?.location]);

	const handleDontShowAnymore = () => {
		setAdditional((prev) => ({
			...prev,
			dontShowAnymore: true,
		}));
	};

	return (
		<>
			<div className="flex flex-col gap-3">
				<div className="flex gap-3 items-center">
					<div
						className="cursor-pointer"
						onClick={() => navigate('/store/groups')}
					>
						<SypacText variant="body-regular-medium">
							<p
								className={`text-sm transition hover:text-gray-80 ${
									!currentGroup ? 'text-cornflower-blue' : 'text-gray-40'
								}`}
							>
								<T keyName="clientSelectQuantity.store">Store</T>
							</p>
						</SypacText>
					</div>

					{currentGroup && (
						<>
							<p className="text-[10px] text-gray-22">/</p>
							<div
								className={`hidden sm:block ${
									currentCategory ? 'cursor-pointer' : ''
								}`}
								onClick={() =>
									currentCategory ? navigate('/store/groups') : undefined
								}
							>
								<SypacText variant="body-regular-medium">
									<p
										className={`text-sm transition hover:text-gray-80 ${
											!currentCategory ? 'text-cornflower-blue' : 'text-gray-40'
										}`}
									>
										{currentGroup?.name}
									</p>
								</SypacText>
							</div>
						</>
					)}
					<div className="sm:hidden text-gray-40">...</div>

					{currentCategory && (
						<>
							<p className="hidden sm:block text-[10px] text-gray-22">/</p>
							<div
								className={`hidden sm:block ${
									categoryId ? 'cursor-pointer' : ''
								}`}
								onClick={() =>
									categoryId
										? navigate(
												`/store/groups/${currentGroup?.id}/categories/${categoryId}/products`,
										  )
										: undefined
								}
							>
								<SypacText variant="body-regular-medium">
									<p
										className={`text-sm transition ${
											categoryId ? 'hover:text-gray-80' : ''
										} ${
											!currentProduct ? 'text-cornflower-blue' : 'text-gray-40'
										}`}
									>
										{currentCategory?.name}
									</p>
								</SypacText>
							</div>
						</>
					)}

					{currentProduct && (
						<>
							<p className="text-[10px] text-gray-22">/</p>
							<div>
								<SypacText variant="body-regular-medium">
									<p className="text-sm text-cornflower-blue">
										{currentProduct?.name}
									</p>
								</SypacText>
							</div>
						</>
					)}
				</div>

				<div className="flex items-center gap-4">
					<div className="hidden sm:flex gap-4">
						<span className="scale-[1.2]">
							<MapIcon green={true} />
						</span>
						<SypacText variant="body-regular-medium">
							<p className="text-sm align-middle text-gray-80">
								<T keyName="clientSelectQuantity.deliveryAddress">
									Delivery address:
								</T>
							</p>
						</SypacText>
					</div>

					<span className="mt-[2px]">
						<Flag
							className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
							code={order?.location?.countryCode?.toLocaleUpperCase()}
							width={24}
							height={16}
						/>
					</span>
					<SypacText variant="body-regular-medium">
						<p className="text-sm align-middle text-gray-80">
							{deliveryAddress}
						</p>
					</SypacText>

					<SypacButton variant="subTitle" className="sm:ml-0 ml-[4px]">
						<button
							type="button"
							onClick={() =>
								additional.dontShowAnymore
									? navigate('/store')
									: setShowDeleteModal(true)
							}
							className="flex flex-row justify-center items-center h-[22px] w-[22px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-md"
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>
			</div>
			<ModalDeleteAddress
				isOpen={showDeleteModal}
				onClose={() => setShowDeleteModal(false)}
				dontShowAnymore={handleDontShowAnymore}
				isChecked={additional.dontShowAnymore!}
			/>
		</>
	);
};

export default ClientStoreBreadcrumbs;
