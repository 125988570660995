import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import {
	SypacAvatar,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import './products-table-producer.css';
import { T, useTranslate } from '@tolgee/react';
import {
	ProductInterface,
	ProductStatusEnum,
} from '../../interfaces/product.interface';
import Flag from 'react-world-flags';
import TableStatus from '../TableStatus/TableStatus';
import { formatDate } from '../../utils/time.util';
import { ProductsTableProducerInterface } from './ProductsTableProducer.interface';
import { Tons } from '../../assets/Tons';
import { useNavigate } from 'react-router-dom';

export const ProductsTableProducer: React.FC<
	ProductsTableProducerInterface
> = ({ rows, rowClick, search, clickedOutside, currentTab, storeId }) => {
	const { t } = useTranslate();
	const [keyRows, setRows] = useState<ProductInterface[]>([]);
	const [selectedRowKey, setSelectedRowKey] = useState<number>();
	const navigate = useNavigate();

	const columns = (search?: string) => [
		{
			title: t('productTable.country', 'Country'),
			dataIndex: '',
			className: 'text-left',
			key: 'country',
			render(value: ProductInterface) {
				const countryCode = value?.countryCode;
				const hasSecond = false;

				return (
					<div className="flex items-center gap-3">
						<Flag
							className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
							code={countryCode}
							width={22}
							height={16}
						/>
						<SypacText variant="body-regular-medium">
							<p>{countryCode}</p>
						</SypacText>

						{hasSecond && (
							<>
								<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
								<Flag
									className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded z-10"
									code={countryCode}
									width={22}
									height={16}
								/>
								<SypacText variant="body-regular-medium">
									<p>{countryCode}</p>
								</SypacText>
							</>
						)}
					</div>
				);
			},
		},
		{
			title: t('productTable.status', 'Status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: ProductInterface) {
				const status: string = value?.status || ProductStatusEnum.PENDING;

				return (
					<div className="flex">
						<TableStatus state={status} isPartial={false} insideOut={true} />
					</div>
				);
			},
		},
		{
			title: t('productTable.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: ProductInterface) {
				const html = search
					? value.id
							.toString()
							.replace(
								new RegExp(search, 'gi'),
								(match) => `<span class="text-texas-rose">${match}</span>`,
							)
					: value.id.toString();

				return (
					<SypacText variant="body-regular-medium">
						<p
							className="text-gray"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
					</SypacText>
				);
			},
		},
		{
			title: t('productTable.productName', 'Product name'),
			dataIndex: '',
			className: 'text-left',
			key: 'name',
			render(value: ProductInterface) {
				const productDescription = `${value?.name || ''}`;
				const html = search
					? productDescription?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productDescription;
				const hasSecond = false;

				return (
					<div className="flex gap-4">
						<div
							style={{
								backgroundImage: `url(${value?.photoUrl})`,
							}}
							className="flex flex-none w-[48px] h-[48px] bg-no-repeat bg-cover border border-solid border-gray-10 rounded-10 box-border"
						/>

						<div className="flex flex-col gap-[6px]">
							<div className="flex flex-row items-center gap-3">
								<SypacText variant="body-regular-medium">
									<p
										className="text-gray-80"
										dangerouslySetInnerHTML={{ __html: html }}
									/>
								</SypacText>
								{value?.type || value?.size ? (
									<>
										<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">
												{value?.type || ''}{' '}
												{value?.size ? `(${value?.size})` : ''}
											</p>
										</SypacText>
									</>
								) : null}
							</div>

							<div className="flex flex-row items-center gap-4">
								{value.countryCode ? (
									<Flag
										className="w-[18px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
										code={value.countryCode}
										width={22}
										height={16}
									/>
								) : null}
								<SypacText variant="body-regular-medium">
									<p
										className={`text-sm text-gray-50 ${
											hasSecond ? 'w-[162px] truncate' : ''
										}`}
									>
										{value.address}
									</p>
								</SypacText>

								{hasSecond && (
									<>
										<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
										<Flag
											className="w-[18px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
											code={value.countryCode}
											width={22}
											height={16}
										/>
										<SypacText variant="body-regular-medium">
											<p className="w-[162px] text-sm text-gray-50 truncate">
												{value.address}
											</p>
										</SypacText>
									</>
								)}
							</div>
						</div>
					</div>
				);
			},
		},
		{
			title: t('productTable.addedBY', 'Added by'),
			dataIndex: '',
			className: 'text-left',
			key: 'addedBY',
			render(value: ProductInterface) {
				const companyName = value?.createdByUser?.name;
				const date = formatDate(value.createdAt);

				return (
					<div className="flex gap-4">
						<div className="flex w-11 h-11">
							<SypacAvatar initials={companyName} size="md" />
						</div>

						<div className="flex flex-col gap-[6px]">
							<SypacText variant="body-regular-medium">
								<p className="max-w-[330px] text-gray-90 truncate">
									{companyName}
								</p>
							</SypacText>

							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-50">{date}</p>
							</SypacText>
						</div>
					</div>
				);
			},
		},
	];

	useEffect(() => {
		const newRows = rows.map((row) => ({ ...row, key: row?.id }));
		setRows(newRows);
	}, [rows]);

	const getTrProps = (
		record: ProductInterface,
		_index: number,
		_indent: number,
	): string => {
		const baseClass = `products-table-producer-row-${record.id}`;
		const bgClass = ` products-table-producer-row-${record.status}`;
		const selected =
			record.id === selectedRowKey && !clickedOutside
				? ' products-table-producer-row-selected'
				: '';

		return `${baseClass}${bgClass}${selected}`;
	};

	const handleClick = useCallback(
		(event: Event, row: any) => {
			event.stopImmediatePropagation();
			const className = row.className;
			const regexes = className.match(/products-table-producer-row-[0-9]+/gm);
			const regex = regexes?.length && regexes[0];
			if (!regex) {
				return;
			}
			const query = regex.replace('products-table-producer-row-', '');
			if (!query) {
				return;
			}
			const productId = parseInt(query);
			if (!productId) {
				return;
			}
			setSelectedRowKey(productId);
			const product = rows.find((r) => r.id === productId);
			if (product) {
				rowClick(product);
			}
		},
		[rowClick, rows],
	);

	useEffect(() => {
		const rows = document.querySelectorAll(
			'.products-table-producer .rc-table-row',
		);
		for (let i = 0; i < rows.length; i++) {
			rows[i].addEventListener('click', (e) => handleClick(e, rows[i]));

			const tdElements = rows[i].querySelectorAll('td');
			if (tdElements.length >= 5) {
				tdElements[4].addEventListener('click', (e) => {
					e.stopImmediatePropagation();
					const productId = keyRows[i]?.id;
					const product = keyRows.find((row) => row.id === productId);
					const companyId = product?.company?.id;
					if (companyId && product) {
						setSelectedRowKey(product.id);
					}
				});
			}
		}

		return () => {
			const rows = document.querySelectorAll(
				'.products-table-producer .rc-table-row',
			);
			for (let i = 0; i < rows.length; i++) {
				rows[i].removeEventListener('click', (e) => handleClick(e, rows[i]));

				const tdElements = rows[i].querySelectorAll('td');
				if (tdElements.length >= 5) {
					tdElements[4].removeEventListener('click', (e) => {
						e.stopImmediatePropagation();
						const productId = keyRows[i]?.id;
						const product = keyRows.find((row) => row.id === productId);
						const companyId = product?.company?.id;
						if (companyId && product) {
							setSelectedRowKey(product.id);
						}
					});
				}
			}
		};
	}, [handleClick, keyRows]);

	const noProductsText = useMemo(() => {
		const tabTextMap = {
			all: 'noProductsInStoreYet',
			approved: 'noProductsInApproved',
			pending: 'noProductsInPending',
			rejected: 'noProductsInRejected',
		};
		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab]);

	return (
		<>
			<Table
				className={`products-table-producer ${
					rows.length === 0 ? 'h-[calc(100%-1px)]' : ''
				}`}
				columns={columns(search)}
				data={keyRows}
				rowClassName={getTrProps}
				emptyText={
					<div className="h-full flex flex-col justify-center items-center gap-[60px]">
						<span className="flex scale-[2.25]">
							<Tons />
						</span>

						<div
							className={`flex flex-col gap-6 ${
								currentTab === 'all' ? 'w-[453px] whitespace-normal' : ''
							}`}
						>
							<SypacText variant="body-regular-large">
								<p className="text-2xl leading-7 text-gray-80 text-center">
									<T keyName={`products.${noProductsText}`}>{noProductsText}</T>
								</p>
							</SypacText>

							{currentTab === 'all' && (
								<>
									<SypacText variant="body-normal-small">
										<p className="text-gray-40 text-center">
											<T keyName="ordersTable.noProductsInStoreYetDescription">
												Once you add a product, it will be sent for approval
											</T>
										</p>
									</SypacText>

									<div className="flex justify-center items-center px-3 py-[11px] border border-solid border-gray-10 rounded-10 bg-gray-10-opacity-50">
										<SypacText variant="body-normal-small">
											<p
												className="text-gray-40 text-center"
												style={{
													whiteSpace: 'break-spaces',
												}}
											>
												<T keyName="products.noteAllAddedProductsWillBeReviewed">
													Note: All added products will be reviewed by the SYPAC
													team before they’re displayed in the store.
												</T>
											</p>
										</SypacText>
									</div>

									<SypacButton
										variant="secondary"
										size="small"
										className="w-full"
									>
										<button
											className="w-full flex py-[11px] border-none rounded-lg bg-primary-violet transition hover:bg-primary-violet/80"
											onClick={() => navigate(`/products/${storeId}/groups`)}
										>
											<SypacText variant="body-normal-medium">
												<p className="text-white">
													<T keyName="products.addNewProduct">
														Add new product
													</T>
												</p>
											</SypacText>
										</button>
									</SypacButton>
								</>
							)}
						</div>
					</div>
				}
			/>
		</>
	);
};
