import React, { useCallback, useEffect, useRef, useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import { MutatingDots } from 'react-loader-spinner';
import { SypacButton, SypacText } from '@sypac/component-library-react';
import { useNavigate } from 'react-router-dom';
import { PlusIcon } from '../../../assets/PlusIcon';
import Map from '../../../components/Map/Map';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import {
	ProductService,
	ProductStoreInterface,
} from '../../../services/product.services';
import { LIMIT } from '../../../constants';
import NewStoreCreate from './components/NewStoreCreate';
import { Geo } from '../../../components/SearchLocation/locations.interface';
import { GeoService } from '../../../services/geo.services';
import useDebounce from '../../../hooks/useDebounce';
import { MapMarker, PinVariant } from '../../../components/Map/Map.interface';
import Flag from 'react-world-flags';
import { formatDate } from '../../../utils/time.util';
import DotsDropdown from '../../../components/DotsDropdown/DotsDropdown';
import { DotsDropdownProps } from '../../../components/DotsDropdown/DotsDropdown.interface';

const ProductStores: React.FC = () => {
	const { t } = useTranslate();
	const [loading, setLoading] = useState<boolean>(false);
	const [myStores, setMyStores] = useState<any>();
	const [openCreate, setOpenCreate] = useState<boolean>(false);
	const [locations, setLocations] = useState<Geo[]>([]);
	const [searchLocation, setSearchLocation] = useState<string>('');
	const [markers, setMarkers] = useState<MapMarker[]>([]);
	const [storeLocation, setStoreLocation] = useState<Geo | undefined>(
		undefined,
	);
	const [storeName, setStoreName] = useState<string | undefined>(undefined);
	const [showList, setShowList] = useState<boolean>(true);
	const [selectedStore, setSelectedStore] = useState<any>(null);
	const [loadingMap, setLoadingMap] = useState<boolean>(false);
	const [updatedStore, setUpdatedStore] = useState<MapMarker | undefined>(
		undefined,
	);
	const divRef = useRef<HTMLDivElement>(null);
	const [isScrollable, setIsScrollable] = useState<boolean>(false);
	const searchQuery = useDebounce(searchLocation, 500);
	const navigate = useNavigate();

	const getStoresCallback = useCallback(async () => {
		setLoading(true);
		try {
			const { data } = await ProductService.getProductStores({
				limit: LIMIT,
				offset: 0,
			});

			if (!selectedStore) {
				setLoadingMap(true);
				const newMarkers = data?.items?.map((item) => ({
					latitude: item.location.coordinates[1],
					longitude: item.location.coordinates[0],
					variant: PinVariant.product_store,
					store: {
						name: item.name,
						countryCode: item.countryCode,
						productCount: item.productCount,
					},
				}));
				setMarkers(newMarkers);
				setTimeout(() => {
					setLoadingMap(false);
				}, 300);
			}

			setMyStores(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		} finally {
			setLoading(false);
		}
	}, [selectedStore]);

	useEffect(() => {
		getStoresCallback().then(() => {});
	}, [getStoresCallback]);

	const getSearchLocation = useCallback(() => {
		if (searchQuery.length) {
			GeoService.getLocations({ phrase: searchQuery, maxResults: '10' })
				.then(({ data }) => {
					setLocations(data);
				})
				.catch(console.error);
		} else {
			setLocations([]);
		}
	}, [searchQuery]);

	useEffect(() => {
		getSearchLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const handleClose = () => {
		setOpenCreate(false);
		setShowList(true);
		setSelectedStore(null);
		setStoreName(undefined);
		setStoreLocation(undefined);
		getStoresCallback().then(() => {});
	};

	const getStoreCoords = useCallback(
		async (locationId: string) => {
			if (locationId) {
				setLoadingMap(true);
				try {
					const { data } = await GeoService.getLocationDetails({
						locationid: locationId,
					});
					const newMarker = {
						latitude: data.Latitude,
						longitude: data.Longitude,
						variant: PinVariant.product_store,
						store: {
							name: storeName,
							countryCode: data.address?.countryCode,
						},
					};

					setMarkers([newMarker]);
					setUpdatedStore(newMarker);
				} catch (error) {
					return toastVariant(
						`Something went wrong. ${error?.toString()!}`,
						true,
					);
				} finally {
					setLoadingMap(false);
				}
			}
		},
		[storeName],
	);

	useEffect(() => {
		if (storeLocation?.locationId) {
			getStoreCoords(storeLocation.locationId).then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeLocation, storeName]);

	const editStore = (store: any) => {
		setLoadingMap(true);
		setSelectedStore(store);
		setStoreName(store.name);

		const selectedMarker = {
			latitude: store.location.coordinates[1],
			longitude: store.location.coordinates[0],
			variant: PinVariant.product_store,
			store: {
				name: store.name,
				countryCode: store.countryCode,
				productCount: store.productCount,
			},
		};
		setMarkers([selectedMarker]);

		setOpenCreate(true);
		setShowList(false);
		setTimeout(() => {
			setLoadingMap(false);
		}, 300);
	};

	const getProprieties = (store: ProductStoreInterface): DotsDropdownProps => ({
		options: [
			{
				id: 1,
				title: t('productStores.editStore', 'Edit store'),
				action: () => editStore(store),
			},
		],
	});

	const openProducts = (storeId: number) => {
		navigate(`/products/${storeId}`);
	};

	useEffect(() => {
		const checkIfOverViewport = () => {
			if (divRef.current) {
				const { bottom } = divRef.current.getBoundingClientRect();
				const viewportHeight = window.innerHeight;
				setIsScrollable(bottom > viewportHeight);
			}
		};

		const timeoutId = setTimeout(checkIfOverViewport, 1500);

		return () => clearTimeout(timeoutId);
	}, []);

	return (
		<div className="flex w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-104px)] h-full">
			<div className="relative flex flex-col gap-4">
				{showList && (
					<>
						<div className="flex flex-col gap-4 px-3 xl-2xl:px-5">
							<div className="flex items-center justify-between">
								<SypacText variant="heading-4">
									<p className="text-gray-90">
										<T keyName="productStores.myStores">My stores</T>
									</p>
								</SypacText>

								{myStores?.count > 0 && (
									<SypacButton variant="subTitle">
										<button
											type="button"
											className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-mountain-meadow rounded-lg transition hover:bg-mountain-meadow/80"
											onClick={() => {
												setSelectedStore(null);
												setOpenCreate(true);
												setShowList(false);
											}}
										>
											<PlusIcon color="white" />
										</button>
									</SypacButton>
								)}
							</div>
						</div>
						<div className="border-0 border-solid border-t-[1px] border-gray-10" />

						{loading ? (
							<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[1000]">
								<MutatingDots
									height="100"
									width="100"
									color="#7693F4"
									secondaryColor="#494C83"
									radius="12.5"
									ariaLabel="mutating-dots-loading"
									wrapperStyle={{}}
									wrapperClass=""
									visible={true}
								/>
							</div>
						) : (
							<>
								{myStores?.count > 0 ? (
									<div
										ref={divRef}
										className={`flex flex-col gap-3 px-3 xl-2xl:px-5 ${
											isScrollable
												? 'overflow-y-scroll overflow-x-hidden sypac-scrollbar mr-[2px]'
												: ''
										} ${!myStores ? 'h-full justify-center' : ''}`}
									>
										{myStores?.items?.map((store: ProductStoreInterface) => (
											<div
												key={store.name}
												className="w-[432px] xl-2xl:w-[544px] flex flex-col border border-solid border-gray-10 rounded-10 cursor-pointer transition hover:border-[#AFB0C8] box-border"
												onClick={() => openProducts(store.id)}
											>
												<div className="flex items-center p-[10px]">
													<SypacText variant="body-regular-medium">
														<p className="text-xs text-gray-40">
															<T keyName="baseOrderDetails.created">Created:</T>{' '}
															{formatDate(store.createdAt)}
														</p>
													</SypacText>

													<div
														className="ml-auto"
														onClick={(event) => {
															event.stopPropagation();
														}}
													>
														<DotsDropdown
															options={getProprieties(store).options}
														/>
													</div>
												</div>
												<div className="border-0 border-solid border-t-[1px] border-gray-10" />

												<div className="flex flex-col gap-3 p-[10px]">
													<div className="flex items-center justify-between">
														<SypacText variant="body-regular-medium">
															<p className="text-xl text-gray-90">
																{store.name}
															</p>
														</SypacText>

														<SypacText variant="body-regular-medium">
															<p className="text-sm text-gray-80">
																{store.productCount} products
															</p>
														</SypacText>
													</div>

													<div className="flex gap-3">
														<span className="mt-[2px]">
															<Flag
																className="w-[22px] h-[16px] object-cover border border-solid border-gray-10 rounded"
																code={store.countryCode}
																width={24}
																height={16}
															/>
														</span>
														<SypacText variant="body-regular-medium">
															<p className="text-sm align-middle text-gray-80 mt-[2px]">
																{store.address}
															</p>
														</SypacText>
													</div>
												</div>
											</div>
										))}
									</div>
								) : (
									<div className="w-full h-full flex justify-center items-center">
										<div className="w-[339px] flex flex-col text-center gap-[40px] box-border">
											<div className="flex flex-col gap-3">
												<SypacText variant="body-regular-medium">
													<p className="text-2xl text-gray-80">
														<T keyName="productStores.noStoresYet">
															You don't have any stores yet
														</T>
													</p>
												</SypacText>

												<SypacText variant="body-regular-medium">
													<p className="text-sm text-gray-40">
														Lorem ipsum dolor sit amet, consectetur adipiscing
														elit, sed do eiusmod tempor incididunt.
													</p>
												</SypacText>
											</div>

											<SypacButton variant="secondary" size="small">
												<button
													type="button"
													className="py-[9px] border-gray-10 rounded-lg transition hover:border-gray-40"
													onClick={() => {
														setOpenCreate(true);
														setShowList(false);
													}}
												>
													<SypacText variant="body-regular-medium">
														<p>
															<T keyName="productStores.createStore">
																Create store
															</T>
														</p>
													</SypacText>
												</button>
											</SypacButton>
										</div>
									</div>
								)}
							</>
						)}
					</>
				)}
			</div>

			{openCreate && (
				<NewStoreCreate
					locations={locations}
					onClose={handleClose}
					onChange={setSearchLocation}
					setStoreLocation={setStoreLocation}
					storeLocation={updatedStore!}
					setStoreName={setStoreName}
					isEditing={!!selectedStore}
					storeData={selectedStore}
				/>
			)}

			<div className="w-full border border-solid border-gray-10 rounded-xl">
				{!loadingMap ? <Map markers={markers || []} /> : null}
			</div>
		</div>
	);
};

export default ProductStores;
