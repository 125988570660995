import React from 'react';
import { SypacText } from '@sypac/component-library-react';
import './input-border.css';
import { InputBorderProps } from '../../ModalDeposit.interface';

const InputBorder: React.FC<InputBorderProps> = ({
	currency = 'PLN',
	children,
	error = false,
}) => {
	return (
		<div className={` ${error ? 'input-container-error' : 'input-container'}`}>
			{children}
			<SypacText variant="heading-5">
				<p className="input-currency">{currency}</p>
			</SypacText>
		</div>
	);
};

export default InputBorder;
