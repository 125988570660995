import { IconInterface } from '../interfaces/icon.interface';

export const FleetTruckIcon = (props: IconInterface) => {
	const {
		isActive,
		color = '#A2A2A2',
		strokeWidth = '1',
		hoverEffect = false,
	} = props;

	return (
		<svg
			width="21"
			height="16"
			viewBox="0 0 21 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={hoverEffect ? 'transition group-hover:stroke-black' : ''}
		>
			<path
				d="M7.70201 1.69838C4.07071 1.69838 1.12695 4.64213 1.12695 8.27344"
				stroke={isActive ? 'white' : color}
				strokeLinecap="round"
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-black' : ''}
			/>
			<path
				d="M7.32087 3.50725C4.9 3.50725 2.9375 5.46976 2.9375 7.89062"
				stroke={isActive ? 'white' : color}
				strokeLinecap="round"
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-black' : ''}
			/>
			<path
				d="M6.89676 5.27511C5.68633 5.27511 4.70508 6.25636 4.70508 7.4668"
				stroke={isActive ? 'white' : color}
				strokeLinecap="round"
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-black' : ''}
			/>
			<path
				d="M14.1754 4.04883H15.0362C15.9071 4.04883 16.6636 4.64766 16.8636 5.49525L17.3491 7.23866C17.4193 7.53634 17.6301 7.78114 17.9141 7.89473L19.2179 8.41625C19.5743 8.55882 19.808 8.90401 19.808 9.28788V12.6525C19.808 13.1709 19.3879 13.5911 18.8695 13.5913L17.9305 13.5915M17.9305 13.5915C17.9305 14.3692 17.3 14.9997 16.5223 14.9997C15.7446 14.9997 15.1142 14.3687 15.1142 13.591M17.9305 13.5915C17.9305 12.8138 17.3 12.1833 16.5223 12.1833C15.7446 12.1833 15.1142 12.8133 15.1142 13.591M4.31831 13.591H3.84893C3.33046 13.591 2.91016 13.1707 2.91016 12.6523V11.2446C2.91016 10.7261 3.33046 10.3058 3.84893 10.3058H5.25708M4.31831 13.591C4.31831 14.3687 4.94876 14.9997 5.72647 14.9997C6.50417 14.9997 7.13462 14.3692 7.13462 13.5915C7.13462 12.8138 6.50417 12.1833 5.72647 12.1833C4.94876 12.1833 4.31831 12.8133 4.31831 13.591ZM15.1142 13.591L10.4203 13.5915M10.4203 13.5915C10.4203 14.3692 9.78986 14.9997 9.01216 14.9997C8.23446 14.9997 7.60401 14.3692 7.60401 13.5915C7.60401 12.8138 8.23446 12.1833 9.01216 12.1833C9.78986 12.1833 10.4203 12.8138 10.4203 13.5915ZM16.0529 9.36704H15.1142"
				stroke={isActive ? 'white' : color}
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-black' : ''}
			/>
			<path
				d="M5.34766 10.3085H11.8247C12.377 10.3085 12.8247 9.86084 12.8247 9.30857L12.8249 4.82721V3.57684C12.8249 3.02456 13.2726 2.57684 13.8249 2.57684H14.2698C14.7053 2.57684 15.0583 2.22385 15.0583 1.78842V1.78842C15.0583 1.35299 14.7053 1 14.2698 1H12.0466C11.7118 1 11.3992 1.16757 11.2138 1.4464L9.88866 3.43981"
				stroke={isActive ? 'white' : color}
				strokeLinecap="round"
				strokeWidth={strokeWidth}
				className={hoverEffect ? 'transition group-hover:stroke-black' : ''}
			/>
		</svg>
	);
};
