import React from 'react';
import Flag from 'react-world-flags';
import { NumericFormat } from 'react-number-format';
import {
	SypacAvatar,
	SypacBadge,
	SypacBox,
	SypacText,
} from '@sypac/component-library-react';
import { ShortCompanyBoxProps } from './ShortCompanyBoxProps';
import { RenderIconByState } from '../../../../../components/RenderIconByState/RenderIconByState';
import { RenderIconByTarget } from '../../../../../components/RenderIconByTarget/RenderIconByTarget';
import {
	colorsTarget,
	titlesTarget,
} from '../../../../../constants/company-target.constants';
import { PaymentPeriod } from '../../../../../components/PyamentPeriod';
import { T, useTranslate } from '@tolgee/react';

export const ShortCompanyBox = ({ data }: ShortCompanyBoxProps) => {
	const { t } = useTranslate();

	const titles: Record<string, string> = {
		all: 'All',
		registration_in_progress: 'Registration (in progress)',
		verification_pending: 'Verification (pending)',
		verified: 'Verified',
	};

	return (
		<SypacBox
			className="h-fit justify-center bg-alabaster border border-solid border-l-0 border-r-0 rounded-2xl box-border"
			hover={false}
			boxStyle="solid"
			boxColor="primary"
		>
			<div className="flex flex-col gap-5 px-10 py-4">
				<div className="flex gap-6">
					<div className="flex items-center justify-center w-[50px] h-[50px] rounded-full bg-gray-10">
						<p className="text-2xl leading-none text-gray-80">
							{data?.name
								.split(' ')
								.map((name) => name[0])
								.join('')
								.toUpperCase()
								.slice(0, 2)}
						</p>
					</div>

					<div className="flex flex-col gap-[4px]">
						<div className="flex gap-6">
							<SypacText
								variant="body-regular-medium"
								className="flex items-end"
							>
								<p className="text-xl leading-none text-gray-80">
									{data?.name}
								</p>
							</SypacText>

							{data.targets.map((r) => {
								return (
									<SypacBadge color={colorsTarget[r]} size="large" key={r}>
										<div className="flex items-center justify-center gap-2 px-4">
											<RenderIconByTarget target={r} />
											<SypacText variant="overline-normal-large">
												<p>{titlesTarget[r]}</p>
											</SypacText>
										</div>
									</SypacBadge>
								);
							})}
						</div>
						<div className="flex items-center">
							{data.country ? (
								<Flag
									className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
									code={data.country.toLocaleUpperCase()}
									width={24}
									height={16}
								/>
							) : null}
							<SypacText
								variant="body-regular-medium"
								className={data.country ? 'ml-3' : ''}
							>
								<p className="text-gray-80">{data.address}</p>
							</SypacText>
						</div>
					</div>
				</div>

				<div className="flex w-full h-[28px] justify-between gap-5">
					{['producer', 'transporter'].includes(data.targets[0]) ? (
						<div className="flex w-full items-center justify-center bg-gray-10/50 border border-solid border-gray-10 rounded-full">
							<SypacText variant="body-regular-medium">
								<p className="py-[5px] px-4">
									<T keyName="assignees.withdrawInterval">Withdraw interval:</T>{' '}
									{PaymentPeriod({
										value: parseInt(data.withdrawInterval || '0'),
									})}
								</p>
							</SypacText>
						</div>
					) : null}

					{data.targets.includes('customer') ? (
						<div className="flex w-full items-center justify-center bg-gray-10/50 border border-solid border-gray-10 rounded-full">
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-80 px-4">
									<T keyName="assignees.creditLimit">Credit limit:</T>{' '}
									<NumericFormat
										type="text"
										className="font-bold"
										displayType="text"
										thousandSeparator="."
										decimalSeparator=","
										value={data.creditLimit}
										suffix={data.country === 'MD' ? ' MDL' : ' PLN'}
									/>
								</p>
							</SypacText>
						</div>
					) : null}

					{data.targets.includes('customer') ? (
						<div className="flex w-full items-center justify-center bg-gray-10/50 border border-solid border-gray-10 rounded-full">
							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-80 px-4">
									<T keyName="assignees.paymentTerm">Payment term:</T>{' '}
									<NumericFormat
										type="text"
										className="font-bold"
										displayType="text"
										value={data.terms}
										suffix={' days'}
									/>
								</p>
							</SypacText>
						</div>
					) : null}

					{data.targets.includes('customer') ? (
						<div
							className={`flex w-full items-center justify-center border border-solid rounded-full ${
								data?.allowExcludedTransporter
									? 'bg-[#E4F2F0] border-[#96D6CB] text-mountain-meadow'
									: 'bg-[#FBE5C8] border-[#FDD199] text-gray-80'
							}`}
						>
							<SypacText variant="body-regular-medium">
								<p className="text-sm px-4">
									{data?.allowExcludedTransporter
										? 'Self-service: On'
										: 'Self-service: Off'}
								</p>
							</SypacText>
						</div>
					) : null}
				</div>
			</div>
			<div className="border-0 border-solid border-t-[1px] border-gray-10" />

			<div className="flex w-full px-10 py-4 justify-between box-border">
				<div className="flex flex-col gap-2 border-0 border-solid border-r-[1px] border-gray-10 pr-10">
					<SypacText variant="overline-normal-large">
						<p className="text-gray-40">
							<T keyName="assignees.accountState">Account State:</T>
						</p>
					</SypacText>
					<div className="flex gap-2.5">
						<div className="w-max h-max">
							<RenderIconByState state={data.state} />
						</div>
						<SypacText variant="body-regular-small">
							<p className="text-primary-dark-gray">
								{t(
									`shortCompanyBox.${titles[data.state].toLowerCase()}`,
									titles[data.state],
								)}
							</p>
						</SypacText>
					</div>
				</div>
				<div className="flex flex-col gap-2 border-0 border-solid border-r-[1px] border-gray-10 px-10">
					<SypacText variant="overline-normal-large">
						<p className="text-gray-40">
							<T keyName="assignees.registeredOn">Registered on SYPAC:</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-small">
						<p className="text-primary-dark-gray">{data.registrationDate}</p>
					</SypacText>
				</div>
				<div className="flex flex-col gap-2 border-0 border-solid border-r-[1px] border-gray-10 px-10">
					<SypacText variant="overline-normal-large">
						<p className="text-gray-40">
							<T keyName="assignees.companyId">Company ID:</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-small">
						<p className="text-primary-dark-gray">{data.companyId}</p>
					</SypacText>
				</div>
				<div className="flex flex-col gap-2 pl-10">
					<SypacText variant="overline-normal-large">
						<p className="text-gray-40">
							<T keyName="assignees.lastOnline">Last Online</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-small">
						<p className="text-primary-dark-gray">{data.lastOnline}</p>
					</SypacText>
				</div>
			</div>

			{data.termsAccepted ? (
				<>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
					<div className="flex w-full px-10 py-4">
						<div className="flex gap-4 items-center">
							<SypacAvatar
								initials={
									data.userOwner.firstName + ' ' + data.userOwner.lastName
								}
								size="md"
							/>

							<div className="flex flex-col gap-1">
								<SypacText variant="body-regular-small">
									<p className="text-xs text-gray-40">
										Date: {data.registrationDate}
									</p>
								</SypacText>

								<SypacText variant="body-regular-small">
									<p>
										<span className="text-base text-gray-90">
											{data.userOwner.firstName + ' ' + data.userOwner.lastName}
											,
										</span>{' '}
										<span className="text-base text-gray-60">
											accepted terms and conditions.
										</span>
									</p>
								</SypacText>
							</div>
						</div>
					</div>
				</>
			) : null}
		</SypacBox>
	);
};
