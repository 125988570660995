import React from 'react';
import { SypacPagination, SypacText } from '@sypac/component-library-react';
import { LIMIT } from '../../constants';
import { T } from '@tolgee/react';

interface PaginationProps {
	page: number;
	count: number;
	onClick: (item: number) => void;
	showText?: boolean;
	customLimit?: number;
}

const Pagination: React.FC<PaginationProps> = ({
	page,
	count,
	onClick,
	showText = true,
	customLimit,
}) => {
	const limit = customLimit || LIMIT;

	return (
		<>
			{showText ? (
				<div>
					<SypacText variant="body-normal-medium">
						<p className="text-gray">
							<T keyName="pagination.showing">Showing</T>{' '}
							{count ? page * limit + 1 : 0}-
							{(page + 1) * limit > count ? count : (page + 1) * limit}{' '}
							<T keyName="pagination.of">of</T> {count}
						</p>
					</SypacText>
				</div>
			) : (
				''
			)}
			<div>
				{count ? (
					<SypacPagination
						count={Math.ceil(count / limit)}
						page={page + 1}
						view-page-range="6"
						onPageChanged={(e) => {
							onClick(e.detail.page - 1);
						}}
					/>
				) : null}
			</div>
		</>
	);
};

export default Pagination;
