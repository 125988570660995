import { CompanyResponse } from '../services/company.services';
import { UsersInterface } from './Users.interface';

export enum ProductStatusEnum {
	PENDING = 'pending',
	APPROVED = 'approved',
	REJECTED = 'rejected',
}

export interface ProductTranslates {
	createdAt: string;
	id: number;
	language: string;
	name: string;
	productId: number;
	updatedAt: string;
}

export interface ProductInterface {
	id: number;
	name: string;
	companyId?: number;
	categoryId?: string;
	company?: CompanyResponse;
	sourceProductId?: number;
	photoUrl: string;
	pricePerUnit: number;
	pricePerUnitWithProcent?: number;
	pricePerUnitWithVat?: number;
	location?: {
		type: string;
		coordinates: number[];
	};
	availableQuantity: number;
	minOrderQuantity: number;
	maxOrderCapacity: number;
	type: string;
	size: string;
	unit: string;
	unlimited: boolean;
	createdAt: string;
	updatedAt: string;
	category: {
		id: string;
		name: string;
		parent?: {
			id: string;
			name: string;
		};
	};
	address: string;
	quantity?: number;
	createdBy?: string;
	createdByUser?: UsersInterface;
	verifiedBy?: string;
	verifiedByUser?: UsersInterface;
	countryCode?: string;
	status?: string;
	markup?: number;
	vat?: number;
	currency: string;
	totalOrders?: number;
	description?: string;
	translations?: ProductTranslates[];
	productCount: number;
}
