import { IconInterface } from '../interfaces/icon.interface';

const NewAddPlus = (props: IconInterface) => {
	const { color = '#494C83', hoverEffect } = props;

	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={
				hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
			}
		>
			<path
				d="M1 9C1 5.22876 1 3.34315 2.17157 2.17157C3.34315 1 5.22876 1 9 1C12.7712 1 14.6569 1 15.8284 2.17157C17 3.34315 17 5.22876 17 9C17 12.7712 17 14.6569 15.8284 15.8284C14.6569 17 12.7712 17 9 17C5.22876 17 3.34315 17 2.17157 15.8284C1 14.6569 1 12.7712 1 9Z"
				stroke={color}
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
			<path
				d="M11.4016 8.99963L9.00156 8.99963M9.00156 8.99963L6.60156 8.99963M9.00156 8.99963L9.00156 6.59961M9.00156 8.99963L9.00156 11.3996"
				stroke="#494C83"
				strokeLinecap="round"
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
		</svg>
	);
};

export default NewAddPlus;
