export const COLORS: {
	[key: string]: {
		[key: string]: string;
	};
} = {
	white: { en: 'White', pl: 'Biały', ro: 'Alb', ru: 'Белый' },
	silver: { en: 'Silver', pl: 'Srebrny', ro: 'Argintiu', ru: 'Серебристый' },
	black: { en: 'Black', pl: 'Czarny', ro: 'Negru', ru: 'Чёрный' },
	gray: { en: 'Gray', pl: 'Szary', ro: 'Gri', ru: 'Серый' },
	blue: { en: 'Blue', pl: 'Niebieski', ro: 'Albastru', ru: 'Синий' },
	red: { en: 'Red', pl: 'Czerwony', ro: 'Roșu', ru: 'Красный' },
	brown: { en: 'Brown', pl: 'Brązowy', ro: 'Maro', ru: 'Коричневый' },
	green: { en: 'Green', pl: 'Zielony', ro: 'Verde', ru: 'Зелёный' },
	yellow: { en: 'Yellow', pl: 'Żółty', ro: 'Galben', ru: 'Жёлтый' },
	orange: {
		en: 'Orange',
		pl: 'Pomarańczowy',
		ro: 'Portocaliu',
		ru: 'Оранжевый',
	},
	purple: { en: 'Purple', pl: 'Fioletowy', ro: 'Violet', ru: 'Фиолетовый' },
	other: { en: 'Other', pl: 'Inny', ro: 'Altele', ru: 'Другой' },
};
