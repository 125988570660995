import React, { useEffect, useMemo, useState } from 'react';
import {
	SypacAvatar,
	SypacBadge,
	SypacImage,
	SypacText,
} from '@sypac/component-library-react';
import { TruckCardProps } from './TruckCard.interface';
import { StatusTruckColors } from '../../constants/order-status.constants';
import useGetRating from '../../hooks/use-get-rating';
import DotsDropdown from '../DotsDropdown/DotsDropdown';
import ModalRemoveTruck from '../ModalRemoveTruck/ModalRemoveTruck';
import { DotsDropdownProps } from '../DotsDropdown/DotsDropdown.interface';
import { TruckService } from '../../services/truck.services';
import { T, useTranslate } from '@tolgee/react';
import { NumericFormat } from 'react-number-format';
import { COLORS } from '../../constants/truck-colors.constant';
import { TYPE_TRUCK } from '../../constants/truck-types.constant';

export const TruckCard = (props: TruckCardProps) => {
	const { t } = useTranslate();
	const lang = useMemo(() => localStorage.getItem('lang') || 'pl', []);
	const { data, editTruck, showTruck, company } = props;
	const {
		id,
		truckPhoto,
		name,
		truckModel,
		truckYear,
		truckColor,
		driverFullName,
		driverAvatar,
		status,
		code,
		emissionType,
		licensePlates,
		type,
	} = data;

	const colorTruck = useMemo(() => {
		const lang = localStorage.getItem('lang') || 'pl';
		const colors = COLORS[truckColor];
		return colors && colors[lang];
	}, [truckColor]);

	const typeTruck = useMemo(() => {
		const truckType = TYPE_TRUCK[lang]?.find((r) => r.value === type);
		return truckType ? truckType.label : type;
	}, [lang, type]);

	const [bgColor, setBgColor] = useState<string>('');
	const [labelBadge, setLabelBadge] = useState<string>('');
	const [showRemoveTruck, setShowRemoveTruck] = useState(false);
	const { color, label } = useGetRating();

	const handleRemoveTruckModal = () => {
		setShowRemoveTruck(!showRemoveTruck);
	};

	const onDelete = async (id: number) => {
		await TruckService.deleteTruck(id)
			.then(() => {
				handleRemoveTruckModal();
				return props.onUpdate ? props.onUpdate() : null;
			})
			.catch(console.error);
	};

	useEffect(() => {
		if (status) {
			const bgColor =
				StatusTruckColors[status].color || StatusTruckColors.created.color;
			const label = StatusTruckColors[status]
				? StatusTruckColors[status].label
				: StatusTruckColors.created.label;
			setBgColor(bgColor);
			setLabelBadge(label);
		}
	}, [status]);

	const Proprieties: DotsDropdownProps = {
		options: [
			{
				id: 1,
				title: t('truckCard.editTruck', 'Edit truck'),
				action: () => editTruck(),
			},
			{
				id: 2,
				title: t('truckCard.deleteTruck', 'Delete truck'),
				action: () => handleRemoveTruckModal(),
			},
			{
				id: 3,
				title: t('truckCard.viewTruck', 'Show Qr Code'),
				action: () => showTruck(),
			},
		],
	};

	return (
		<div className="w-[432px] xl-2xl:w-[544px] h-fit border border-solid border-gray-10 rounded-10 shadow-md transition hover:border-[#AFB0C8] hover:shadow-drop-shadow box-border">
			<div className="flex items-center gap-3 py-1 px-3.5 border-0 border-b border-solid border-b-gray-10">
				{bgColor && labelBadge ? (
					<SypacBadge color={bgColor} outlined={false} className="h-[22px]">
						<div className="whitespace-nowrap min-w-[63px] flex items-center justify-center">
							<SypacText variant="overline-regular-large">
								<p className="text-white">
									<T keyName={`${labelBadge}`}>{labelBadge}</T>
								</p>
							</SypacText>
						</div>
					</SypacBadge>
				) : null}
				<div className="flex justify-center items-center gap-2">
					<div className="flex gap-1.5 items-center">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-90">
								<T keyName="truckCard.truckId">Truck ID:</T>
							</p>
						</SypacText>
						<SypacText variant="overline-regular-large">
							<p className="text-gray-90">{id}</p>
						</SypacText>
					</div>
					<div className="w-[2px] h-[2px] bg-gray-90 rounded-full" />
					<div className="flex gap-1.5 items-center">
						<SypacText variant="overline-regular-large">
							<p className="text-gray-90">
								<T keyName="truckCard.verificationCode">Verification code:</T>
							</p>
						</SypacText>
						<SypacText variant="overline-regular-large">
							<p className="text-gray-90">{code}</p>
						</SypacText>
					</div>
				</div>

				<div className="ml-auto">
					<DotsDropdown options={Proprieties.options} />
				</div>
			</div>
			<div className="flex flex-row py-3 px-3.5 gap-5">
				<div
					style={{
						backgroundImage: `url(${truckPhoto ? truckPhoto : '/truck.jpg'})`,
					}}
					className="flex w-[58px] bg-center bg-cover bg-no-repeat h-[58px] rounded-md min-w-[58px] gap-2"
				/>
				<div className="flex flex-1 flex-col justify-between gap-2">
					<div className="flex flex-row justify-between items-center gap-2.5">
						<SypacText variant="body-regular-large">
							<p className="text-gray-90">{name}</p>
						</SypacText>

						<div className="flex gap-3 rounded-md items-center py-1 px-4 bg-gray-10-opacity-50">
							<SypacImage className="w-[47px] h-[18px]">
								<img src="/tipper.svg" alt="tipper" />
							</SypacImage>
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{typeTruck}</p>
							</SypacText>
						</div>
					</div>
					<div className="flex flex-row justify-between items-center gap-1.5">
						<div className="flex gap-1.5 items-center">
							{truckModel ? (
								<SypacText
									className="truck-card-dot"
									variant="overline-regular-large"
								>
									<p className="text-gray-50">{truckModel}</p>
								</SypacText>
							) : null}
							<div className="w-[2px] h-[2px] bg-gray-50 rounded-full" />
							{truckYear ? (
								<SypacText
									className="truck-card-dot"
									variant="overline-regular-large"
								>
									<p className="text-gray-50">{truckYear}</p>
								</SypacText>
							) : null}
							<div className="w-[2px] h-[2px] bg-gray-50 rounded-full" />
							{truckColor ? (
								<SypacText
									className="truck-card-dot"
									variant="overline-regular-large"
								>
									<p className="text-gray-50">{colorTruck || truckColor}</p>
								</SypacText>
							) : null}
							<div className="w-[2px] h-[2px] bg-gray-50 rounded-full" />
							{truckColor ? (
								<SypacText
									className="truck-card-dot"
									variant="overline-regular-large"
								>
									<p className="text-gray-50">{emissionType}</p>
								</SypacText>
							) : null}
						</div>
						<div className="flex self-end rounded-md py-1 px-4 w-fit bg-gray-10-opacity-50">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-80">{licensePlates}</p>
							</SypacText>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row mx-3">
				<div className="flex justify-start items-center p-2 bg-gray-1/5 w-full rounded-lg">
					<div className="flex flex-row justify-start items-center gap-1 mr-10">
						<SypacText variant="body-regular-small">
							<p className="text-gray-80">
								<T keyName="truckCard.startPrice">Start price:</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-small">
							<p className="text-gray-80">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={'200,00'}
									suffix={company?.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
					<div className="flex flex-row justify-start items-center gap-1">
						<SypacText variant="body-regular-small">
							<p className="text-gray-80">
								<T keyName="truckCard.pricePerKm">Price per km:</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-small">
							<p className="text-gray-80">
								<NumericFormat
									type="text"
									displayType="text"
									thousandSeparator="."
									decimalSeparator=","
									value={'12,00'}
									suffix={company?.countryCode === 'MD' ? ' MDL' : ' PLN'}
								/>
							</p>
						</SypacText>
					</div>
				</div>
			</div>

			<div className="flex py-3 px-4 gap-6 justify-between">
				<div className="flex flex-row gap-3 items-center">
					<SypacAvatar
						size="sm"
						initials={driverFullName}
						className="border border-solid border-gray-10 rounded-full"
					>
						{driverAvatar ? (
							<img src={driverAvatar} alt={driverFullName} />
						) : null}
					</SypacAvatar>
					<SypacText variant="body-regular-small">
						<p className="text-gray-90">{driverFullName}</p>
					</SypacText>
				</div>
				{color && label ? (
					<SypacBadge
						className="h-fit w-fit self-center flex-1"
						color={color}
						outlined={false}
					>
						<div className="font-medium whitespace-nowrap py-0.5 px-1.25 flex justify-center">
							<SypacText variant="overline-regular-large">
								<p className="text-gray-8">{label}</p>
							</SypacText>
						</div>
					</SypacBadge>
				) : null}
				{/* <div className="self-end">
					<SypacButton onClick={props.onClick} variant="secondary">
						<button className="flex flex-row gap-3 py-1.5 px-4 text-primary-violet">
							<SypacIcon
								size="custom"
								width="17px"
								height="17px"
								icon-name="Chat Square 3"
							/>
							<SypacText variant="body-regular-small">
								<p>
									<T keyName="truckCard.message">Message</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div> */}
			</div>

			<ModalRemoveTruck
				id={id}
				name={name}
				isOpen={showRemoveTruck}
				onClose={handleRemoveTruckModal}
				onDelete={onDelete}
			/>
		</div>
	);
};
