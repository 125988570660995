import { IconInterface } from '../interfaces/icon.interface';

const ManageDatabaseIcon = (props: IconInterface) => {
	const { color = '#494C83', hoverEffect } = props;

	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={
				hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
			}
		>
			<path
				d="M10.9766 11.9465C10.9766 10.3587 10.9766 9.56471 11.4699 9.07142C11.9632 8.57812 12.7571 8.57812 14.345 8.57812C15.9329 8.57812 16.7268 8.57812 17.2201 9.07142C17.7134 9.56471 17.7134 10.3587 17.7134 11.9465V13.6308C17.7134 15.2186 17.7134 16.0126 17.2201 16.5059C16.7268 16.9992 15.9329 16.9992 14.345 16.9992C12.7571 16.9992 11.9632 16.9992 11.4699 16.5059C10.9766 16.0126 10.9766 15.2186 10.9766 13.6308V11.9465Z"
				stroke={color}
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
			<path
				d="M1.28906 6.05263C1.28906 7.64052 1.28906 8.43446 1.78236 8.92776C2.27565 9.42105 3.06959 9.42105 4.65748 9.42105C6.24537 9.42105 7.03932 9.42105 7.53261 8.92776C8.0259 8.43446 8.0259 7.64052 8.0259 6.05263V4.36842C8.0259 2.78053 8.0259 1.98659 7.53261 1.49329C7.03932 1 6.24537 1 4.65748 1C3.06959 1 2.27565 1 1.78236 1.49329C1.28906 1.98659 1.28906 2.78053 1.28906 4.36842V6.05263Z"
				stroke={color}
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
			<path
				d="M10.9766 3.52632C10.9766 2.74157 10.9766 2.3492 11.1048 2.03969C11.2757 1.62701 11.6036 1.29914 12.0163 1.1282C12.3258 1 12.7181 1 13.5029 1H15.1871C15.9718 1 16.3642 1 16.6737 1.1282C17.0864 1.29914 17.4143 1.62701 17.5852 2.03969C17.7134 2.3492 17.7134 2.74157 17.7134 3.52632C17.7134 4.31106 17.7134 4.70343 17.5852 5.01294C17.4143 5.42562 17.0864 5.75349 16.6737 5.92443C16.3642 6.05263 15.9718 6.05263 15.1871 6.05263H13.5029C12.7181 6.05263 12.3258 6.05263 12.0163 5.92443C11.6036 5.75349 11.2757 5.42562 11.1048 5.01294C10.9766 4.70343 10.9766 4.31106 10.9766 3.52632Z"
				stroke={color}
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
			<path
				d="M1.28906 14.4736C1.28906 15.2583 1.28906 15.6507 1.41727 15.9602C1.5882 16.3729 1.91607 16.7008 2.32875 16.8717C2.63826 16.9999 3.03063 16.9999 3.81538 16.9999H5.49959C6.28433 16.9999 6.6767 16.9999 6.98621 16.8717C7.39889 16.7008 7.72676 16.3729 7.8977 15.9602C8.0259 15.6507 8.0259 15.2583 8.0259 14.4736C8.0259 13.6888 8.0259 13.2965 7.8977 12.987C7.72676 12.5743 7.39889 12.2464 6.98621 12.0755C6.6767 11.9473 6.28433 11.9473 5.49959 11.9473H3.81538C3.03063 11.9473 2.63826 11.9473 2.32875 12.0755C1.91607 12.2464 1.5882 12.5743 1.41727 12.987C1.28906 13.2965 1.28906 13.6888 1.28906 14.4736Z"
				stroke={color}
				className={
					hoverEffect ? 'transition group-hover:stroke-primary-violet/70' : ''
				}
			/>
		</svg>
	);
};

export default ManageDatabaseIcon;
