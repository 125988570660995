import React, { useState } from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacIcon,
	SypacTabs,
	SypacText,
} from '@sypac/component-library-react';
import { BillingTab } from '../../../../../components/BillingTabs/BillingTabs.interface';
import { TabsButton } from '../../../../../components/TabsButton/TabsButton';
import Overview from './components/Overview';
import { ProductDetailsDatabaseProps } from '../../productDatabase.interface';
import { formatDate } from '../../../../../utils/time.util';
import { NoResults } from '../../../../../assets/NoResult';
import Listings from './components/Listings';

const ProductDetailsDatabase: React.FC<ProductDetailsDatabaseProps> = ({
	product,
	deleteProduct,
	target,
	onClose,
	activeLanguage,
}) => {
	const { t } = useTranslate();
	const [currentTab, setCurrentTab] = useState<string>('overview');

	const tabs = [
		{
			label: t('productDetails.overview', 'Overview'),
			count: '0',
			value: 'overview',
		},
		{
			label: t('ProductDetailsDatabase.listings', 'Listings'),
			count: '0',
			value: 'listings',
		},
		{
			label: t('productDetails.history', 'Order history'),
			count: '0',
			value: 'history',
		},
	];

	const hasListings = false;

	return (
		<div className="fixed min-h-full top-0 right-0 w-[716px] border border-solid border-gray-10 rounded-l-2xl bg-white shadow-order-details z-[100] h-screen box-border overflow-y-auto scroll-smooth">
			<div className="sticky top-0 flex flex-col gap-5 bg-white z-[120]">
				<div className="w-full flex flex-col gap-3 px-10 py-5 bg-alabaster border border-solid border-gray-10 rounded-10 box-border">
					<div className="w-full flex justify-between items-start">
						<div
							style={{
								backgroundImage: `url(${product.photoUrl})`,
							}}
							className="flex flex-none w-[60px] h-[60px] bg-no-repeat bg-cover rounded-10 border border-solid border-gray-10"
						/>

						<div className="flex flex-col gap-2 text-end">
							<SypacText variant="body-regular-medium">
								<p className="text-xs text-gray-40">
									<T keyName="productDetailsDatabase.createdBy">Created by:</T>{' '}
									{product.createdByUser?.lastName &&
									product.createdByUser?.firstName
										? `${product.createdByUser.firstName} ${product.createdByUser.lastName}`
										: product.createdByUser?.name ?? 'Innessa Polinovaci'}
								</p>
							</SypacText>
							<SypacText variant="body-regular-medium">
								<p className="text-xs text-gray-40">
									{formatDate(product.createdAt, 'DD MMM, YYYY')}
								</p>
							</SypacText>
						</div>
					</div>

					<div className="flex flex-col gap-1">
						<SypacText variant="body-regular-medium">
							<p className="text-xl text-gray-80">
								{product?.translations?.find(
									(locale) => locale.language === activeLanguage,
								)?.name || product?.name}
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-50">
								{product?.type || ''}{' '}
								{product?.size ? `(${product?.size})` : ''}
							</p>
						</SypacText>
					</div>
				</div>
				<div className="px-10">
					<SypacTabs
						variant="gray"
						className="flex w-full p-[3px] gap-[4px box-border"
					>
						{tabs
							? tabs?.map(({ label, value, count }: BillingTab) => {
									return (
										<TabsButton
											key={value}
											label={label}
											buttonId={value!}
											onClick={() =>
												setCurrentTab(value ? value : label.toLowerCase())
											}
											isSelected={
												currentTab === value ||
												currentTab === label.toLowerCase()
											}
											notification={+count}
											color="#E8E8E8"
											classNames="w-full [&_p:first-child]:text-sm"
										/>
									);
							  })
							: null}
					</SypacTabs>
				</div>
			</div>

			<div className="mt-5 mb-10">
				{currentTab === 'overview' ? (
					<Overview
						product={product}
						deleteProduct={deleteProduct}
						target={target}
						onClose={onClose}
						activeLanguage={activeLanguage}
					/>
				) : currentTab === 'listings' ? (
					hasListings ? (
						<Listings />
					) : (
						<div className="flex flex-col gap-[100px] justify-center items-center pt-[150px]">
							<span className="scale-[4]">
								<SypacIcon
									icon-name="Magnifer"
									className="text-gray-40"
									size="custom"
									width="20px"
									height="20px"
								/>
							</span>

							<SypacText variant="body-regular-medium">
								<p className="text-2xl text-gray-80 w-[350px] text-center">
									<T keyName="productDetailsDatabase.noProductListings">
										No product listings placed under this product type
									</T>
								</p>
							</SypacText>
						</div>
					)
				) : (
					<div className="flex flex-col gap-[100px] justify-center items-center pt-[150px]">
						<NoResults />

						<SypacText variant="body-regular-medium">
							<p className="text-2xl text-gray-80 w-[350px] text-center">
								<T keyName="productTable.noResultFoundSearch">
									Feature is not available at the moment
								</T>
							</p>
						</SypacText>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductDetailsDatabase;
