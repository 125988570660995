import { IconInterface } from '../interfaces/icon.interface';

export const StackThin = (props: IconInterface) => {
	const { color = '#A2A2A2' } = props;

	return (
		<svg
			width="51"
			height="42"
			viewBox="0 0 51 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.94708 15.2127C2.98236 13.2268 0.5 12.2339 0.5 11C0.5 9.76613 2.98236 8.77318 7.94708 6.7873L14.9682 3.97883C19.933 1.99294 22.4153 1 25.5 1C28.5847 1 31.067 1.99294 36.0318 3.97883L43.0529 6.7873C48.0176 8.77318 50.5 9.76613 50.5 11C50.5 12.2339 48.0176 13.2268 43.0529 15.2127L36.0318 18.0212C31.067 20.0071 28.5847 21 25.5 21C22.4153 21 19.933 20.0071 14.9682 18.0212L7.94708 15.2127Z"
				stroke={color}
			/>
			<path
				d="M50.5 21C50.5 21 48.0176 23.2268 43.0529 25.2127L36.0318 28.0212C31.067 30.0071 28.5847 31 25.5 31C22.4153 31 19.933 30.0071 14.9682 28.0212L7.94708 25.2127C2.98236 23.2268 0.5 21 0.5 21"
				stroke={color}
				strokeLinecap="round"
			/>
			<path
				d="M50.5 31C50.5 31 48.0176 33.2268 43.0529 35.2127L36.0318 38.0212C31.067 40.0071 28.5847 41 25.5 41C22.4153 41 19.933 40.0071 14.9682 38.0212L7.94708 35.2127C2.98236 33.2268 0.5 31 0.5 31"
				stroke={color}
				strokeLinecap="round"
			/>
		</svg>
	);
};
