import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {
	SypacAvatar,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import Modal from 'react-modal';
import { Logout } from '../../assets/Logout';
import { AuthsService } from '../../services/auths.service';
import { AuthContext } from '../../context/context';
import { RenderIconByTarget } from '../RenderIconByTarget/RenderIconByTarget';
import { titlesTarget } from '../../constants/company-target.constants';
import { RadioCheked } from '../../assets/RadioCheked';
import { Radio } from '../../assets/Radio';
import {
	CompanyResponse,
	CompanyService,
} from '../../services/company.services';
import { toastVariant } from '../CompaniesTable/toastVariant/toastVariant';
import Flag from 'react-world-flags';
import { ProfileModalProps } from './ProfileModal.interface';
import { useNavigate } from 'react-router-dom';
import { MyProfile, UsersServices } from '../../services/users.services';
import BouncingLoader from '../BouncingLoader/BouncingLoader';

export const ProfileModal: React.FC<ProfileModalProps> = ({
	isOpen,
	onClose,
	isLogoutOnly = false,
	viewTerms,
}) => {
	const { user } = useContext(AuthContext);
	const [isModalOpen, setModalOpen] = useState<boolean>(isOpen);
	const [myCompany, setMyCompany] = useState<CompanyResponse>();
	const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
	const [myProfile, setMyProfile] = useState<MyProfile>();
	const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
	const navigate = useNavigate();

	const getMyProfileCustomer = useCallback(async () => {
		setLoadingProfile(true);
		try {
			const { data } = await UsersServices.getMyProfile(
				user?.company.industries[0]!,
			);
			setMyProfile(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		} finally {
			setLoadingProfile(false);
		}
	}, [user]);

	useEffect(() => {
		if (!user?.isAdmin) {
			getMyProfileCustomer().then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getMyProfileCustomer]);

	const userName = useMemo(() => {
		return myProfile?.profile
			? `${myProfile?.profile.firstName} ${myProfile?.profile.lastName}`
			: user?.name;
	}, [myProfile, user?.name]);

	const getMyCompanyCallback = useCallback(async () => {
		setLoadingCompany(true);
		try {
			const { data } = await CompanyService.getMeCompany();
			setMyCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		} finally {
			setLoadingCompany(false);
		}
	}, []);

	useEffect(() => {
		if (!user?.isAdmin) {
			getMyCompanyCallback().then(() => {});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getMyCompanyCallback]);

	if (
		(user?.company?.industries?.length === 1 || !user?.company?.industries) &&
		!isLogoutOnly
	) {
		isLogoutOnly = true;
	}

	const logout = () => {
		AuthsService.logout();
	};

	const changeCompany = (companyType: string) => {
		let newUrl = '';
		if (companyType === 'transporter') {
			newUrl = window.location.origin.replace('producer', 'transporter');
		}
		if (companyType === 'producer') {
			newUrl = window.location.origin.replace('transporter', 'producer');
		}
		if (newUrl?.length) {
			const lang = localStorage.getItem('lang') || 'pl';
			const accessToken = localStorage.getItem('access_token');
			const refreshToken = localStorage.getItem('refresh_token');
			window.location.replace(
				`${newUrl}?access_token=${accessToken}&refresh_token=${refreshToken}&lang=${lang}`,
			);
		}
	};

	const handleViewTerms = () => {
		setModalOpen(false);
		viewTerms && viewTerms();
	};

	const handleViewSettings = () => {
		onClose();
		navigate('/settings');
	};

	const getTarget = (host: string) => {
		if (host.includes('producer')) return 'producer';
		if (host.includes('transporter')) return 'transporter';
		return 'customer';
	};

	const getTargetColor = (host: string) => {
		switch (getTarget(host)) {
			case 'producer':
				return 'cornflower-blue';
			case 'transporter':
				return 'texas-rose';
			default:
				return 'primary-violet';
		}
	};

	return (
		<Modal
			isOpen={isModalOpen}
			onRequestClose={onClose}
			className="absolute bottom-[102px] left-12 w-[401px] h-fit bg-white border border-solid border-gray-10 rounded-10 outline-none"
			overlayClassName="modal-overlay-without-bg"
			shouldCloseOnOverlayClick={true}
			ariaHideApp={false}
		>
			<div className="flex flex-col gap-2 box-border bg-alabaster border-0 border-solid border-b-[1px] border-gray-10 rounded-10 p-3">
				{loadingCompany && loadingProfile ? (
					<div className="flex w-full justify-center items-center my-6">
						<BouncingLoader />
					</div>
				) : (
					<>
						<div className="flex gap-6">
							<SypacAvatar
								initials={userName}
								size="sm"
								className="scale-[1.1]"
							>
								{myProfile?.profile.photoUrl && (
									<img
										src={myProfile?.profile.photoUrl || ''}
										alt={userName}
										className="w-full h-full object-cover rounded-full"
									/>
								)}
							</SypacAvatar>

							<SypacText variant="body-regular-medium">
								<p className="text-2xl text-gray-90">{userName}</p>
							</SypacText>
						</div>

						{!user?.isAdmin ? (
							<div className="flex flex-col gap-2 ml-[54px]">
								<div className="flex items-center gap-3">
									<SypacText variant="body-regular-medium">
										<p
											className={`text-xl text-${getTargetColor(
												window.location.host,
											)}`}
										>
											{myCompany?.name}
										</p>
									</SypacText>

									<div
										className={`w-[26px] h-[20px] flex justify-center items-center rounded bg-${getTargetColor(
											window.location.host,
										)}`}
									>
										<RenderIconByTarget
											target={getTarget(window.location.host)}
										/>
									</div>
								</div>

								<div className="flex items-center gap-3">
									<span className="mt-[2px]">
										<Flag
											className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
											code={myCompany?.countryCode}
											width={24}
											height={16}
										/>
									</span>
									<SypacText variant="body-regular-medium">
										<p className="text-sm align-middle text-gray-50">
											{myCompany?.registryAddress}
										</p>
									</SypacText>
								</div>
							</div>
						) : null}
					</>
				)}
			</div>

			{!isLogoutOnly ? (
				<>
					<div className="flex flex-col gap-3 w-full p-5 box-border">
						{user?.company.industries?.map((companyType) => {
							return (
								<button
									key={companyType}
									className={`flex items-center justify-between bg-transparent border border-solid transition hover:border-gray-40 rounded-lg px-3 py-2 ${
										!window.location.host.includes(companyType)
											? 'border-gray-10 cursor-pointer'
											: ''
									} ${
										window.location.host.includes(companyType)
											? 'border-gray-40 pointer-events-none'
											: ''
									}`}
									onClick={() => changeCompany(companyType)}
								>
									<div className="flex items-center gap-3">
										<div className="mt-1">
											{window.location.host.includes(companyType) ? (
												<RadioCheked />
											) : (
												<Radio />
											)}
										</div>

										<div
											className={`w-[26px] h-[20px] flex justify-center items-center rounded bg-${getTargetColor(
												companyType,
											)}`}
										>
											<RenderIconByTarget target={companyType} />
										</div>

										<SypacText variant="body-regular-medium">
											<p className="text-gray-80 -tracking-[0.01em] mt-[4px]">
												<T keyName={`profileModal.${companyType}`}>
													{titlesTarget[companyType]}
												</T>
											</p>
										</SypacText>
									</div>
								</button>
							);
						})}
					</div>
					<div className="border-0 border-solid border-t-[1px] border-gray-10" />
				</>
			) : null}

			{!user?.isAdmin ? (
				<div className="flex flex-col p-3 border-0 border-solid border-b-[1px] border-gray-10">
					<SypacButton variant="primary" size="small">
						<button
							type="button"
							className="flex gap-4 bg-transparent border-none -ml-[5px] group"
							onClick={handleViewTerms}
						>
							<SypacIcon
								iconName="Notebook"
								size="custom"
								width="32px"
								height="32px"
								className="text-gray-80 transition group-hover:text-gray-80/60"
							/>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 mt-1 transition group-hover:text-gray-80/60">
									<T keyName="profileModal.viewTermsAndConditions">
										View Terms and Conditions
									</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton variant="primary" size="small">
						<button
							type="button"
							className="flex gap-4 bg-transparent border-none -ml-[5px] group"
							onClick={handleViewSettings}
						>
							<SypacIcon
								iconName="Settings"
								size="custom"
								width="32px"
								height="32px"
								className="text-gray-80 transition group-hover:text-gray-80/60"
							/>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80 mt-1 transition group-hover:text-gray-80/60">
									<T keyName="profileModal.userSettings">User Settings</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			) : null}

			<div className="py-2.5">
				<SypacButton variant="primary" size="small">
					<button
						type="button"
						className="flex gap-5 bg-transparent border-none group"
						onClick={logout}
					>
						<span className="scale-[0.7] ml-[6px]">
							<Logout color="#F44A77" hoverEffect={true} />
						</span>

						<SypacText variant="body-regular-medium">
							<p className="text-gray-80 transition group-hover:text-gray-80/60">
								<T keyName="producerRouter.logOut">Log out</T>
							</p>
						</SypacText>
					</button>
				</SypacButton>
			</div>
		</Modal>
	);
};
