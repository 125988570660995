import React, {
	DragEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { T, useTranslate } from '@tolgee/react';
import {
	SypacButton,
	SypacCheckbox,
	SypacDatepicker,
	SypacIcon,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EditPenIcon from '../../../assets/EditPenIcon';
import Select from 'react-select';
import { COUNTRIES } from '../../../components/DropDownOption/countries.constant';
import { DropDownOption } from '../../../components/DropDownOption/DropDownOption';
import Flag from 'react-world-flags';
import {
	activeFieldInterface,
	SettingCompanyProfileProps,
} from '../userSettings.interface';
import dayjs from 'dayjs';
import { Geo } from '../../../components/SearchLocation/locations.interface';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { GeoService } from '../../../services/geo.services';
import useDebounce from '../../../hooks/useDebounce';
import PhoneInput from 'react-phone-input-2';
import ModalChangeRequest from './ModalChangeRequest';
import {
	MAX_FILE_SIZE_MB,
	PREFERRED_PHONE_COUNTIES,
	settingsCountrySelect,
	settingsCountrySelectError,
} from '../constants';
import { FileService } from '../../../services/file.service';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';
import { FileInterface } from '../../../components/CompanyVerification/components/CompanyDocuments/CompanyDocuments.interface';
import {
	CompanyService,
	UpdateMyCompany,
} from '../../../services/company.services';
import LoadingSpinner from '../../../assets/LoadingSpinner';
import { PhotoFrame } from '../../../assets/PhotoFrame';

const SettingCompanyProfile: React.FC<SettingCompanyProfileProps> = ({
	company,
	updateMyCompany,
}) => {
	const { t } = useTranslate();
	const [file, setFile] = useState<FileInterface | undefined>(undefined);
	const [selectedCountry, selectCountry] = useState<any>({});
	const [locationResults, setLocationResults] = useState<boolean>(false);
	const [searchLocation, setSearchLocation] = useState<string>('');
	const searchQuery = useDebounce(searchLocation, 500);
	const [locations, setLocations] = useState<Geo[]>([]);
	const [billingLocationResults, setBillingLocationResults] =
		useState<boolean>(false);
	const [searchBillingLocation, setSearchBillingLocation] =
		useState<string>('');
	const billingSearchQuery = useDebounce(searchBillingLocation, 500);
	const [billingLocations, setBillingLocations] = useState<Geo[]>([]);
	const [billingAddressCheckbox] = useState<boolean>(
		company?.registryAddress === company?.billingAddress,
	);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [activeField, setActiveField] = useState<activeFieldInterface>({
		type: null,
		value: '',
	});
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const formRef = useRef<HTMLFormElement>(null);

	const getSearchLocation = useCallback(() => {
		if (searchQuery.length) {
			GeoService.getLocations({ phrase: searchQuery, maxResults: '10' })
				.then(({ data }) => {
					setLocations(data);
				})
				.catch();
		} else {
			setLocations([]);
		}
	}, [searchQuery]);

	useEffect(() => {
		getSearchLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const getBillingSearchLocation = useCallback(() => {
		if (billingSearchQuery.length) {
			GeoService.getLocations({ phrase: billingSearchQuery, maxResults: '10' })
				.then(({ data }) => {
					setBillingLocations(data);
				})
				.catch();
		} else {
			setBillingLocations([]);
		}
	}, [billingSearchQuery]);

	useEffect(() => {
		getBillingSearchLocation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billingSearchQuery]);

	const isCustomer = company.companyTypes.includes('customer');
	const availableCompanyTypes = isCustomer
		? ['customer']
		: ['producer', 'transporter'];

	const formik = useFormik({
		initialValues: {
			companyName: company?.name,
			companyRegistrationNumber: company?.registrationNumber,
			vatNumber: company?.vatId,
			country: {
				label: COUNTRIES.find((item) => item.value === company?.countryCode)
					?.label,
				value: company?.countryCode,
			},
			companyTypes: company?.companyTypes,
			established: '',
			registryAddress: company?.registryAddress,
			billingAddress: company?.billingAddress,
			companyWebSite: company?.website,
			primaryEmail: company?.contact?.email,
			primaryPhoneNumber: company?.contact?.phone?.phoneNumber,
		},
		onSubmit: async (values) => {
			// setLoadingProfile(true);
			try {
				const body: UpdateMyCompany = {
					phone: {
						phoneNumber: values.primaryPhoneNumber,
						isViber: true,
						isTelegram: true,
						isWhatsapp: true,
					},
					registryAddress: values.registryAddress!,
					logoUrl: file?.file?.name || company?.logoUrl,
					lat: 0,
					long: 0,
					website: values.companyWebSite,
				};

				await CompanyService.updateMyCompany(company?.companyTypes[0], {
					...body,
				});
				toastVariant('Company profile updated successfully', false);
			} catch (e) {
			} finally {
				// setLoadingProfile(false);
				setFormEdited(false);
				updateMyCompany();
			}
		},
		validationSchema: Yup.object({
			primaryPhoneNumber: Yup.string().required(
				'Primary phone number is required',
			),
		}),
		onReset: () => {
			setFormEdited(false);
		},
	});

	const getDataFiles = async (file: File) => {
		try {
			const { data } = await FileService.uploadFiles([file]);
			const newFile = data.items[0];
			const [name, type] = newFile.originalName?.split('.') || [];
			const mimeType =
				newFile.type?.split('/').pop()?.toUpperCase() ||
				type?.toUpperCase() ||
				'';
			const size = `${(file.size / (1024 * 1024)).toFixed(1)} MB`;

			setFile({ name, mimeType, size, file: newFile });
			setFormEdited(true);
		} catch (e) {}
	};

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		if (e.dataTransfer.files && e.dataTransfer.files.length) {
			const droppedFile = e.dataTransfer.files[0];
			if (droppedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(droppedFile).then(() => {});
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files && e.target.files.length) {
			const selectedFile = e.target.files[0];
			if (selectedFile.size / (1024 * 1024) > MAX_FILE_SIZE_MB) {
				toastVariant(
					`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`,
					true,
				);
				return;
			}
			getDataFiles(selectedFile).then(() => {});
		}
	};

	const setCountry = (data: any, _: any) => {
		selectCountry(data);
		formik.setFieldValue('country', data.value);
	};

	const companyTypes = [
		{
			label: 'Buyer',
			value: 'customer',
			icon: 'Bus',
		},
		{
			label: 'Seller',
			value: 'producer',
			icon: 'Bus',
		},
		{
			label: 'Carrier',
			value: 'transporter',
			icon: 'Bus',
		},
	];

	const getCompanies = (types: string[]) => {
		return types?.map((type) => {
			return companyTypes?.find((opt) => opt.value === type);
		});
	};

	// TODO: This Selector change doesn't work
	const onChangeCompany = (options: any) => {
		const newCompanyTypes = options?.map((item: any) => item.value);
		const updatedCompanyTypes = [
			...new Set([...company.companyTypes, ...newCompanyTypes]),
		];
		formik.setFieldValue('companyTypes', updatedCompanyTypes);
	};

	const onSelectLocation = (location: Geo) => {
		formik.setFieldValue('registryAddress', location.label);
		setLocationResults(false);
	};

	const onSelectBillingLocation = (location: Geo) => {
		formik.setFieldValue('billingAddress', location.label);
		setBillingLocationResults(false);
	};

	useEffect(() => {
		if (billingAddressCheckbox) {
			formik.setFieldValue('billingAddress', formik.values.registryAddress);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billingAddressCheckbox, formik.values.registryAddress]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		formik.handleChange(e);

		const fieldName = e.target.name;
		const initialValue =
			formik.initialValues[fieldName as keyof typeof formik.initialValues];
		const currentValue = e.target.value;

		if (initialValue !== currentValue) {
			setFormEdited(true);
		}
	};

	const changePhone = (key: string, value: string) => {
		formik?.setFieldValue(key, value);

		const initialPhoneNumber = formik.initialValues.primaryPhoneNumber;
		if (initialPhoneNumber !== value) {
			setFormEdited(true);
		}
	};

	const handleOpenModal = (fieldType: 'companyName' | 'primaryEmail') => {
		setActiveField({
			type: fieldType,
			value:
				fieldType === 'companyName' ? company?.name! : company?.contact?.email!,
		});
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setActiveField({
			type: null,
			value: '',
		});
	};

	const registryRef = useDetectClickOutside({
		onTriggered: () => setLocationResults(false),
	});
	const billingRef = useDetectClickOutside({
		onTriggered: () => setBillingLocationResults(false),
	});

	const triggerSubmit = () => {
		formRef.current?.dispatchEvent(
			new Event('submit', { cancelable: true, bubbles: true }),
		);
	};

	const triggerReset = () => {
		formRef.current?.reset();
		formik.resetForm();
	};

	return (
		<>
			<div className={`flex flex-col ${formEdited ? 'mb-[32px]' : ''}`}>
				<div className="flex flex-col gap-[14px] mb-[24px]">
					<SypacText variant="heading-4">
						<p className="text-xl text-gray-80">
							<T keyName="userSettings.companyProfile">Company profile</T>
						</p>
					</SypacText>

					<SypacText variant="heading-4">
						<p className="text-base text-gray-40">
							<T keyName="userSettings.updateYourCompanyDetails">
								Update your company logo and details here.
							</T>
						</p>
					</SypacText>
				</div>

				<form
					ref={formRef}
					onSubmit={formik.handleSubmit}
					onReset={formik.handleReset}
					className="flex flex-col gap-6"
				>
					<div className="w-[532px] flex flex-col p-3 gap-6 border border-solid border-gray-10 rounded-10 bg-alabaster">
						<div
							style={{
								backgroundImage: `url(${file?.file.url || company?.logoUrl})`,
							}}
							onDragEnter={handleDrag}
							onDragLeave={handleDrag}
							onDragOver={handleDrag}
							onDrop={handleDrop}
							className={`w-[120px] h-[120px] flex border border-solid border-gray-10 rounded-full cursor-pointer box-border transition hover:border-gray-50 ${
								file?.file.url || company?.logoUrl
									? 'bg-no-repeat bg-cover bg-center'
									: 'bg-white'
							}`}
						>
							<input
								type="file"
								id="input-file-upload"
								className="hidden"
								multiple={true}
								accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
								onChange={handleChange}
							/>
							<label
								htmlFor="input-file-upload"
								className="w-full flex flex-col gap-[10px] justify-center items-center cursor-pointer"
							>
								{!company?.logoUrl && !file?.file.url ? (
									<>
										<span className="flex scale-[1.2]">
											<PhotoFrame color="#454545" />
										</span>
										<SypacText variant="overline-normal-large">
											<p className="text-sm text-gray-80 text-center">
												<T keyName="settingMyProfile.uploadPhoto">
													Upload photo
												</T>
											</p>
										</SypacText>
									</>
								) : null}
							</label>
						</div>

						<div className="flex flex-col gap-3">
							<>
								<SypacInput
									error={
										!!(formik.touched.companyName && formik.errors.companyName)
									}
								>
									<SypacText
										className="mb-[10px]"
										variant="overline-normal-large"
									>
										<p>
											<T keyName="userSettings.companyName">Company Name</T>
										</p>
									</SypacText>
									<input
										readOnly={true}
										className="w-full py-[11px] pl-3 border border-gray-10 rounded-lg placeholder:text-gray-22 bg-gray-1 pointer-events-none"
										name="companyName"
										type="text"
										placeholder={t(
											'userSettings.enterCompanyName',
											'Enter Company Name',
										)}
										value={formik?.values.companyName}
										onChange={handleInputChange}
									/>
									{formik.touched.companyName && formik.errors.companyName ? (
										<span className="bottom-helper">
											{formik.errors.companyName}
										</span>
									) : null}
								</SypacInput>
								<SypacButton
									variant="secondary"
									size="small"
									className="self-end"
								>
									<button
										type="button"
										className="p-0 border-0 bg-transparent group"
										onClick={() => handleOpenModal('companyName')}
									>
										<span className="flex scale-[0.8]">
											<EditPenIcon color="#5682FA" />
										</span>
										<SypacText
											variant="body-regular-medium"
											className="ml-1 mt-[2px]"
										>
											<p className="text-cornflower-blue transition group-hover:text-cornflower-blue/70">
												<T keyName="userSettings.changeRequest">
													Change request
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</>
							<SypacInput
								error={
									!!(
										formik.touched.companyRegistrationNumber &&
										formik.errors.companyRegistrationNumber
									)
								}
							>
								<SypacText
									className="mb-[10px]"
									variant="overline-normal-large"
								>
									<p>
										<T keyName="userSettings.companyRegistrationNumber">
											Company registration number
										</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<input
									readOnly={true}
									className="w-full py-[11px] pl-3 border border-gray-10 rounded-lg placeholder:text-gray-22 bg-gray-1 pointer-events-none"
									name="companyRegistrationNumber"
									type="text"
									placeholder={t(
										'userSettings.enterCompanyRegistrationNumber',
										'Enter Company registration number',
									)}
									value={formik?.values.companyRegistrationNumber}
									onChange={handleInputChange}
								/>
								{formik.touched.companyRegistrationNumber &&
								formik.errors.companyRegistrationNumber ? (
									<span className="bottom-helper">
										{formik.errors.companyRegistrationNumber}
									</span>
								) : null}
							</SypacInput>
							<SypacInput
								error={!!(formik.touched.vatNumber && formik.errors.vatNumber)}
							>
								<SypacText
									className="mb-[10px]"
									variant="overline-normal-large"
								>
									<p>
										<T keyName="userSettings.vatNumber">VAT Number</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<input
									readOnly={true}
									className="w-full py-[11px] pl-3 border border-gray-10 rounded-lg placeholder:text-gray-22 bg-gray-1 pointer-events-none"
									name="vatNumber"
									type="text"
									placeholder={t(
										'userSettings.enterVatNumber',
										'Enter VAT Number',
									)}
									value={formik?.values.vatNumber}
									onChange={handleInputChange}
								/>
								{formik.touched.vatNumber && formik.errors.vatNumber ? (
									<span className="bottom-helper">
										{formik.errors.vatNumber}
									</span>
								) : null}
							</SypacInput>
							<SypacInput
								error={!!(formik.touched.country && formik.errors.country)}
							>
								<SypacText
									className="mb-[10px]"
									variant="overline-normal-large"
								>
									<p>
										<T keyName="userSettings.country">Country</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<Select
									isDisabled={true}
									placeholder={t('userSettings.country', 'Country')}
									name="country"
									onChange={setCountry}
									onBlur={formik.handleBlur}
									options={COUNTRIES}
									menuPlacement={'auto'}
									components={{
										Option: (options) =>
											DropDownOption(options, formik.values.country.value),
									}}
									classNamePrefix="dropdown"
									value={
										formik.values.country
											? {
													value:
														selectedCountry.value ||
														formik.values.country.value,
													label: (
														<div className="w-full min-w-full flex gap-[10px] h-[30px] items-center">
															<Flag
																className="object-cover border border-solid border-gray-10 rounded"
																code={
																	selectedCountry.value ||
																	formik.values.country.value
																}
																width={22}
																height={16}
															/>
															<SypacText variant="body-regular-medium">
																<p className="text-gray-80 truncate">
																	{selectedCountry.label ||
																		formik.values.country.label}
																</p>
															</SypacText>
														</div>
													),
											  }
											: ''
									}
									styles={
										formik.touched.country && formik.errors.country
											? {
													...settingsCountrySelect,
													...settingsCountrySelectError,
											  }
											: settingsCountrySelect
									}
								/>
							</SypacInput>
							<SypacInput
								error={!!(formik.touched.country && formik.errors.country)}
							>
								<SypacText
									className="mb-[10px]"
									variant="overline-normal-large"
								>
									<p>
										<T keyName="userSettings.companyType">Company type</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<Select
									isDisabled={true}
									placeholder={t(
										'companyInformationEditable.companyTypes',
										'Company types',
									)}
									name="companyTypes"
									onChange={onChangeCompany}
									onBlur={formik.handleBlur}
									options={getCompanies(availableCompanyTypes)}
									menuPlacement={'auto'}
									classNamePrefix="dropdown"
									value={formik.values.companyTypes.map((type) => ({
										value: type,
										label: (
											<div className="w-full min-w-full flex gap-[6px] h-[30px] items-center">
												<SypacIcon
													icon-name={
														companyTypes.find((opt) => opt.value === type)
															?.icon || ''
													}
													size="custom"
													width="30px"
													height="30px"
													className="text-gray-80"
												/>
												<SypacText variant="body-regular-medium">
													<p className="text-gray-80 truncate">
														{companyTypes.find((opt) => opt.value === type)
															?.label || ''}
													</p>
												</SypacText>
											</div>
										),
									}))}
									styles={
										formik.touched.companyTypes && formik.errors.companyTypes
											? {
													...settingsCountrySelect,
													...settingsCountrySelectError,
											  }
											: settingsCountrySelect
									}
								/>
								{formik.touched.companyTypes && formik.errors.companyTypes ? (
									<span className="bottom-helper">
										{formik.errors.companyTypes}
									</span>
								) : null}
							</SypacInput>
							<SypacInput
								error={
									!!(formik.touched.established && formik.errors.established)
								}
							>
								<SypacText
									className="mb-[10px]"
									variant="overline-normal-large"
								>
									<p>
										<T keyName="companyInformationEditable.established">
											Established
										</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<SypacDatepicker
									name="established"
									placeholder={t(
										'companyInformationEditable.enterEstablished',
										'Enter established date',
									)}
									min="1950-01-01"
									max={dayjs().toString()}
									value={formik.values.established?.toString() || undefined}
									onCallback={({ detail }: any) =>
										formik.setFieldValue('established', detail)
									}
								/>
								{formik.touched.established && formik.errors.established ? (
									<span className="bottom-helper">
										{formik.errors.established}
									</span>
								) : null}
							</SypacInput>
						</div>
					</div>

					<div className="w-[532px] flex flex-col p-3 gap-6 border border-solid border-gray-10 rounded-10 bg-alabaster">
						<div className="flex flex-col gap-3">
							<div ref={registryRef} className="relative">
								<SypacInput
									error={
										!!(
											formik.touched.registryAddress &&
											formik.errors.registryAddress
										)
									}
								>
									<SypacText
										className="mb-[10px]"
										variant="overline-normal-large"
									>
										<p>
											<T keyName="companyInformationEditable.registryAddress">
												Registration address
											</T>
										</p>
									</SypacText>
									<input
										readOnly={true}
										autoComplete="off"
										type="text"
										name="registryAddress"
										// className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 transition ${
										// 	locationResults
										// 		? 'border-cornflower-blue'
										// 		: 'hover:border-gray-30'
										// }`}
										className="w-full py-[11px] pl-3 border border-gray-10 rounded-lg placeholder:text-gray-22 bg-gray-1 pointer-events-none"
										placeholder={t(
											'companyInformationEditable.enterRegistryAddress',
											'Enter Registration address',
										)}
										onBlur={formik.handleBlur}
										value={formik?.values.registryAddress}
										onFocus={() => setLocationResults(true)}
										onChange={(event) => {
											formik.handleChange(event);

											const initialValue = formik.initialValues.registryAddress;
											const currentValue = event.target.value;

											if (initialValue !== currentValue) {
												setFormEdited(true);
											}

											setSearchLocation(event.target.value);
										}}
									/>
									{formik.touched.registryAddress &&
									formik.errors.registryAddress ? (
										<span className="bottom-helper">
											{formik.errors.registryAddress}
										</span>
									) : null}
								</SypacInput>

								<div
									className={`absolute top-[78px] w-[303px] flex-col shadow-dropdown border-[1px] border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
										locationResults ? 'flex' : 'hidden'
									}`}
								>
									{locations?.length ? (
										<div className="flex flex-col w-full p-3 overflow-y-scroll">
											{locations.map((location: Geo) => (
												<div
													key={location.locationId}
													className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px]"
													onClick={() => onSelectLocation(location)}
												>
													<div className="mx-5">
														<Flag
															className="rounded-[3px]"
															code={location.countryCode.toLocaleUpperCase()}
															width={22}
															height={16}
														/>
													</div>
													<SypacText
														variant="body-normal-medium"
														className="my-[15px]"
													>
														<p>
															{location.address?.city},{' '}
															{location.address?.street} str.,
															{location.address?.houseNumber
																? ` ${location.address?.houseNumber},`
																: ''}{' '}
															Zip {location.address?.postalCode}
														</p>
													</SypacText>
												</div>
											))}
										</div>
									) : null}
								</div>
							</div>
							<div ref={billingRef} className="relative">
								<SypacInput
									error={
										!!(
											formik.touched.billingAddress &&
											formik.errors.billingAddress
										)
									}
									disabled={billingAddressCheckbox}
								>
									<SypacText
										className="mb-[10px]"
										variant="overline-normal-large"
									>
										<p>
											<T keyName="companyInformationEditable.billingAddress">
												Billing Address
											</T>
										</p>
									</SypacText>
									<input
										readOnly={true}
										autoComplete="off"
										type="text"
										name="billingAddress"
										// className={`w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22 transition ${
										// 	billingLocationResults
										// 		? 'border-cornflower-blue'
										// 		: 'hover:border-gray-30'
										// }`}
										className="w-full py-[11px] pl-3 border border-gray-10 rounded-lg placeholder:text-gray-22 bg-gray-1 pointer-events-none"
										placeholder={t(
											'companyInformationEditable.enterBillingAddress',
											'Enter Billing Address',
										)}
										onBlur={formik.handleBlur}
										value={formik?.values.billingAddress}
										onFocus={() => setBillingLocationResults(true)}
										onChange={(event) => {
											formik.handleChange(event);

											const initialValue = formik.initialValues.billingAddress;
											const currentValue = event.target.value;

											if (initialValue !== currentValue) {
												setFormEdited(true);
											}

											setSearchBillingLocation(event.target.value);
										}}
									/>
									{formik.touched.billingAddress &&
									formik.errors.billingAddress ? (
										<span className="bottom-helper">
											{formik.errors.billingAddress}
										</span>
									) : null}
								</SypacInput>

								<div
									className={`absolute top-[78px] w-[303px] flex-col shadow-dropdown border-[1px] border-gray-10 rounded-lg max-h-72 overflow-hidden bg-white z-50 ${
										billingLocationResults ? 'flex' : 'hidden'
									}`}
								>
									{billingLocations?.length ? (
										<div className="flex flex-col w-full p-3 overflow-y-scroll">
											{billingLocations.map((location: Geo) => (
												<div
													key={location.locationId}
													className="flex items-center hover:bg-gray-10-opacity-50 rounded-md cursor-pointer mr-[9px]"
													onClick={() => onSelectBillingLocation(location)}
												>
													<div className="mx-5">
														<Flag
															className="rounded-[3px]"
															code={location.countryCode.toLocaleUpperCase()}
															width={22}
															height={16}
														/>
													</div>
													<SypacText
														variant="body-normal-medium"
														className="my-[15px]"
													>
														<p>
															{location.address?.city},{' '}
															{location.address?.street} str.,
															{location.address?.houseNumber
																? ` ${location.address?.houseNumber},`
																: ''}{' '}
															Zip {location.address?.postalCode}
														</p>
													</SypacText>
												</div>
											))}
										</div>
									) : null}
								</div>
							</div>
							<div className="flex gap-3 items-center self-end">
								<SypacCheckbox size="md">
									<input
										name="billingAddressCheckbox"
										checked={billingAddressCheckbox}
										// onChange={setBillingAddressSame}
										onChange={() => {}}
										type="checkbox"
										className="cursor-pointer"
									/>
								</SypacCheckbox>
								<SypacText variant="overline-normal-large">
									<p className="text-sm text-gray-80 mt-[2px]">
										<T keyName="companyDetails.billingAddressSame">
											Billing address the same
										</T>
									</p>
								</SypacText>
							</div>
							<SypacInput
								error={
									!!(
										formik.touched.companyWebSite &&
										formik.errors.companyWebSite
									)
								}
							>
								<SypacText
									className="mb-[10px]"
									variant="overline-normal-large"
								>
									<p>
										<T keyName="userSettings.webSite">Web site</T>
									</p>
								</SypacText>
								<input
									className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
									name="companyWebSite"
									type="text"
									placeholder={t('userSettings.enterWebSite', 'Enter Web site')}
									value={formik?.values.companyWebSite}
									onChange={handleInputChange}
								/>
								{formik.touched.companyWebSite &&
								formik.errors.companyWebSite ? (
									<span className="bottom-helper">
										{formik.errors.companyWebSite}
									</span>
								) : null}
							</SypacInput>
							<>
								<SypacInput>
									<SypacText
										className="mb-[10px]"
										variant="overline-normal-large"
									>
										<p>
											<T keyName="userSettings.primaryEmailAddress">
												Primary email address
											</T>
										</p>
									</SypacText>
									<input
										readOnly={true}
										className="w-full py-[11px] pl-3 border border-gray-10 rounded-lg placeholder:text-gray-22 bg-gray-1 pointer-events-none"
										name="primaryEmail"
										type="text"
										placeholder={t(
											'userCard.enterEmailAddress',
											'Enter email address',
										)}
										value={formik?.values.primaryEmail}
									/>
								</SypacInput>
								<SypacButton
									variant="secondary"
									size="small"
									className="self-end"
								>
									<button
										type="button"
										className="p-0 border-0 bg-transparent group"
										onClick={() => handleOpenModal('primaryEmail')}
									>
										<span className="flex scale-[0.8]">
											<EditPenIcon color="#5682FA" />
										</span>
										<SypacText
											variant="body-regular-medium"
											className="ml-1 mt-[2px]"
										>
											<p className="text-cornflower-blue transition group-hover:text-cornflower-blue/70">
												<T keyName="userSettings.changeRequest">
													Change request
												</T>
											</p>
										</SypacText>
									</button>
								</SypacButton>
							</>
							<SypacInput
								error={
									!!(
										formik.touched.primaryPhoneNumber &&
										formik.errors.primaryPhoneNumber
									)
								}
							>
								<SypacText
									className="mb-[10px]"
									variant="overline-normal-large"
								>
									<p>
										<T keyName="userSettings.primaryPhoneNumber">
											Primary phone number
										</T>{' '}
										<span className="text-red">*</span>
									</p>
								</SypacText>
								<PhoneInput
									containerClass="border border-solid border-gray-20 rounded-lg hover:border-gray-30 focus-within:border-cornflower-blue focus-within:hover:border-cornflower-blue"
									placeholder={t(
										'userSettings.enterPrimaryPhone',
										'Enter primary phone',
									)}
									value={formik?.values.primaryPhoneNumber}
									onChange={(phone) => changePhone('primaryPhoneNumber', phone)}
									preferredCountries={PREFERRED_PHONE_COUNTIES}
									country="pl"
								/>
								{formik.touched.primaryPhoneNumber &&
								formik.errors.primaryPhoneNumber ? (
									<span className="bottom-helper">
										{formik.errors.primaryPhoneNumber}
									</span>
								) : null}
							</SypacInput>
						</div>
					</div>
				</form>
			</div>
			{formEdited ? (
				<div className="w-[1092px] absolute bottom-0 bg-white p-4 border-0 border-t border-solid border-t-gray-10 animate-slide-up box-border z-10">
					<div className="flex gap-4">
						<SypacButton variant="secondary" size="small" className="w-full">
							<button
								type="button"
								className="w-full h-[44px] flex items-center justify-center rounded-lg transition"
								onClick={triggerReset}
							>
								<SypacText variant="body-regular-medium">
									<p>
										<T keyName="modalConfirmationCode.cancel">Cancel</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>

						<SypacButton variant="primary" size="small" className="w-full">
							<button
								type="button"
								disabled={formik.isSubmitting}
								className="w-full py-2.75 rounded-lg"
								onClick={triggerSubmit}
							>
								<SypacText
									variant="body-regular-medium"
									className="flex items-center gap-4"
								>
									<p className="text-white">
										<T keyName="modalManageProduct.save">Save</T>
									</p>
									{formik.isSubmitting ? <LoadingSpinner /> : null}
								</SypacText>
							</button>
						</SypacButton>
					</div>
				</div>
			) : null}
			<ModalChangeRequest
				isOpen={openModal}
				onClose={handleCloseModal}
				activeField={activeField}
				company={company}
			/>
		</>
	);
};

export default SettingCompanyProfile;
