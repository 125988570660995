export const ADMIN_STEPS_SIMPLE = [
	'reviewOrder',
	'pendingConfirmationFromCarriers',
	'pendingTruckAllocation',
	'pendingStartTrip',
	'pendingArrivalLoadingPlace',
	'pendingLoadingOfGoods',
	'pendingArrivalAtTheDestination',
	'pendingUnloadingOfGoods',
	'pendingProofOfDeliveryDocuments',
	'proofOfDeliveryDocumentsConfirmed',
	'pendingPayment',
	'orderPaid',
];
export const ADMIN_STEPS_SIMPLE_EXCLUDED = [
	'reviewAndAssignThisOrder',
	'awaitingConfirmationFromSeller',
	'pendingTruckArrivalConfirmation',
	'pendingLoadingConfirmation',
	'pendingPaymentFromBuyer',
	'orderCompleted',
];
export const ADMIN_STEPS_MULTIPLE = [
	'reviewAndAssignThisOrderToYou',
	'awaitingTransportPartners',
	'deliveryExecutionInProgress',
	'pendingPayment',
	'orderCompleted',
];
export const ADMIN_STEPS_MULTIPLE_EXCLUDED = [
	'reviewAndAssignThisOrderToYou',
	'awaitingConfirmationFromSeller',
	'pickUpExecutionInProgress',
	'pendingPaymentFromBuyer',
	'orderCompleted',
];
export const ADMIN_SUB_STATUS_SIMPLE: string[] = [
	'singleOrder',
	'orderAcceptedAndMatched',
	'transportOrderConfirmed',
	'truckAllocated',
	'driverStartedTheTrip',
	'theDriverArrivedLoadingPlace',
	'theDriverHasBeenLoaded',
	'theDriverArrivedAtTheDestination',
	'theDriverHasBeenUnloaded',
	'unloadingSuccessful',
];
export const ADMIN_SUB_STATUS_SIMPLE_EXCLUDED: string[] = [
	'selfServiceOrder',
	'orderHasBeenAssignedToYou',
	'orderConfirmedFromSeller',
	'truckArrivalConfirmed',
	'loadingConfirmed',
	'paymentConfirmedFromBuyer',
];
export const ADMIN_SUB_STATUS_MULTIPLE: string[] = [
	'newMultipleDeliveryOrder',
	'orderHasBeenAssignedToYou',
	'',
	'orderDeliveryCompleted',
	'paymentConfirmed',
];
export const ADMIN_SUB_STATUS_MULTIPLE_EXCLUDED: string[] = [
	'multipleOrder',
	'ordersConfirmedBySeller',
	'trucksArrivedAtTheLoadingPlace',
	'loadingConfirmed',
	'paymentConfirmedFromBuyer',
];
export const TRANSPORTER_STEPS: string[] = [
	'carrierPendingTruckAllocation',
	'allocateATruck',
	'carrierPendingTruckAllocation',
	'awaitingDriverOrderConfirmation',
	'pendingStartTrip',
	'pendingArrivalAtTheLoadingPlace',
	'pendingLoadingOfGoods',
	'pendingArrivalAtTheDestination',
	'pendingUnloadingOfGoods',
	'pendingProofOfDeliveryDocuments',
	'carrierPendingPayment',
	'orderPaid',
];
export const TRANSPORTER_OFFER_STEPS: string[] = [
	'assignTheOrder',
	'confirmSelectedQuantity',
];
export const TRANSPORTER_SUB_STATUS: string[] = [
	'orderAccepted',
	'orderAcceptedTwo',
	'orderAccepted',
	'carrierTruckAllocated',
	'driverConfirmedTheOrder',
	'driverStartedTheTrip',
	'theDriverArrivedAtTheLoadingPlace',
	'cargoSuccessfullyLoaded',
	'theDriverArrivedAtTheUnloadingPlace',
	'theDriverHasBeenUnloaded',
	'proofOfDeliveryDocumentsUploaded',
	'thankYouForCooperation',
];
export const TRANSPORTER_OFFER_SUB_STATUS: string[] = [
	'newTransportOrderAvailable',
	'fulfillmentCapacitySelected',
];
export const CUSTOMER_STEPS_SIMPLE: string[] = [
	'simpleOrderIsBeingReviewed',
	'yourOrderIsBeingPrepared',
	'pendingArrivalAtDestination',
	'pendingUnloading',
	'customerPendingYourPayment',
	'orderCompleted',
];
export const CUSTOMER_STEPS_MULTIPLE: string[] = [
	'simpleOrderIsBeingReviewed',
	'yourOrderIsBeingPrepared',
	'yourOrderIsBeingShipped',
	'pendingPayment',
	'orderCompleted',
];
export const CUSTOMER_STEPS_SIMPLE_EXCLUDED: string[] = [
	'yourOrderIsBeingReviewed',
	'headToTheLoadingPlace',
	'pendingLoadingConfirmation',
	'pendingYourPayment',
	'orderCompleted',
];
export const CUSTOMER_STEPS_MULTIPLE_EXCLUDED: string[] = [
	'yourOrderIsBeingReviewed',
	'excludedHeadToTheLoadingPlace',
	'excludedPendingLoadingConfirmation',
	'excludedPendingYourPayment',
	'orderCompleted',
];
export const CUSTOMER_SUB_STATUS_SIMPLE: string[] = [
	'simpleOrderPlaced',
	'yourOrderHasBeenAccepted',
	'loadingConfirmed',
	'truckArrivedAtTheDestination',
	'unloadingComplete',
	'paymentConfirmed',
];
export const CUSTOMER_SUB_STATUS_MULTIPLE: string[] = [
	'orderHasBeenCreated',
	'orderConfirmed',
	'',
	'loadingOfGoodsCompleted',
	'orderPaid',
];
export const CUSTOMER_SUB_STATUS_SIMPLE_EXCLUDED: string[] = [
	'orderPlaced',
	'yourOrderHasBeenAcceptedSimple',
	'truckArrivedLoadingPlace',
	'clientLoadingConfirmed',
	'paymentConfirmed',
];
export const CUSTOMER_SUB_STATUS_MULTIPLE_EXCLUDED: string[] = [
	'orderPlaced',
	'yourOrderHasBeenAccepted',
	'yourOrderHasBeenAccepted',
	'loadingOfGoodsCompleted',
	'orderPaid',
];
export const PRODUCER_STEPS: string[] = [
	'assignTheOrderProducer',
	'pendingDriverArrival',
	'producerPendingLoadingOfGoods',
	'producerPendingPayment',
	'producerOrderCompleted',
];
export const PRODUCER_STEPS_EXCLUDED: string[] = [
	'reviewAndAssignThisOrderToYou',
	'confirmTruckArrival',
	'uploadProofOfLoading',
	'confirmLoading',
	'pendingPayment',
	'producerOrderCompleted',
];
export const PRODUCER_SUB_STATUS: string[] = [
	'newOrderAvailable',
	'orderAssigned',
	'driverArrivedAtTheLoadingPlace',
	'driverHasBeenLoaded',
	'paymentConfirmed',
];
export const PRODUCER_SUB_STATUS_EXCLUDED: string[] = [
	'newOrderAvailable',
	'orderAssignedExcluded',
	'truckArrivalConfirmed',
	'truckArrivalConfirmed',
	'loadingConfirmed',
	'paymentConfirmed',
];
export const DRIVER_ISSUE = {
	status: 'orderPausedDriverReportedIssue',
	subStatus: 'driverReportedAnIssue',
	subStatusResolved: 'issueResolvedDeliveryInProgress',
};
