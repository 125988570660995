import React, { useMemo } from 'react';
import { SypacText } from '@sypac/component-library-react';
import { T } from '@tolgee/react';
import CardDownArrow from '../../../assets/CardDownArrow';
import Wallet from '../../../assets/Wallet';
import { OrderTarget } from '../../OrdersTable/interfaces/OrderStatus.interface';

interface NoResultsProps {
	target: string;
	currentTab: string;
}

const NoResults: React.FC<NoResultsProps> = ({ target, currentTab }) => {
	const emptyText = useMemo(() => {
		const isCustomer = target === OrderTarget.customer;
		const tabTextMap = {
			all: isCustomer ? 'noWithdrawalsMadeYetCustomer' : 'noWithdrawalsMadeYet',
			pending: isCustomer
				? 'noPendingPaymentsAtTheMomentCustomer'
				: 'noPendingPaymentsAtTheMoment',
			overdue: isCustomer
				? 'noPaymentsAreCurrentlyOverdueCustomer'
				: 'noPaymentsAreCurrentlyOverdue',
			paid: isCustomer
				? 'yourAccountHasNoPaidOrdersCurrentlyCustomer'
				: 'yourAccountHasNoPaidOrdersCurrently',
		};
		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab, target]);
	const emptySubText = useMemo(() => {
		const isCustomer = target === OrderTarget.customer;
		const tabTextMap = {
			all: isCustomer
				? 'noWithdrawalsDescriptionCustomer'
				: 'noWithdrawalsDescription',
			pending: isCustomer
				? 'noPendingPaymentsDescriptionCustomer'
				: 'noPendingPaymentsDescription',
		} as const;

		return tabTextMap[currentTab as keyof typeof tabTextMap];
	}, [currentTab, target]);

	return (
		<div className="h-full flex flex-col gap-[60px] justify-center items-center">
			{target === OrderTarget.customer ? <Wallet /> : <CardDownArrow />}

			<div className="flex flex-col gap-6 items-center">
				<SypacText variant="body-regular-medium">
					<p className="text-xl leading-[26px] text-gray-80">
						<T keyName={`paymentsTable.${emptyText}`}>{emptyText}</T>
					</p>
				</SypacText>
				{emptySubText ? (
					<SypacText variant="body-regular-medium">
						<p
							className="w-[340px] text-sm text-gray-40 text-center"
							style={{
								whiteSpace: 'break-spaces',
							}}
						>
							<T keyName={`paymentsTable.${emptySubText}`}>{emptySubText}</T>
						</p>
					</SypacText>
				) : null}
			</div>
		</div>
	);
};

export default NoResults;
