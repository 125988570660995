import React, { memo, useState } from 'react';
import UserCardGroup from '../../../components/UserCard/UserCardGroup';
import { useGetCompanyUsers } from '../../../hooks/use-get-company-users';
import { CardUser } from '../../../components/UserCard/UserCard.interface';
import { MutatingDots } from 'react-loader-spinner';
import { UsersServices } from '../../../services/users.services';

interface Props {
	companyId: number;
}

export const CompanyUsers: React.FC<Props> = memo(({ companyId }: Props) => {
	// TODO implement full user get in BE
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [refresh, setRefresh] = useState(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [response, _, isLoading] = useGetCompanyUsers({ companyId, refresh });

	const saveProfile = async (user: CardUser) => {
		try {
			await UsersServices.updateProfile(user.uid, {
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.primaryEmail,
				secondaryEmail: user.secondaryEmail,
				phoneNumber: {
					phoneNumber: user.primaryPhoneNumber,
					isViber: user.primaryIsViber,
					isTelegram: user.primaryIsTelegram,
					isWhatsapp: user.primaryIsWhatsUp,
				},
				secondaryPhoneNumber: {
					phoneNumber: user.secondaryPhoneNumber,
					isViber: user.secondaryIsViber,
					isTelegram: user.secondaryIsTelegram,
					isWhatsapp: user.secondaryIsWhatsUp,
				},
			});
			setRefresh(refresh + 1);
		} catch (e) {}
	};
	return !isLoading ? (
		<div className="mx-10">
			<UserCardGroup
				saveProfile={saveProfile}
				data={
					response?.items.map((user) => {
						const fullName = [user.firstName || '', user.lastName || '']
							.join(' ')
							.trim();
						return {
							uid: user.uid,
							firstName: user.firstName,
							lastName: user.lastName,
							fullName: fullName || user.name,
							role: user.role,
							avatarUrl: user.avatarUrl,
							lastOnline: 'unknown',
							primaryEmail: user.email,
							secondaryEmail: user.secondaryEmail,
							primaryPhoneNumber: user.phoneNumber?.phoneNumber,
							secondaryPhoneNumber: user.secondaryPhoneNumber?.phoneNumber,
							primaryIsViber: user.phoneNumber?.isViber,
							primaryIsWhatsUp: user.phoneNumber?.isWhatsapp,
							primaryIsTelegram: user.phoneNumber?.isTelegram,
							secondaryIsViber: user.secondaryPhoneNumber?.isViber,
							secondaryIsWhatsUp: user.secondaryPhoneNumber?.isWhatsapp,
							secondaryIsTelegram: user.secondaryPhoneNumber?.isTelegram,
						} as CardUser;
					}) || []
				}
			/>
		</div>
	) : (
		<div className="flex w-full h-full items-center justify-center">
			<MutatingDots
				height="100"
				width="100"
				color="#7693F4"
				secondaryColor="#494C83"
				radius="12.5"
				ariaLabel="mutating-dots-loading"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
			/>
		</div>
	);
});
