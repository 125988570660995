import {
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import React, { useState } from 'react';
import { OrderInterface } from '../../../../../../components/OrdersTable/interfaces/Order.interface';
import {
	OrdersService,
	ProducerOrderAction,
} from '../../../../../../services/orders.services';
import { OrderTarget } from '../../../../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { T } from '@tolgee/react';
import OrderDetailsStepper from '../../../../../../components/OrderDetailsStepper/OrderDetailsStepper';
import PickUpDeliveryDetails from '../../../../../Admin/OrderDetails/components/AdditionalDetails/PickUp&DeliveryDetails';
import BaseOrderDetails from '../../../../../../components/BaseOrderDetails/BaseOrderDetails';
import ModalLoadDetails from '../../AdditionalDetails/ModalLoadDetails';
import Emitter, { EventType } from '../../../../../../services/events';
import { UploadResponse } from '../../../../../../services/file.service';
import PickUpSchedule from '../../AdditionalDetails/PickUpSchedule';
import { ProducerOrderProps } from '../../../producerOrder.interface';

interface LoadingConfirmationData {
	wight: string;
	info: string;
	files: UploadResponse[];
}

const ConfirmPickUp: React.FC<ProducerOrderProps> = (props) => {
	const { selectedOrder, setOrder } = props;
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [stepperMargin, setStepperMargin] = useState<number>(0);
	const [modalDone, setModalDone] = useState<boolean>(false);
	const [modalData, setModalData] = useState<any>({});

	const handleClick = async () => {
		try {
			const { wight, files } = modalData;
			await OrdersService.actionOrder(
				{ wight: +wight, documents: files.map((r: any) => r.name) },
				OrderTarget.producer,
				ProducerOrderAction.CONFIRM_LOADING_GOODS,
				selectedOrder?.id!,
			);
			const { data } = await OrdersService.getOrder(
				selectedOrder?.id!,
				OrderTarget.producer,
			);
			setOrder?.(data as unknown as OrderInterface);
			Emitter.emit(EventType.ORDER_LIST_REFRESH, {
				id: selectedOrder?.id.toString(),
				recipientId: '',
				groupId: selectedOrder?.id.toString(),
				category: 'update_order',
			});
		} catch (e) {}
	};

	const handleModalData = ({ wight, info, files }: LoadingConfirmationData) => {
		setModalData({
			wight,
			files,
		});
		setModalDone(true);
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleWidthChange = (value: number) => {
		setStepperMargin(value);
	};

	return (
		<div className={`${!selectedOrder?.deliveryIncluded ? 'mb-[248px]' : ''}`}>
			{modalDone ? (
				<>
					<div style={{ marginBottom: `${stepperMargin}px` }}>
						<OrderDetailsStepper
							current={4}
							simpleOrder={selectedOrder?.trucks?.length === 1}
							order={selectedOrder}
							onWidthChange={handleWidthChange}
						/>
					</div>

					<div className="px-10 pt-7 pb-3.5">
						<BaseOrderDetails
							selectedOrder={selectedOrder}
							isOpen={true}
							simpleOrder={selectedOrder?.trucks?.length === 1}
							deliveryIncluded={selectedOrder?.deliveryIncluded!}
							target={selectedOrder?.target!}
						/>
					</div>

					<div className="px-10 py-3.5">
						<PickUpDeliveryDetails
							selectedOrder={selectedOrder}
							target={selectedOrder?.target}
							isPickUp={true}
							simpleOrder={selectedOrder?.trucks?.length === 1}
						/>
					</div>

					<div className="px-10 pb-7 pt-3.5">
						<PickUpSchedule selectedOrder={selectedOrder} step={4} />
					</div>

					<SypacBox
						className="fixed bottom-0 w-[702px] h-fit bg-alabaster border border-solid border-gray-40 border-l-0 border-r-0 border-b-0 rounded-2xl shadow-order-assign"
						hover={false}
						boxStyle="solid"
						boxColor="primary"
					>
						<div className="flex flex-col justify-center items-start p-8 gap-5">
							<SypacText variant="overline-regular-large">
								<p className="text-base leading-6 tracking-tighter text-gray-40">
									<T keyName="boxConfirmPickUpStep.arrivalConfirmation">
										Loading confirmation
									</T>
								</p>
							</SypacText>

							<SypacText variant="heading-5">
								<p className="tracking-tighter text-gray-90">
									<T keyName="boxConfirmPickUpStep.confirmOrderExecution">
										Please confirm the loading of goods for this order.
									</T>
								</p>
							</SypacText>

							<SypacText variant="overline-regular-large">
								<p className="text-base leading-6 tracking-tighter text-gray-40">
									<T keyName="boxConfirmPickUpStep.onceYouConfirmOrder">
										After confirming the loading, you will no longer be able to
										edit or add information.
									</T>
								</p>
							</SypacText>

							<SypacButton variant="primary" size="large">
								<button className="w-fit transition" onClick={handleClick}>
									<p className="m-0">
										<T keyName="boxConfirmPickUpStep.confirmLoadingGoods">
											Confirm loading
										</T>
									</p>
								</button>
							</SypacButton>
						</div>
					</SypacBox>
				</>
			) : (
				<>
					<div style={{ marginBottom: `${stepperMargin}px` }}>
						<OrderDetailsStepper
							current={3}
							simpleOrder={selectedOrder?.trucks?.length === 1}
							order={selectedOrder}
							onWidthChange={handleWidthChange}
						/>
					</div>

					<div className="px-10 pt-7 pb-3.5">
						{selectedOrder?.deliveryIncluded ? (
							<PickUpSchedule selectedOrder={selectedOrder} step={3} />
						) : (
							<PickUpDeliveryDetails
								selectedOrder={selectedOrder}
								target={selectedOrder?.target}
								isPickUp={true}
								simpleOrder={selectedOrder?.trucks?.length === 1}
							/>
						)}
					</div>

					<div className="px-10 py-3.5">
						<BaseOrderDetails
							selectedOrder={selectedOrder}
							isOpen={true}
							simpleOrder={selectedOrder?.trucks?.length === 1}
							deliveryIncluded={selectedOrder?.deliveryIncluded!}
							target={selectedOrder?.target!}
						/>
					</div>

					{!selectedOrder?.deliveryIncluded ? (
						<div className="px-10 pb-7 pt-3.5">
							<PickUpSchedule selectedOrder={selectedOrder} step={3} />
						</div>
					) : null}

					{!selectedOrder?.deliveryIncluded ? (
						<SypacBox
							className="fixed bottom-0 w-[702px] h-fit bg-alabaster border border-solid border-gray-40 border-l-0 border-r-0 border-b-0 rounded-2xl shadow-order-assign"
							hover={false}
							boxStyle="solid"
							boxColor="primary"
						>
							<div className="flex flex-col justify-center items-start p-8 gap-5">
								<SypacText variant="overline-regular-large">
									<p className="text-base leading-6 tracking-tighter text-gray-40">
										<T keyName="boxConfirmPickUpStep.proofLoadingConfirmation">
											Proof of loading confirmation
										</T>
									</p>
								</SypacText>

								<SypacText variant="heading-5">
									<p className="tracking-tighter text-gray-90">
										<T keyName="boxConfirmPickUpStep.pleaseConfirmLoading">
											Please confirm loading of goods
										</T>
									</p>
								</SypacText>

								<SypacText variant="overline-regular-large">
									<p className="text-base leading-6 tracking-tighter text-gray-40">
										<T keyName="boxConfirmPickUpStep.addLoadedAuantityAndProof">
											Add loaded quantity and proof of loading documents.
										</T>
									</p>
								</SypacText>

								<SypacButton variant="primary" size="large">
									<button className="w-fit transition" onClick={openModal}>
										<p className="m-0">
											<T keyName="boxConfirmPickUpStep.uploadDocuments">
												Upload documents
											</T>
										</p>
									</button>
								</SypacButton>
							</div>
						</SypacBox>
					) : null}
				</>
			)}
			<ModalLoadDetails
				isOpen={isModalOpen}
				onClose={closeModal}
				selectedOrder={selectedOrder}
				handleClick={handleModalData}
			/>
		</div>
	);
};

export default ConfirmPickUp;
