import React, {
	createContext,
	useState,
	useMemo,
	Dispatch,
	SetStateAction,
	MutableRefObject,
} from 'react';

interface ProductContextProps {
	product: ProductProps | undefined;
	setProduct: (product: ProductProps | undefined) => void;
	additional: AdditionalProps;
	setAdditional: Dispatch<SetStateAction<AdditionalProps>>;
}

interface AdditionalProps {
	modalRefs: MutableRefObject<HTMLDivElement | null>[];
}

export interface ProductProps {
	name: string;
	categoryId: string;
	productGroup: string;
	photoUrl: string;
	countryCode: string;
	size: string;
	type: string;
	unit: string;
	translations: {
		language: string;
		name: string;
	}[];
}

export const ProductContext = createContext<ProductContextProps>({
	product: undefined,
	setProduct: () => null,
	additional: {
		modalRefs: [],
	},
	setAdditional: () => {},
});

const ProductContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const [product, setProduct] = useState<ProductProps | undefined>();
	const [additional, setAdditional] = useState<AdditionalProps>({
		modalRefs: [],
	});

	const value = useMemo<ProductContextProps>(
		() => ({
			product,
			setProduct,
			additional,
			setAdditional,
		}),
		[product, additional],
	);

	return (
		<ProductContext.Provider value={value}>{children}</ProductContext.Provider>
	);
};

export default ProductContextProvider;
