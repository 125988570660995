import { IconInterface } from '../interfaces/icon.interface';

export const PhotoFrame = (props: IconInterface) => {
	const { color = '#8B8B8B' } = props;

	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 9C1 5.22876 1 3.34315 2.17157 2.17157C3.34315 1 5.22876 1 9 1C12.7712 1 14.6569 1 15.8284 2.17157C17 3.34315 17 5.22876 17 9C17 12.7712 17 14.6569 15.8284 15.8284C14.6569 17 12.7712 17 9 17C5.22876 17 3.34315 17 2.17157 15.8284C1 14.6569 1 12.7712 1 9Z"
				stroke={color}
			/>
			<circle cx="12.2016" cy="5.79922" r="1.6" stroke={color} />
			<path
				d="M3.39844 10.0433L4.04684 9.44104C4.7877 8.75293 5.945 8.79155 6.63834 9.52753L8.75633 11.7758C9.18688 12.2328 9.8917 12.2944 10.395 11.9189C11.1202 11.3779 12.13 11.4394 12.7842 12.0644L14.5984 13.7977"
				stroke={color}
				strokeLinecap="round"
			/>
		</svg>
	);
};
