import React, { useState } from 'react';
import dayjs from 'dayjs';
import { CompanyStatsProps } from './CompanyStats.interface';
import {
	SypacBox,
	SypacButton,
	SypacText,
} from '@sypac/component-library-react';
import PieChart from '../PieChart/PieChart';
import { NumericFormat } from 'react-number-format';
import { T } from '@tolgee/react';
import { formatDate } from '../../utils/time.util';

const CompanyStats: React.FC<CompanyStatsProps> = ({
	stats,
	companyType,
	countryCode,
}) => {
	const [showMore, setShowMore] = useState(companyType !== 'customer');

	return (
		<div className="mt-5">
			<SypacBox
				hover={false}
				box-style="solid"
				box-color="primary"
				boxShadow="sm"
			>
				<div className="flex flex-row justify-start px-5 py-4 border-0 border-b border-solid border-gray-10">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							<T keyName="companyStats.statistics">Statistics</T>
						</p>
					</SypacText>
				</div>
				<div className={`p-5 border-0 border-b border-solid border-gray-10`}>
					{companyType === 'customer' ? (
						<div className="flex flex-row justify-start">
							<PieChart
								overdueOrders={stats.count?.overdue}
								paidOrders={stats.count?.paid}
							/>
							<div className="flex flex-col justify-start w-full ml-6">
								<div className="flex flex-row justify-between items-end pb-3 border-0 border-b border-solid border-gray-10">
									<div className="flex flex-col">
										<SypacText variant="body-regular-small">
											<p className="text-nevada">
												<T keyName="companyStats.currentBalance">
													Current balance:
												</T>
											</p>
										</SypacText>
										<SypacText variant="heading-4" className="mt-1">
											<p className="text-tuna">
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator="."
													decimalSeparator=","
													value={
														stats.amount
															? parseFloat(parseFloat(stats.amount)?.toFixed(2))
															: 0
													}
													suffix={countryCode === 'MD' ? ' MDL' : ' PLN'}
												/>
											</p>
										</SypacText>
									</div>
									<div>
										<div className="flex flex-row bg-blue-10 rounded-[57px] px-3 py-1">
											<SypacText variant="overline-regular-large">
												<p className="text-gray-80">
													<T keyName="companyStats.creditLimit">
														Credit limit:
													</T>
													<NumericFormat
														type="text"
														displayType="text"
														thousandSeparator="."
														decimalSeparator=","
														value={
															stats.creditLimit
																? parseFloat(
																		parseFloat(stats.creditLimit)?.toFixed(2),
																  )
																: 0
														}
														suffix={countryCode === 'MD' ? ' MDL' : ' PLN'}
													/>
												</p>
											</SypacText>
										</div>
									</div>
								</div>
								<div className="flex flex-row justify-between items-start pt-3">
									<div className="flex flex-col">
										<SypacText variant="body-regular-medium">
											<p className="text-red">
												<T keyName="companyStats.currentOverdueOrders">
													Current overdue orders:
												</T>{' '}
												{stats.count?.overdue}
											</p>
										</SypacText>
										<SypacText variant="body-regular-small" className="mt-1">
											<p className="text-gray-40">
												<T keyName="companyStats.totalUnpaidAmount">
													Total unpaid amount:
												</T>
												Total unpaid amount:{' '}
												{parseFloat(stats.count?.overdue) +
													parseFloat(stats.count?.pending)}
											</p>
										</SypacText>
									</div>
									<div>
										<div className="flex flex-row bg-mountain-meadow-10 rounded-[57px] px-3 py-1">
											<SypacText variant="overline-regular-large">
												<p className="text-gray-80">
													<T keyName="companyStats.paymentTerm">
														Payment term:
													</T>{' '}
													{stats.paymentTerm}{' '}
													<T keyName="companyStats.days">days</T>
												</p>
											</SypacText>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="flex flex-col">
							<div className="flex flex-row">
								<div className="flex flex-col justify-start">
									<div className="flex flex-row justify-start items-center">
										<SypacText variant="heading-4">
											<p className="text-gray-80">
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator="."
													decimalSeparator=","
													value={
														stats.amount
															? parseFloat(parseFloat(stats.amount)?.toFixed(2))
															: 0
													}
													suffix={''}
												/>
											</p>
										</SypacText>
										<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center ml-3">
											<SypacText variant="body-regular-small">
												<p className="text-gray-80">
													{countryCode === 'MD' ? 'MDL' : 'PLN'}
												</p>
											</SypacText>
										</div>
									</div>

									<SypacText variant="overline-regular-large" className="mt-3">
										<p className="text-gray-40">
											<T keyName="companyStats.earnedFrom">Earned from</T>{' '}
											{formatDate(
												dayjs(stats.nextInvoicingDate)
													.subtract(stats.paymentTerm, 'days')
													.toString(),
											)}{' '}
											<T keyName="companyStats.to">to</T>{' '}
											{formatDate(stats.nextInvoicingDate)}
										</p>
									</SypacText>
								</div>
								<div className="flex flex-col justify-start ml-15">
									<div className="flex flex-row justify-start items-center">
										<SypacText variant="heading-4">
											<p className="text-gray-80">
												<NumericFormat
													type="text"
													displayType="text"
													thousandSeparator="."
													decimalSeparator=","
													value={
														parseFloat(stats.amountTotal?.pending) +
														parseFloat(stats.amountTotal?.overdue)
													}
													suffix={''}
												/>
											</p>
										</SypacText>
										<div className="bg-gray-10 rounded px-3 h-6 flex flex-row justify-center items-center ml-3">
											<SypacText variant="body-regular-small">
												<p className="text-gray-80">
													{countryCode === 'MD' ? 'MDL' : 'PLN'}
												</p>
											</SypacText>
										</div>
									</div>

									<SypacText variant="overline-regular-large" className="mt-3">
										<p className="text-gray-40">
											<T keyName="companyStats.bondBalance">Bond balance</T>
										</p>
									</SypacText>
								</div>
							</div>
							<div className="mt-3">
								<div className="flex flex-row bg-blue-10 rounded-[57px] px-3 py-1 w-fit">
									<SypacText variant="overline-regular-large">
										<p className="text-gray-80">
											<T keyName="companyStats.paymentScheduledFor">
												Payment scheduled for
											</T>{' '}
											{formatDate(stats.nextInvoicingDate, 'DD MMM')}
										</p>
									</SypacText>
								</div>
							</div>
						</div>
					)}
				</div>
				{showMore ? (
					<div className="p-5">
						<div className="grid grid-cols-3 gap-4">
							<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-mountain-meadow">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.paidOrders">Paid orders:</T>
									</p>
								</SypacText>
								<SypacText variant="heading-5" className="ml-3">
									<p className="text-gray-80 font-medium">
										{stats.countOrders?.paid}
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-texas-rose">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.ordersInProgress">
											Order in progress:
										</T>
									</p>
								</SypacText>
								<SypacText variant="heading-5" className="ml-3">
									<p className="text-gray-80 font-medium">
										{stats.countOrders?.inProgress}
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-red">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.ordersInDispute">
											Orders in dispute:
										</T>
									</p>
								</SypacText>
								<SypacText variant="heading-5" className="ml-3">
									<p className="text-gray-80 font-medium">
										{stats.countOrders?.dispute}
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.allOrders">All orders:</T>
									</p>
								</SypacText>
								<SypacText variant="heading-5" className="ml-3">
									<p className="text-gray-80 font-medium">
										{stats.countOrders?.all}
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.orderLast60Days">
											Order last 30 days:
										</T>
									</p>
								</SypacText>
								<SypacText variant="heading-5" className="ml-3">
									<p className="text-gray-80 font-medium">
										{stats.ordersLast30Days}
									</p>
								</SypacText>
							</div>
							<div className="flex flex-row items-center p-3 rounded-11 border border-solid border-gray-10">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.orderLast60Days">
											Order last 60 days:
										</T>
									</p>
								</SypacText>
								<SypacText variant="heading-5" className="ml-3">
									<p className="text-gray-80 font-medium">
										{stats.ordersLast60Days}
									</p>
								</SypacText>
							</div>

							<div className="flex flex-wrap gap-3 items-center p-3 rounded-11 border border-solid border-gray-10">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.revenueLast30">
											Revenue last 30 d.:
										</T>
									</p>
								</SypacText>
								<div className="flex gap-3 flex-grow">
									<SypacText variant="body-regular-large">
										<p className="text-gray-80">{stats.revenueLast30Days}</p>
									</SypacText>
									<div className="h-6 flex justify-center items-center bg-gray-10 rounded px-3">
										<SypacText variant="body-regular-small">
											<p className="text-gray-80">
												{countryCode === 'MD' ? 'MDL' : 'PLN'}
											</p>
										</SypacText>
									</div>
								</div>
							</div>
							<div className="flex flex-wrap gap-3 items-center p-3 rounded-11 border border-solid border-gray-10">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.revenueLast60">
											Revenue last 60 d.:
										</T>
									</p>
								</SypacText>
								<div className="flex gap-3 flex-grow">
									<SypacText variant="body-regular-large">
										<p className="text-gray-80">{stats.revenueLast60Days}</p>
									</SypacText>
									<div className="h-6 flex justify-center items-center bg-gray-10 rounded px-3">
										<SypacText variant="body-regular-small">
											<p className="text-gray-80">
												{countryCode === 'MD' ? 'MDL' : 'PLN'}
											</p>
										</SypacText>
									</div>
								</div>
							</div>
							<div className="flex flex-wrap gap-3 items-center p-3 rounded-11 border border-solid border-gray-10">
								<SypacText variant="overline-regular-large">
									<p className="text-gray-40">
										<T keyName="companyStats.revenueLast90">
											Revenue last 90 d.:
										</T>
									</p>
								</SypacText>
								<div className="flex gap-3 flex-grow">
									<SypacText variant="body-regular-large">
										<p className="text-gray-80">{stats.revenueLast90Days}</p>
									</SypacText>
									<div className="h-6 flex justify-center items-center bg-gray-10 rounded px-3">
										<SypacText variant="body-regular-small">
											<p className="text-gray-80">
												{countryCode === 'MD' ? 'MDL' : 'PLN'}
											</p>
										</SypacText>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}

				{companyType === 'customer' ? (
					<div
						className={`flex flex-row w-full justify-center py-3 ${
							showMore ? 'border-0 border-t border-solid border-gray-10' : ''
						}`}
					>
						<SypacButton
							variant="secondary"
							size="small"
							onClick={() => setShowMore(!showMore)}
						>
							<button className="px-5 py-2 border-0" type="button">
								<SypacText variant="body-regular-medium" className="mr-3">
									<p className="text-gray-80">
										{showMore ? (
											<T keyName="companyStats.showLess">Show less</T>
										) : (
											<T keyName="companyStats.showMore">Show more</T>
										)}
									</p>
								</SypacText>
								{showMore ? (
									<div className="box-icon box-icon-down" />
								) : (
									<div className="box-icon box-icon-up" />
								)}
							</button>
						</SypacButton>
					</div>
				) : null}
			</SypacBox>
		</div>
	);
};

export default CompanyStats;
