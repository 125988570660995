import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { SypacTabs, SypacText } from '@sypac/component-library-react';
import { AvatarItem } from '../../../components/AvatarDropDown/AvatarDropDown.interface';
import {
	NotificationInterface,
	OrderInterface,
} from '../../../components/OrdersTable/interfaces/Order.interface';
import { OrdersTable } from '../../../components/OrdersTable/OrdersTable';
import { UsersInterface } from '../../../interfaces/Users.interface';
import { LIMIT } from '../../../constants';
import { AuthContext } from '../../../context/context';
import { OrderContext } from '../../../context/OrderContext/order.context';
import { OrdersService } from '../../../services/orders.services';
import OrderDetails from '../OrderDetails';
import Pagination from '../../../components/Pagination/Pagination';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import Emitter, { EventType } from '../../../services/events';
import { T, useTranslate } from '@tolgee/react';
import { useSearchParams } from 'react-router-dom';
import { TabsButton } from '../../../components/TabsButton/TabsButton';
import { NotificationsService } from '../../../services/notifications.services';
import SearchBar from '../../../components/SearchBar/SearchBar';
import useDebounce from '../../../hooks/useDebounce';

const Orders: React.FC = () => {
	const { t } = useTranslate();
	const [searchParams] = useSearchParams();
	const orderId = searchParams.get('orderId');
	const [orders, setOrders] = useState<OrderInterface[]>([]);
	const [page, setPage] = useState<number>(0);
	const [count, setCount] = useState<number>(0);
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, setUsers] = useState<AvatarItem[]>([]);
	const [searchLocal, setSearchLocal] = useState<string>('');
	const [clientIds] = useState<string[]>([]);
	const [statuses, setStatuses] = useState<string[]>([]);
	const [selectedTab, setSelectedTab] = useState('all');
	const [notifications, setNotifications] = useState<any>({});
	const { user } = useContext(AuthContext);
	const { setOrderId, additional } = useContext(OrderContext);
	const tableRef = useRef<HTMLDivElement>(null);
	const detailsRef = useRef<HTMLDivElement>(null);
	const searchQuery = useDebounce(searchLocal, 500);

	const getNotifications = useCallback(async () => {
		if (!user?.uid) return;

		try {
			const { data } = await NotificationsService.getCount({
				recipientId: user.uid,
				limit: 500,
			});
			const notifies = Array.from(
				new Map(data.items.map((item) => [item.groupId, item])).values(),
			);

			if (notifies) {
				const statuses: any = {};

				for (const notify of notifies) {
					const orderId = parseInt(notify.groupId);
					let data: OrderInterface | undefined;
					const respOrder = await OrdersService.getOrder(
						orderId,
						OrderTarget.customer,
					);
					data = respOrder.data;
					statuses[data.status] = (statuses[data.status] || 0) + 1;
				}

				setNotifications(statuses);
			}
		} catch (e) {}
	}, [user?.uid]);

	useEffect(() => {
		Emitter.on(EventType.NOTIFICATION_COUNT, () => {
			getNotifications();
		});
		Emitter.on(EventType.NOTIFICATION_COUNT_DECREASE, (count) => {
			setNotifications((prev: any) => (prev - count >= 0 ? prev - count : 0));
			getNotifications();
		});

		return () => {
			Emitter.off(EventType.NOTIFICATION_COUNT);
			Emitter.off(EventType.NOTIFICATION_COUNT_DECREASE);
		};
	}, [getNotifications]);

	useEffect(() => {
		getNotifications();
	}, [getNotifications]);

	const getOrdersCallback = useCallback(async () => {
		try {
			const query = {
				clientIds,
				statuses,
				searchQuery,
				limit: LIMIT,
				offset: page * LIMIT,
			};
			const { data } = await OrdersService.getOrders(query);
			setOrders((data.items as unknown as OrderInterface[]) || []);
			setCount(data.count || 0);
		} catch (e) {
			console.log(e);
		}
	}, [clientIds, page, searchQuery, statuses]);

	const getOrderCallback = useCallback(
		async (notify: NotificationInterface) => {
			try {
				const orderId = parseInt(notify.groupId);
				const { data } = await OrdersService.getOrder(
					orderId,
					OrderTarget.customer,
				);
				if (orderId === selectedOrder?.id) {
					setOrder({
						...data,
						notificationCount: 0,
					} as unknown as OrderInterface);
				}
				const newOrders = orders.map((order) => {
					if (order.id === orderId) {
						const notificationFilter = data.notifications.items.filter(
							(r) => !r.readAt,
						);
						return {
							...order,
							...data,
							notificationCount:
								orderId === selectedOrder?.id ? 0 : notificationFilter.length,
						};
					}
					return order;
				});
				setOrders(newOrders as unknown as OrderInterface[]);
			} catch (e) {
				console.log(e);
			}
		},
		[orders, selectedOrder?.id],
	);

	useEffect(() => {
		Emitter.on(EventType.ORDER_LIST_REFRESH, (notify: any) => {
			if (notify && notify.groupId) {
				getOrderCallback(notify);
			}
		});
		return () => {
			Emitter.off(EventType.ORDER_LIST_REFRESH);
		};
	}, [getOrderCallback]);

	useEffect(() => {
		getOrdersCallback();
	}, [getOrdersCallback]);

	const decreaseNotification = useCallback(
		(orderId: number) => {
			const newOrders = orders.map((order) => {
				if (order.id === orderId) {
					return {
						...order,
						notificationCount: 0,
					};
				}
				return order;
			});
			setOrders(newOrders as unknown as OrderInterface[]);
		},
		[orders],
	);

	useEffect(() => {
		Emitter.on(
			EventType.ORDER_NOTIFICATION_COUNT_DECREASE,
			(orderId: number) => {
				decreaseNotification(orderId);
			},
		);

		return () => {
			Emitter.off(EventType.ORDER_NOTIFICATION_COUNT_DECREASE);
		};
	}, [decreaseNotification]);

	useEffect(() => {
		if (user) {
			const me = user as UsersInterface;
			setUsers([
				{ id: me.uid, fullName: me.name || me.email, avatarUrl: me.avatarUrl },
			]);
		}
	}, [user]);

	const rowClick = useCallback(
		async (order: Partial<OrderInterface>) => {
			try {
				const { data } = await OrdersService.getOrder(
					order.id!,
					OrderTarget.customer,
				);
				setOrderId(order.id);
				setOrder(data as unknown as OrderInterface);
				setOpenDetails(true);
			} catch (e) {
				console.log(e);
			}
		},
		[setOrderId],
	);

	useEffect(() => {
		if (orderId && orderId.length) {
			rowClick({ id: parseInt(orderId, 10) });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderId]);

	useEffect(() => {
		if (additional && additional.order) {
			rowClick(additional.order);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [additional]);

	useEffect(() => {
		Emitter.on(EventType.ORDER_REFRESH, (orderId) => {
			if (orderId) {
				rowClick({ id: parseInt(orderId, 10) });
			}
		});
		return () => {
			Emitter.off(EventType.ORDER_REFRESH);
		};
	}, [rowClick, selectedOrder]);

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const setCurrentTab = (statuses: string[]) => {
		setPage(0);
		setStatuses(statuses);
	};

	const clickOutsideDetails = useCallback((event: MouseEvent) => {
		const toastContainer = document.querySelector('.Toastify');

		if (
			!detailsRef.current?.contains(event.target as Node) &&
			!tableRef.current?.contains(event.target as Node) &&
			!additional.orderModalRef.current?.contains(event.target as Node) &&
			!(toastContainer && toastContainer.contains(event.target as Node))
		) {
			closeModal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', clickOutsideDetails);
		return () => {
			document.removeEventListener('mousedown', clickOutsideDetails);
		};
	}, [clickOutsideDetails]);

	const sortedOrders = orders.sort((a, b) => {
		if (a.notificationCount! > 0 && b.notificationCount! > 0) {
			return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
		}

		if (a.notificationCount! > 0) return -1;
		if (b.notificationCount! > 0) return 1;

		return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
	});

	return (
		<>
			<div className="flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full relative">
				<div className="hidden xl:flex flex-col gap-4">
					<div className="flex flex-col gap-4 h-[78px] ml-5">
						<SypacText variant="body-regular-large">
							<p className="text-gray-80 text-3xl tracking-tighter">
								<T keyName="ordersDashboard.myOrders">My orders</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-large">
							<p className="text-gray-40 text-base tracking-tighter">
								<T keyName="ordersDashboard.trackYourOrdersFromProcessingDelivery">
									Track your orders, from processing to delivery.
								</T>
							</p>
						</SypacText>
					</div>
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
				</div>

				<div className="flex items-center gap-6 2xl:gap-12 ml-5 mr-7.5">
					<SypacTabs className="flex w-fit p-[3px] gap-[4px]">
						{[
							{ label: t('orders.all', 'All'), buttonId: 'all', statuses: [] },
							{
								label: t('orders.reviewing', 'Reviewing'),
								buttonId: 'created',
								statuses: ['created'],
							},
							{
								label: t('orders.preparingOrder', 'Preparing order'),
								buttonId: 'preparing',
								statuses: [
									'preparing',
									'to_unloading',
									'in_progress',
									'arrived_to_unloading',
								],
							},
							// {
							// 	label: t('orders.shipped', 'Shipped'),
							// 	buttonId: 'shipped',
							// 	statuses: ['shipped'],
							// },
							{
								label: t('orders.delivered', 'Delivered'),
								buttonId: 'delivered',
								statuses: ['delivered'],
							},
							{
								label: t('orders.completed', 'Completed'),
								buttonId: 'completed',
								statuses: ['paid'],
							},
						].map(({ label, buttonId, statuses }) => (
							<TabsButton
								key={buttonId}
								label={label}
								buttonId={buttonId}
								onClick={() => setCurrentTab(statuses)}
								isSelected={selectedTab === buttonId}
								handleSelectedTab={() => setSelectedTab(buttonId)}
								notification={
									statuses.length
										? statuses.reduce(
												(total, status) =>
													total + (notifications?.[status] || 0),
												0,
										  )
										: Object.values(notifications).reduce<number>(
												(sum, count) => sum + (count as number),
												0,
										  )
								}
							/>
						))}
					</SypacTabs>

					<div className="ml-auto">
						<SearchBar
							placeholder={t(
								'adminOrders.search',
								'Search by Order ID & Product name',
							)}
							onChange={setSearchLocal}
							showButton={false}
							classNames="w-[320px]"
						/>
					</div>
				</div>

				<div className="h-full relative ml-5 mr-7.5 border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
					<div
						className={`w-full overflow-y-auto scroll-smooth pr-[5px] ${
							sortedOrders.length ? 'h-[calc(100%-56px)]' : 'h-[calc(100%+2px)]'
						}`}
						ref={tableRef}
					>
						<OrdersTable
							rows={sortedOrders}
							target={OrderTarget.customer}
							rowClick={rowClick}
							search={searchQuery}
							currentTab={selectedTab}
						/>
					</div>
					{sortedOrders.length ? (
						<div className="w-full absolute bottom-0 border border-solid border-gray-10 border-l-0 border-r-0 border-b-0 shadow-pagination">
							<div className="flex justify-between items-center h-[56px] px-4">
								<Pagination
									count={count}
									page={page}
									onClick={(item) => setPage(item)}
								/>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div ref={detailsRef}>
				<OrderDetails
					isOpen={!!(isOpen && selectedOrder?.id)}
					selectedOrder={selectedOrder}
				/>
			</div>
		</>
	);
};

export default Orders;
