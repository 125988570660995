import React from 'react';
import Modal from 'react-modal';
import { T } from '@tolgee/react';
import {
	SypacButton,
	SypacCheckbox,
	SypacText,
} from '@sypac/component-library-react';
import Close from '../../../assets/Close';
import { MapIcon } from '../../../assets/MapIcon';
import { useNavigate } from 'react-router-dom';

interface ModalDeleteAddressProps {
	isOpen: boolean;
	onClose: () => void;
	dontShowAnymore: () => void;
	isChecked: boolean;
}

const ModalDeleteAddress: React.FC<ModalDeleteAddressProps> = ({
	isOpen,
	onClose,
	dontShowAnymore,
	isChecked,
}) => {
	const navigate = useNavigate();

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			className="w-[516px] modal-inside outline-none"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="flex flex-col h-full">
				<div className="flex items-center justify-between p-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90 mt-[2px]">
							<T keyName="modalDeleteAddress.deleteDeliveryAddress">
								Delete delivery address
							</T>
						</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={onClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>
				<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />

				<div className="flex flex-col px-3 mb-[56px]">
					<div className="relative">
						<span className="w-fit flex scale-[3.334] ml-[16px] my-[60px]">
							<MapIcon green={true} />
						</span>

						<div className="absolute top-[50px] left-[35px] border-[6px] border-solid border-white w-[22px] h-[22px] flex items-center justify-center bg-red rounded-full">
							<Close width="10" height="10" color="white" />
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<SypacText variant="body-regular-medium">
							<p className="text-2xl leading-7 text-gray-80">
								<T keyName="modalDeleteAddress.areYouSure">
									Are you sure you want to proceed?
								</T>
							</p>
						</SypacText>
						<SypacText variant="body-regular-medium">
							<p className="text-sm text-gray-40">
								<T keyName="modalDeleteAddress.deletingTheDeliveryDddress">
									Deleting the delivery address will take you back to the
									store's home screen and you'll need to begin the product
									selection process again.
								</T>
							</p>
						</SypacText>
					</div>
				</div>

				<div className="mt-auto">
					<div className="border border-solid border-gray-10 border-t-0 border-l-0 border-r-0" />
					<div className="flex flex-col gap-3 w-full px-3 py-3 [&_button]:transition box-border">
						<SypacButton variant="primary" size="small" className="w-full">
							<button
								type="button"
								className="w-full py-2.5 rounded-lg"
								onClick={() => navigate('/store')}
							>
								<SypacText variant="body-regular-medium">
									<p className="text-white">
										<T keyName="modalDeleteAddress.deleteDeliveryDddress">
											Delete delivery address
										</T>
									</p>
								</SypacText>
							</button>
						</SypacButton>

						<div className="flex gap-4 items-center cursor-pointer">
							<SypacCheckbox size="md">
								<input
									type="checkbox"
									name="unlimited"
									checked={isChecked}
									onChange={dontShowAnymore}
									className="cursor-pointer"
								/>
							</SypacCheckbox>
							<SypacText variant="body-regular-medium">
								<p className="text-gray-40 mt-1">
									<T keyName="modalDeleteAddress.dontShowAgain">
										Don't show again
									</T>
								</p>
							</SypacText>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalDeleteAddress;
