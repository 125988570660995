import React, { useCallback, useEffect, useRef, useState } from 'react';
import Table from 'rc-table';
import 'react-toastify/dist/ReactToastify.css';
import { SypacAvatar, SypacText } from '@sypac/component-library-react';
import './products-table.css';
import { ProductsTableInterface } from './ProductsTable.interface';
import { T, useTranslate } from '@tolgee/react';
import {
	ProductInterface,
	ProductStatusEnum,
} from '../../interfaces/product.interface';
import Flag from 'react-world-flags';
import { NoResults } from '../../assets/NoResult';
import TableStatus from '../TableStatus/TableStatus';
import { formatDate } from '../../utils/time.util';
import { COUNTRIES } from '../DropDownOption/countries.constant';
import { ChevronRightIcon } from '../../assets/ChevronRightIcon';

export const ProductsTable: React.FC<ProductsTableInterface> = ({
	rows,
	rowClick,
	search,
	openCompany,
	clickedOutside,
	database = false,
}) => {
	const { t } = useTranslate();
	const language = localStorage.getItem('lang') || 'en';
	const [keyRows, setRows] = useState<ProductInterface[]>([]);
	const [selectedRowKey, setSelectedRowKey] = useState<number>();
	const [languageDropdownOpen, setLanguageDropdownOpen] =
		useState<boolean>(false);
	const [activeLanguage, setActiveLanguage] = useState<string>(language);

	const activeLanguageRef = useRef(activeLanguage);

	useEffect(() => {
		activeLanguageRef.current = activeLanguage;
	}, [activeLanguage]);

	const locale = [
		{
			title: 'English',
			value: 'en',
		},
		{
			title: 'Polish',
			value: 'pl',
		},
		{
			title: 'Romanian',
			value: 'ro',
		},
		{
			title: 'Russian',
			value: 'ru',
		},
	];

	const getNameColumn = (search: string | undefined, isDatabase?: boolean) => {
		return {
			title: (
				<div className="relative flex items-center">
					<SypacText variant="body-regular-medium">
						<p className="text-sm text-gray-40 mt-[6px]">
							<T keyName="productTable.productName">Product name</T>
						</p>
					</SypacText>

					<button
						onClick={() => setLanguageDropdownOpen(!languageDropdownOpen)}
						className="absolute top-[1px] left-[94px] flex gap-3 items-center justify-center border border-solid border-transparent rounded-md bg-transparent cursor-pointer transition hover:border-gray-20 px-1 py-[1px] active:border-cornflower-blue"
					>
						<span className="rounded-[3px] overflow-hidden">
							<Flag
								className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded mt-1"
								code={
									{
										en: 'GBR',
										ro: 'ROU',
										ru: 'RUS',
										pl: 'POL',
									}[activeLanguage] || 'GBR'
								}
								width={22}
								height={16}
							/>
						</span>
						<span
							className={`flex transform transition-all duration-300 ease-in-out ${
								languageDropdownOpen ? 'rotate-90' : ''
							}`}
						>
							<ChevronRightIcon />
						</span>
					</button>

					{languageDropdownOpen && (
						<div className="absolute top-8 left-10 flex flex-col p-[3px] border border-solid border-gray-10 rounded-10 shadow-dropdown bg-white min-w-[145px] w-max z-30">
							{locale.map((local) => (
								<div
									key={local.value}
									className="flex py-[10px] px-3 bg-white border border-solid border-transparent rounded-lg cursor-pointer hover:bg-gray-10-opacity-50 hover:border-gray-10"
									onClick={() => {
										setActiveLanguage(local.value);
										setLanguageDropdownOpen(false);
									}}
								>
									<SypacText variant="body-regular-medium" className="mr-auto">
										<p className="text-gray-80">{local.title}</p>
									</SypacText>
								</div>
							))}
						</div>
					)}
				</div>
			),
			dataIndex: '',
			className: 'text-left',
			key: 'name',
			render(value: ProductInterface) {
				const productName = `${
					value?.translations?.find(
						(locale) => locale.language === activeLanguage,
					)?.name || value?.name
				}`;
				const html = search
					? productName?.replace(
							new RegExp(search, 'gi'),
							(match) => `<span class="text-texas-rose">${match}</span>`,
					  )
					: productName;
				const hasSecond = false;

				return isDatabase ? (
					<SypacText variant="body-regular-medium">
						<p
							className="text-gray-80"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
					</SypacText>
				) : (
					<div className="flex gap-4">
						<div
							style={{
								backgroundImage: `url(${value?.photoUrl})`,
							}}
							className="flex flex-none w-[48px] h-[48px] bg-no-repeat bg-cover border border-solid border-gray-10 rounded-10 box-border"
						/>

						<div className="flex flex-col gap-[6px]">
							<div className="flex flex-row items-center gap-3">
								<SypacText variant="body-regular-medium">
									<p
										className="text-gray-80"
										dangerouslySetInnerHTML={{ __html: html }}
									/>
								</SypacText>
								{value?.type || value?.size ? (
									<>
										<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
										<SypacText variant="body-regular-medium">
											<p className="text-gray-80">
												{value?.type || ''}{' '}
												{value?.size ? `(${value?.size})` : ''}
											</p>
										</SypacText>
									</>
								) : null}
							</div>

							<div className="flex flex-row items-center gap-4">
								{value.countryCode ? (
									<Flag
										className="w-[18px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
										code={value.countryCode}
										width={22}
										height={16}
									/>
								) : null}
								<SypacText variant="body-regular-medium">
									<p
										className={`text-sm text-gray-50 ${
											hasSecond ? 'w-[162px] truncate' : ''
										}`}
									>
										{value.address}
									</p>
								</SypacText>

								{hasSecond && (
									<>
										<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
										<Flag
											className="w-[18px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
											code={value.countryCode}
											width={22}
											height={16}
										/>
										<SypacText variant="body-regular-medium">
											<p className="w-[162px] text-sm text-gray-50 truncate">
												{value.address}
											</p>
										</SypacText>
									</>
								)}
							</div>
						</div>
					</div>
				);
			},
		};
	};

	const columns = (search?: string) => [
		{
			title: t('productTable.country', 'Country'),
			dataIndex: '',
			className: 'text-left',
			key: 'country',
			render(value: ProductInterface) {
				const countryCode = value?.countryCode;
				const hasSecond = false;

				return (
					<div className="flex items-center gap-3">
						<Flag
							className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
							code={countryCode}
							width={22}
							height={16}
						/>
						<SypacText variant="body-regular-medium">
							<p>{countryCode}</p>
						</SypacText>

						{hasSecond && (
							<>
								<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />
								<Flag
									className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded z-10"
									code={countryCode}
									width={22}
									height={16}
								/>
								<SypacText variant="body-regular-medium">
									<p>{countryCode}</p>
								</SypacText>
							</>
						)}
					</div>
				);
			},
		},
		{
			title: t('productTable.status', 'Status'),
			dataIndex: '',
			className: 'text-left',
			key: 'status',
			render(value: ProductInterface) {
				const status: string = value?.status || ProductStatusEnum.PENDING;

				return (
					<div className="flex">
						<TableStatus state={status} isPartial={false} insideOut={true} />
					</div>
				);
			},
		},
		{
			title: t('productTable.id', 'ID'),
			dataIndex: '',
			className: 'text-left',
			key: 'id',
			render(value: ProductInterface) {
				const html = search
					? value.id
							.toString()
							.replace(
								new RegExp(search, 'gi'),
								(match) => `<span class="text-texas-rose">${match}</span>`,
							)
					: value.id.toString();

				return (
					<SypacText variant="body-regular-medium">
						<p
							className="text-gray"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
					</SypacText>
				);
			},
		},
		getNameColumn(search),
		{
			title: t('productTable.addedBY', 'Added by'),
			dataIndex: '',
			className: 'text-left',
			key: 'addedBY',
			render(value: ProductInterface) {
				const companyName = value?.company?.name;
				const date = formatDate(value.createdAt);

				return (
					<div className="flex gap-4">
						<div className="flex w-11 h-11">
							<SypacAvatar initials={companyName} size="md" />
						</div>

						<div className="flex flex-col gap-[6px]">
							<SypacText variant="body-regular-medium">
								<p className="max-w-[330px] text-cornflower-blue truncate transition hover:text-gray-90">
									{companyName}
								</p>
							</SypacText>

							<SypacText variant="body-regular-medium">
								<p className="text-sm text-gray-50">{date}</p>
							</SypacText>
						</div>
					</div>
				);
			},
		},
		{
			title: t('productTable.approvedRejected', 'Approved / Rejected by'),
			dataIndex: '',
			className: 'text-left',
			key: 'approvedRejected',
			render(value: ProductInterface) {
				const status = value.status || ProductStatusEnum.PENDING;
				const date = formatDate(value.updatedAt);
				const fullName =
					[
						value.verifiedByUser?.firstName || '',
						value.verifiedByUser?.lastName || '',
					]
						.join(' ')
						.trim() || value.verifiedByUser?.name;

				return [
					ProductStatusEnum.APPROVED.toString(),
					ProductStatusEnum.REJECTED.toString(),
				].includes(status) ? (
					<div className="flex items-center gap-4">
						<SypacAvatar initials={fullName} size="sm">
							{value.verifiedByUser?.avatarUrl ? (
								<img src={value.verifiedByUser?.avatarUrl} alt={fullName} />
							) : null}
						</SypacAvatar>

						<SypacText variant="body-regular-medium">
							<p className="text-gray-80">{fullName}</p>
						</SypacText>

						<div className="flex items-center justify-center px-2 py-[2px] bg-gray-1 border border-solid border-gray-10 rounded-full">
							<SypacText variant="body-regular-medium">
								<p className="text-xs text-gray-40">{date}</p>
							</SypacText>
						</div>
					</div>
				) : null;
			},
		},
	];

	const databaseColumns = (search?: string) => [
		{
			title: t('productTable.countryStore', 'Country store'),
			dataIndex: '',
			className: 'text-left',
			key: 'countryStore',
			render(value: ProductInterface) {
				const country = COUNTRIES.find(
					(country) => country['alpha-3'] === value?.countryCode,
				);

				return (
					<div className="flex items-center gap-3">
						<Flag
							className="w-[20px] h-[14px] mb-[2px] object-cover border border-solid border-gray-10 rounded"
							code={country?.['alpha-3']}
							width={22}
							height={16}
						/>
						<SypacText variant="body-regular-medium">
							<p className="mt-[2px]">{country?.value}</p>
						</SypacText>
					</div>
				);
			},
		},
		// {
		// 	title: t('productTable.id', 'ID'),
		// 	dataIndex: '',
		// 	className: 'text-left',
		// 	key: 'id',
		// 	render(value: ProductInterface) {
		// 		const html = search
		// 			? value.id
		// 					.toString()
		// 					.replace(search, `<span class="text-text-texas-rose">${search}</span>`)
		// 			: value.id.toString();
		//
		// 		return (
		// 			<SypacText variant="body-regular-medium">
		// 				<p
		// 					className="text-gray-80"
		// 					dangerouslySetInnerHTML={{ __html: html }}
		// 				/>
		// 			</SypacText>
		// 		);
		// 	},
		// },
		{
			title: t('productTable.group', 'Group'),
			dataIndex: '',
			className: 'text-left',
			key: 'group',
			render(value: ProductInterface) {
				const group = value.category.parent?.name;

				return (
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{group}</p>
					</SypacText>
				);
			},
		},
		{
			title: t('productTable.category', 'Category'),
			dataIndex: '',
			className: 'text-left',
			key: 'category',
			render(value: ProductInterface) {
				const category = value.category.name;

				return (
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{category}</p>
					</SypacText>
				);
			},
		},
		{
			title: t('productTable.typeSize', 'Product type & size'),
			dataIndex: '',
			className: 'text-left',
			key: 'typeSize',
			render(value: ProductInterface) {
				const type = value?.type;
				const size = value?.size;

				return (
					<div className="flex gap-4 items-center">
						{type || size ? (
							<>
								{type && (
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">{type}</p>
									</SypacText>
								)}
								{type && size && (
									<div className="w-[2px] h-[2px] bg-gray-90 rounded-full" />
								)}
								{size && (
									<SypacText variant="body-regular-medium">
										<p className="text-gray-80">{size}</p>
									</SypacText>
								)}
							</>
						) : (
							<SypacText variant="body-regular-medium">
								<p className="text-gray-80">—</p>
							</SypacText>
						)}
					</div>
				);
			},
		},
		getNameColumn(search, true),
		{
			title: t('productTable.photo', 'Photo'),
			dataIndex: '',
			className: 'text-left',
			key: 'photo',
			render(value: ProductInterface) {
				return (
					<div
						style={{
							backgroundImage: `url(${value?.photoUrl})`,
						}}
						className="flex flex-none w-[48px] h-[48px] bg-no-repeat bg-cover border border-solid border-gray-10 rounded-10 box-border"
					/>
				);
			},
		},
		{
			title: t('productTable.orders', 'Orders'),
			dataIndex: '',
			className: 'text-left',
			key: 'orders',
			render(value: ProductInterface) {
				return (
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{value?.totalOrders || '—'}</p>
					</SypacText>
				);
			},
		},
		{
			title: t('productTable.listings', 'Listings'),
			dataIndex: '',
			className: 'text-left',
			key: 'listings',
			render(value: ProductInterface) {
				return (
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">{'—'}</p>
					</SypacText>
				);
			},
		},
		{
			title: t('productTable.createdDate', 'Created date'),
			dataIndex: '',
			className: 'text-left',
			key: 'createdDate',
			render(value: ProductInterface) {
				return (
					<SypacText variant="body-regular-medium">
						<p className="text-gray-80">
							{formatDate(value.createdAt, 'DD MMM, YYYY')}
						</p>
					</SypacText>
				);
			},
		},
	];

	useEffect(() => {
		const newRows = rows.map((row) => ({ ...row, key: row?.id }));
		setRows(newRows);
	}, [rows]);

	const getTrProps = (
		record: ProductInterface,
		_index: number,
		_indent: number,
	): string => {
		const baseClass = `product-table-row-${record.id}`;
		const bgClass = ` product-table-row-${record.status}`;
		const selected =
			record.id === selectedRowKey && !clickedOutside
				? ' product-table-row-selected'
				: '';

		return `${baseClass}${bgClass}${selected}`;
	};

	const handleClick = useCallback(
		(event: Event, row: any) => {
			event.stopImmediatePropagation();
			const className = row.className;
			const regexes = className.match(/product-table-row-[0-9]+/gm);
			const regex = regexes?.length && regexes[0];
			if (!regex) {
				return;
			}
			const query = regex.replace('product-table-row-', '');
			if (!query) {
				return;
			}
			const productId = parseInt(query);
			if (!productId) {
				return;
			}
			setSelectedRowKey(productId);
			const product = rows.find((r) => r.id === productId);
			if (product) {
				rowClick(product, activeLanguageRef.current);
			}
		},
		[rowClick, rows],
	);

	useEffect(() => {
		const rows = document.querySelectorAll('.product-table .rc-table-row');
		for (let i = 0; i < rows.length; i++) {
			rows[i].addEventListener('click', (e) => handleClick(e, rows[i]));

			if (!database) {
				const tdElements = rows[i].querySelectorAll('td');
				if (tdElements.length >= 5) {
					tdElements[4].addEventListener('click', (e) => {
						e.stopImmediatePropagation();
						const productId = keyRows[i]?.id;
						const product = keyRows.find((row) => row.id === productId);
						const companyId = product?.company?.id;
						if (companyId && product) {
							setSelectedRowKey(product.id);
							openCompany && openCompany(companyId);
						}
					});
				}
			}
		}

		return () => {
			const rows = document.querySelectorAll('.product-table .rc-table-row');
			for (let i = 0; i < rows.length; i++) {
				rows[i].removeEventListener('click', (e) => handleClick(e, rows[i]));

				if (!database) {
					const tdElements = rows[i].querySelectorAll('td');
					if (tdElements.length >= 5) {
						tdElements[4].removeEventListener('click', (e) => {
							e.stopImmediatePropagation();
							const productId = keyRows[i]?.id;
							const product = keyRows.find((row) => row.id === productId);
							const companyId = product?.company?.id;
							if (companyId && product) {
								setSelectedRowKey(product.id);
								openCompany && openCompany(companyId);
							}
						});
					}
				}
			}
		};
	}, [handleClick, keyRows, openCompany, database]);

	return (
		<>
			<Table
				className={`product-table ${rows.length === 0 ? 'h-full' : ''} ${
					database ? 'database-product-table' : ''
				}`}
				columns={database ? databaseColumns(search) : columns(search)}
				data={keyRows}
				rowClassName={getTrProps}
				emptyText={() => {
					return (
						<div className="flex flex-col justify-center items-center h-[calc(100vh-371px)] gap-8">
							<NoResults />
							<SypacText variant="heading-3">
								<p className="text-gray-80">
									{search ? (
										<T keyName="productTable.noResultFoundSearch">
											Feature is not available at the moment
										</T>
									) : (
										<T keyName="productTable.noResultFound">No products yet.</T>
									)}
								</p>
							</SypacText>
							<SypacText variant="body-normal-small">
								<p className="text-gray-50">
									{search ? (
										<T keyName="productTable.noResultFoundDescription">
											Lorem ipsum dolor sit amet, consectetur adipiscing elit,
											sed do eiusmod tempor incididunt
										</T>
									) : null}
								</p>
							</SypacText>
						</div>
					);
				}}
			/>
		</>
	);
};
