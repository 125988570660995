import React, { useCallback, useEffect, useState } from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { SypacText } from '@sypac/component-library-react';
import dayjs from 'dayjs';
import { PaymentsTable } from '../../../components/PaymentsTable/PaymentsTable';
import BillingTabs from '../../../components/BillingTabs/BillingTabs';
import { useGetStats } from '../../../hooks/use-get-stats';
import { BillingTab } from '../../../components/BillingTabs/BillingTabs.interface';
import Pagination from '../../../components/Pagination/Pagination';
import { LIMIT } from '../../../constants';
import ProducerBillingStatistic from '../../../components/ProducerBillingStatistic/ProducerBillingStatistic';
import { useGetInvoicesLines } from '../../../hooks/use-get-invoices-lines';
import { useGetInvoices } from '../../../hooks/use-get-invoices';
import { InvoiceLinesTable } from '../../../components/InvoiceLinesTable/InvoiceLinesTable';
import { useGetInvoiceLinesStats } from '../../../hooks/use-get-lines-stats';
import OrderDetails from '../OrderDetails';
import { OrderInterface } from '../../../components/OrdersTable/interfaces/Order.interface';
import { OrdersService } from '../../../services/orders.services';
import { OrderTarget } from '../../../components/OrdersTable/interfaces/OrderStatus.interface';
import { InvoiceInterface } from '../../../components/PaymentsTable/interfaces/Payment.interface';
import { PaymentTypeAction } from '../../../components/PaymentsTable/interfaces/PaymentStatus.interface';
import { T, useTranslate } from '@tolgee/react';
import { PaymentPeriod } from '../../../components/PyamentPeriod';
import { useDetectClickOutside } from 'react-detect-click-outside';
import SearchBar from '../../../components/SearchBar/SearchBar';
import useDebounce from '../../../hooks/useDebounce';
import {
	CompanyResponse,
	CompanyService,
} from '../../../services/company.services';
import { toastVariant } from '../../../components/CompaniesTable/toastVariant/toastVariant';

const TransporterBilling: React.FC = () => {
	const { t } = useTranslate();
	const [searchLocal, setSearchLocal] = useState<string>('');
	const [currentTab, setCurrentTab] = useState<string>('all');
	const [page, setPage] = useState<number>(0);
	const [invoicesPage, setInvoicesPage] = useState<number>(0);
	const [isOpen, setOpenDetails] = useState<boolean>(false);
	const [selectedOrder, setOrder] = useState<OrderInterface | undefined>(
		undefined,
	);
	const searchQuery = useDebounce(searchLocal, 500);
	const [myCompany, setMyCompany] = useState<CompanyResponse>();

	const getMyCompanyCallback = useCallback(async () => {
		try {
			const { data } = await CompanyService.getMeCompany();
			setMyCompany(data);
		} catch (error) {
			return toastVariant(`Something went wrong. ${error?.toString()!}`, true);
		}
	}, []);

	useEffect(() => {
		getMyCompanyCallback().then(() => {});
	}, [getMyCompanyCallback]);

	const selectTab = (tab: string) => {
		setCurrentTab(tab);
		setPage(0);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesList, _, invoicesAreLoading] = useGetInvoices({
		limit: LIMIT,
		offset: invoicesPage * LIMIT,
		target: 'transporter',
	});

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [invoicesLines, lerr, linesAreLoading] = useGetInvoicesLines({
		// orderId: +searchQuery,
		status: currentTab,
		limit: LIMIT,
		offset: page * LIMIT,
		target: 'transporter',
		search: searchQuery,
	});

	const [stats] = useGetStats({
		target: 'transporter',
		refresh: 0,
	});
	const [statsLines] = useGetInvoiceLinesStats({ target: 'transporter' });
	const [stats30] = useGetStats({
		target: 'transporter',
		fromDate: dayjs().subtract(30, 'days').format(),
		refresh: 0,
	});

	const [stats60] = useGetStats({
		target: 'transporter',
		fromDate: dayjs().subtract(60, 'days').format(),
		refresh: 0,
	});
	const [stats90] = useGetStats({
		target: 'transporter',
		fromDate: dayjs().subtract(90, 'days').format(),
		refresh: 0,
	});
	const billingStats = {
		amountPaid: stats?.amountTotal?.pending!,
		amountPending: stats?.amount!,
		allOrders: stats?.countOrders?.all!,
		ordersLast30Days: stats30?.countOrders?.all!,
		ordersLast60Days: stats30?.countOrders?.all!,
		paidOrders: stats?.countOrders?.paid!,
		orderInProgress: stats?.countOrders?.inProgress!,
		ordersInDispute: stats?.countOrders?.dispute!,
		revenueLast30Days: stats30?.amountTotal?.all!,
		revenueLast60Days: stats60?.amountTotal?.all!,
		revenueLast90Days: stats90?.amountTotal?.all!,
		paymentTerm: stats?.paymentTerm!,
		nextInvoicingDate: stats?.nextInvoicingDate!,
		countryCode: myCompany?.countryCode!,
	};

	const tabs: BillingTab[] = [
		{
			label: t('transporterBilling.all', 'All'),
			value: 'all',
			color: 'white',
			count: statsLines?.all!,
		},
		{
			label: t('transporterBilling.pendingPayment', 'Pending payment'),
			value: 'pending',
			color: 'yellow',
			count: statsLines?.pending!,
		},
		{
			label: t('transporterBilling.paymentDispute', 'Payment dispute'),
			value: 'overdue',
			color: 'red',
			count: statsLines?.overdue!,
		},
		{
			label: t('transporterBilling.paid', 'Paid'),
			value: 'paid',
			color: 'green',
			count: statsLines?.paid!,
		},
	];

	const viewOrder = async (orderId?: number) => {
		try {
			const { data } = await OrdersService.getOrder(
				orderId!,
				OrderTarget.transporter,
			);
			setOrder(data as unknown as OrderInterface);
			setOpenDetails(true);
		} catch (e) {
			console.log(e);
		}
	};

	const additionalClick = (value: InvoiceInterface | number, type?: string) => {
		if (type === PaymentTypeAction.view_order) {
			viewOrder(value as number).then(() => {});
		}
	};

	const closeModal = () => {
		setOrder(undefined);
		setOpenDetails(false);
	};

	const detailsRef = useDetectClickOutside({
		onTriggered: () => closeModal(),
	});

	return (
		<>
			<div className="flex gap-8 w-[calc(100vw-77px)] xl-2xl:w-[calc(100vw-94px)] h-full">
				<div className="w-[900px] flex flex-col gap-5">
					<div className="flex flex-col gap-4 ml-5">
						<div className="flex justify-between">
							<SypacText variant="heading-4">
								<p className="text-gray-80">
									<T keyName="producerBilling.manageYourWalletBilling">
										Manage your wallet & billing
									</T>
								</p>
							</SypacText>

							{/* <SypacButton variant="secondary" size="small">
								<button className="h-[42px] flex justify-center items-center px-[57px] bg-transparent rounded-lg transition">
									<SypacText variant="body-regular-medium">
										<p className="text-base text-gray-80">
											<T keyName="producerBilling.contactSypac">
												Contact Sypac
											</T>
										</p>
									</SypacText>
								</button>
							</SypacButton> */}
						</div>

						<SypacText variant="body-regular-small">
							<p className="text-base text-gray-40">
								<T keyName="clientBilling.downloadInvoicesControlBillingDetails">
									Download invoices and control your billing details.
								</T>
							</p>
						</SypacText>
					</div>

					{/* <div className="ml-5">
						<SearchBar
							placeholder={t('transporterBilling.searchOrder', 'Search order')}
							onChange={setSearchLocal}
							classNames="w-full"
						/>
					</div> */}

					<div className="border-0 border-solid border-t-[1px] border-gray-10" />

					<div className="ml-5 mr-2">
						<BillingTabs
							activeTab={currentTab}
							data={tabs}
							callback={selectTab}
							classNames="w-full h-[38px]"
						/>
					</div>

					{linesAreLoading ? (
						<div className="flex w-full h-full items-center justify-center">
							<MutatingDots
								height="100"
								width="100"
								color="#7693F4"
								secondaryColor="#494C83"
								radius="12.5"
								ariaLabel="mutating-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div className="h-full relative border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap ml-5">
							<div
								className={`w-full overflow-y-auto scroll-smooth ${
									invoicesLines?.items.length
										? 'h-[calc(100%-52px)]'
										: 'h-[calc(100%+2px)]'
								}`}
							>
								<InvoiceLinesTable
									target={OrderTarget.transporter}
									rows={invoicesLines?.items || []}
									rowClick={() => {}}
									currentTab={currentTab}
								/>
							</div>
							{invoicesLines?.items.length ? (
								<div className="w-full absolute bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
									<div className="flex justify-between items-center h-[51px] px-3">
										<Pagination
											showText={true}
											count={invoicesLines?.count!}
											page={page}
											onClick={(item) => setPage(item)}
										/>
									</div>
								</div>
							) : null}
						</div>
					)}
				</div>

				<div className="w-[calc(100vw-1036px)] flex flex-col xl:gap-8 mr-7.5">
					<ProducerBillingStatistic {...billingStats} />

					{invoicesAreLoading ? (
						<div className="flex w-full h-full items-center justify-center">
							<MutatingDots
								height="100"
								width="100"
								color="#7693F4"
								secondaryColor="#494C83"
								radius="12.5"
								ariaLabel="mutating-dots-loading"
								wrapperStyle={{}}
								wrapperClass=""
								visible={true}
							/>
						</div>
					) : (
						<div className="h-full relative border border-solid border-gray-10 rounded-10 overflow-hidden whitespace-nowrap">
							<div className="flex justify-between p-3">
								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="paymentsTable.withdrawHistory">
											Withdraw history
										</T>
									</p>
								</SypacText>

								<SypacText variant="body-regular-medium">
									<p className="text-gray-80">
										<T keyName="paymentsTable.withdrawInterval">
											Withdraw interval:
										</T>{' '}
										<PaymentPeriod value={stats?.paymentTerm!} />
									</p>
								</SypacText>
							</div>

							<div
								className={`w-full overflow-y-auto scroll-smooth border-0 border-solid border-t-[1px] border-gray-10 rounded-t-10 ${
									invoicesList?.items.length
										? 'h-[calc(100%-98px)]'
										: 'h-[calc(100%+2px)]'
								}`}
							>
								<PaymentsTable
									target="withdraw_history"
									rows={invoicesList?.items || []}
									rowClick={() => {}}
									additionalClick={additionalClick}
								/>
							</div>
							{invoicesList?.items.length ? (
								<div className="w-full absolute bg-alabaster bottom-0 border-0 border-t border-solid border-t-gray-10 rounded-tl-10 rounded-tr-10 shadow-pagination">
									<div className="flex justify-between items-center h-[51px] px-3">
										<Pagination
											showText={true}
											count={invoicesList?.count!}
											page={invoicesPage}
											onClick={(item) => setInvoicesPage(item)}
										/>
									</div>
								</div>
							) : null}
						</div>
					)}
				</div>
			</div>
			{isOpen && selectedOrder?.id ? (
				<div ref={detailsRef}>
					<OrderDetails
						isOpen={!!(isOpen && selectedOrder?.id)}
						selectedOrder={selectedOrder}
					/>
				</div>
			) : null}
		</>
	);
};

export default TransporterBilling;
