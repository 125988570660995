import React, { useEffect, useMemo, useState } from 'react';
import './map.css';
import {
	Circle,
	MapContainer,
	Polyline,
	TileLayer,
	useMap,
} from 'react-leaflet';
import { MarkerLayer, Marker } from 'react-leaflet-marker';
import {
	GroupedMarkers,
	LatLng,
	MapMarker,
	MapProps,
	MapView,
	MarkerContentProps,
	MarkerIconProps,
	PinContentProps,
	PinVariant,
	RadiusCircleProps,
	RouteColorVariant,
	ZoomLevel,
} from './Map.interface';
import { Pickup } from '../../assets/Pickup';
import { Delivery } from '../../assets/Delivery';
import { TruckPin } from '../../assets/TruckPin';
import { SypacText } from '@sypac/component-library-react';
import { OrderStatusesEnum } from '../../services/orders.services';
import { CompletedPin } from '../../assets/CompletedPin';
import { TruckPinClient } from '../../assets/TruckPinClient';
import { DeliveryClient } from '../../assets/DeliveryClient';

import Flag from 'react-world-flags';
import { OrderTarget } from '../OrdersTable/interfaces/OrderStatus.interface';
import { MapPin } from '../../assets/MapPin';
import { PlusIcon } from '../../assets/PlusIcon';
import { MinusIcon } from '../../assets/MinusIcon';

const ChangeMapView = (props: MapView) => {
	const { coords } = props;
	const map = useMap();
	map.setView([coords.lat, coords.lng], map.getZoom());
	return null;
};

const zoomLevels: ZoomLevel = {
	25: 11,
	50: 10,
	75: 9,
	100: 9,
	200: 8,
};

const radiusValues = [25, 50, 75, 100, 200];

const Map: React.FC<MapProps> = ({
	polygons,
	markers,
	markerHovered,
	orderStatus,
	currentMarker,
	target = 'transporter',
	setBaseRadius,
}) => {
	const [center, setCenter] = useState<LatLng>({
		lat: 52.232938,
		lng: 21.0611941,
	});
	const [initialZoom, setInitialZoom] = useState<number>(10);
	const [currentRadius, setCurrentRadius] = useState<number | undefined>(
		undefined,
	);
	const [leftEdge, setLeftEdge] = useState<Partial<LatLng> | undefined>(
		undefined,
	);
	const [rightEdge, setRightEdge] = useState<Partial<LatLng> | undefined>(
		undefined,
	);

	const productLocation = useMemo(
		() => markers?.find((r) => r.variant === PinVariant.product),
		[markers],
	);
	const baseLocation = useMemo(
		() => markers?.find((r) => r.variant === PinVariant.fleet_base),
		[markers],
	);

	useEffect(() => {
		if (baseLocation) {
			setCenter({
				lat: baseLocation.latitude,
				lng: baseLocation.longitude,
			});
			setCurrentRadius(baseLocation?.radius?.value);
		} else if (markers?.length) {
			const variants = [
				PinVariant.truck,
				PinVariant.pickup,
				PinVariant.product,
				PinVariant.delivery,
				PinVariant.product_store,
			];
			let start = variants
				.map((v) => markers.find((r) => r.variant === v))
				.find((result) => result);

			if (start) {
				setCenter({
					lat: start.latitude,
					lng: start.longitude,
				});
			}
		} else if (polygons?.length) {
			const start = polygons[0].coordinates[0];
			if (start) {
				setCenter(start);
			}
		} else if (currentMarker) {
			setCenter({ lat: currentMarker.latitude, lng: currentMarker.longitude });
		}
	}, [polygons, markers, currentMarker, baseLocation]);

	const iconTransporter = (variant: string, truckStatus?: string) => {
		if (
			orderStatus &&
			[
				OrderStatusesEnum.COMPLETED.toString(),
				OrderStatusesEnum.DELIVERED.toString(),
			].includes(orderStatus)
		) {
			return <CompletedPin />;
		}

		const variantMap: any = {
			[PinVariant.pickup]: <Pickup />,
			[PinVariant.delivery]: <Delivery />,
			[PinVariant.product]: <MapPin color="#FFB352" />,
			[PinVariant.fleet_base]: <MapPin color="#FFB352" />,
			[PinVariant.product_store]: <MapPin color="#FFB352" />,
		};

		return variantMap[variant] || <TruckPin status={truckStatus} />;
	};

	const iconCustomer = (variant: string) => {
		const customerIcons: any = {
			[PinVariant.pickup]: <MapPin color="#FFB352" fillColor="white" />,
			[PinVariant.delivery]: <DeliveryClient />,
		};

		return customerIcons[variant] || <TruckPinClient />;
	};

	const icon = (variant: string, truckStatus?: string) => {
		return target === OrderTarget.customer
			? iconCustomer(variant)
			: iconTransporter(variant, truckStatus);
	};

	const colorRoute = (orderRoute: boolean) => {
		// if (target === 'customer') {
		// 	return RouteColorVariant.customer;
		// }
		if (
			orderStatus &&
			[
				OrderStatusesEnum.COMPLETED.toString(),
				OrderStatusesEnum.DELIVERED.toString(),
			].includes(orderStatus)
		) {
			return RouteColorVariant.completed;
		}
		return orderRoute
			? RouteColorVariant.orderRoute
			: RouteColorVariant.toPickup;
	};

	const PinContent = ({
		countryCode,
		address,
		orderProduct,
		productStore,
	}: PinContentProps) => (
		<div
			className={`h-fit flex rounded-lg bg-white border border-solid border-gray-10 box-border ${
				!orderProduct
					? 'w-fit px-1 mt-4'
					: markerHovered === orderProduct?.name
					? 'w-[100px] -mt-[128px] p-[3px] scale-[1.4] shadow-notify'
					: 'w-[82px] -mt-[108px] p-[2px] shadow-dropdown'
			} ${productStore ? '-mt-[114px]' : ''}`}
		>
			<div className="flex items-center">
				{countryCode && (
					<Flag
						className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
						code={countryCode}
						width={22}
						height={16}
					/>
				)}

				{address && (
					<SypacText
						variant="body-regular-medium"
						className={countryCode ? 'ml-3' : ''}
					>
						<p className="max-w-[344px] text-gray-80 truncate">{address}</p>
					</SypacText>
				)}

				{orderProduct && (
					<div className="flex flex-col gap-[3px]">
						<div
							style={{
								backgroundImage: `url(${orderProduct.photoUrl})`,
							}}
							className="flex flex-none w-[32px] h-[32px] bg-no-repeat bg-cover rounded-[8px] border border-solid border-gray-10"
						/>

						<div className="flex flex-col gap-[2px]">
							<SypacText variant="body-normal-medium">
								<p
									className={`text-[10px] leading-[14px] text-gray-80 truncate ${
										!orderProduct.type && !orderProduct.size ? 'pb-[14px]' : ''
									} ${
										markerHovered === orderProduct?.name
											? 'w-[94px]'
											: 'w-[76px]'
									}`}
								>
									{orderProduct.name}
								</p>
							</SypacText>
							<SypacText variant="body-normal-medium">
								<p
									className={`text-[8px] leading-[12px] text-gray-40 truncate ${
										markerHovered === orderProduct?.name
											? 'w-[94px]'
											: 'w-[76px]'
									}`}
								>
									{orderProduct.type}{' '}
									{orderProduct.size && `(${orderProduct.size})`}
								</p>
							</SypacText>
						</div>
					</div>
				)}

				{productStore && (
					<div className="flex flex-col gap-1 p-1 whitespace-nowrap">
						<SypacText variant="body-regular-medium">
							<p>{productStore.name}</p>
						</SypacText>

						<SypacText variant="body-regular-medium">
							<p className="text-gray-40">
								Products in store: {productStore.productCount}
							</p>
						</SypacText>
					</div>
				)}
			</div>
		</div>
	);

	const MarkerContent = ({
		marker,
		delivery,
		pickup,
		fleetBase,
		orderProduct,
		productStore,
	}: MarkerContentProps) => {
		switch (marker.variant) {
			case PinVariant.delivery:
				return (
					<PinContent
						countryCode={delivery?.deliveryCountryCode}
						address={delivery?.deliveryAddress}
					/>
				);
			case PinVariant.pickup:
				if (target === OrderTarget.transporter) {
					return (
						<PinContent
							countryCode={pickup?.pickupCountryCode}
							address={pickup?.pickupAddress}
						/>
					);
				} else {
					return <PinContent orderProduct={orderProduct} />;
				}
			case PinVariant.fleet_base:
				return (
					<PinContent
						countryCode={fleetBase?.countryCode}
						address={fleetBase?.address}
					/>
				);
			case PinVariant.product_store:
				return <PinContent productStore={productStore} />;
			default:
				return null;
		}
	};

	const RadiusCircle = ({ location, radius = 1000 }: RadiusCircleProps) => {
		return (
			<Circle
				center={[location.latitude, location.longitude]}
				pathOptions={{
					color: '#20AC93',
					weight: 1,
					fillColor: 'rgba(32, 172, 147, 0.5)',
				}}
				radius={radius}
			/>
		);
	};

	const updateZoomLevel = (radius: number) =>
		zoomLevels[radius] ?? (productLocation ? 14 : 10);

	useEffect(() => {
		if (typeof currentRadius === 'number') {
			setInitialZoom(updateZoomLevel(currentRadius));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRadius]);

	const handleRadiusChange = (action: 'decrease' | 'increase') => {
		const currentIndex = currentRadius
			? radiusValues.indexOf(currentRadius)
			: -1;
		if (currentIndex !== -1) {
			if (action === 'decrease' && currentIndex > 0) {
				setCurrentRadius(radiusValues[currentIndex - 1]);
				if (setBaseRadius) {
					setBaseRadius(radiusValues[currentIndex - 1]);
				}
			} else if (
				action === 'increase' &&
				currentIndex < radiusValues.length - 1
			) {
				setCurrentRadius(radiusValues[currentIndex + 1]);
				if (setBaseRadius) {
					setBaseRadius(radiusValues[currentIndex + 1]);
				}
			}
		}
	};

	const getCircleEdgeCoords = (center: LatLng, radiusInMeters: number) => {
		const earthRadius = 6378137;
		const latInRad = (center.lat * Math.PI) / 180;
		const deltaLng =
			(radiusInMeters / (earthRadius * Math.cos(latInRad))) * (180 / Math.PI);

		const leftEdge = {
			lng: center.lng - deltaLng,
		};

		const rightEdge = {
			lng: center.lng + deltaLng,
		};

		return { leftEdge, rightEdge };
	};

	useEffect(() => {
		if (currentRadius && center) {
			const { leftEdge, rightEdge } = getCircleEdgeCoords(
				center,
				currentRadius * 1000,
			);
			setLeftEdge(leftEdge);
			setRightEdge(rightEdge);
		}
	}, [currentRadius, center]);

	const TruckInfo = ({ truck }: any) => (
		<div
			className={`py-[1px] px-4 rounded-3xl flex flex-row ${
				truck?.status === 'not_available'
					? 'bg-texas-rose'
					: 'bg-mountain-meadow'
			}`}
		>
			{truck?.truckModel ? (
				<SypacText variant="body-regular-small">
					<p className="font-medium text-white max-w-[60px] truncate">
						{truck?.truckModel}
					</p>
				</SypacText>
			) : null}
			{truck?.id ? (
				<SypacText variant="body-regular-small">
					<p className="font-medium text-white">&nbsp;#{truck?.id}</p>
				</SypacText>
			) : null}
		</div>
	);

	const MarkerIcon = ({
		fleetBase,
		orderProduct,
		productStore,
		markerHovered,
		variant,
		status,
	}: MarkerIconProps) => (
		<div
			className={`${
				fleetBase || productStore
					? 'w-[42px] h-[42px] flex justify-center items-center bg-white rounded-full'
					: ''
			} ${
				orderProduct && markerHovered === orderProduct.name
					? 'w-[32px] h-[32px] flex justify-center items-center bg-white rounded-full'
					: ''
			}`}
		>
			{icon(variant, status)}
		</div>
	);

	const renderMarkerContent = (
		marker: MapMarker,
		shouldShowCluster = false,
		orderProductCount?: number,
	) => {
		const truck = marker?.truck;
		const delivery = marker?.delivery;
		const pickup = marker?.pickup;
		const fleetBase = marker?.fleetBase || marker?.store;
		const orderProduct = marker?.orderProduct;
		const productStore = marker?.store;

		return (
			<div className="flex flex-col items-center -mt-5">
				<div className="relative">
					{shouldShowCluster ? (
						<div className="w-[44px] h-[44px] flex justify-center items-center bg-midnight-blue rounded-full">
							<SypacText variant="body-regular-medium">
								<p className="text-white">{orderProductCount}</p>
							</SypacText>
						</div>
					) : (
						<MarkerIcon
							fleetBase={fleetBase!}
							orderProduct={orderProduct}
							productStore={productStore!}
							markerHovered={markerHovered!}
							variant={marker.variant as PinVariant}
							status={truck?.status}
						/>
					)}
				</div>

				{!shouldShowCluster &&
					marker.variant === PinVariant.truck &&
					target === OrderTarget.transporter &&
					truck?.id && <TruckInfo truck={truck} />}

				{!shouldShowCluster && (
					<MarkerContent
						marker={marker}
						delivery={delivery}
						pickup={pickup}
						fleetBase={fleetBase}
						orderProduct={orderProduct}
						productStore={productStore}
					/>
				)}
			</div>
		);
	};

	return (
		<div className="w-full h-full relative">
			{currentMarker && currentMarker.variant ? (
				<div className="absolute z-[500] top-12 flex flex-row w-full justify-center">
					<div className="rounded-lg bg-cornflower-blue px-4 py-2 flex flex-col">
						<SypacText variant="body-regular-large">
							<p className="text-white">
								Current location: 7 Rue Fernande Folral str., Charleroi, BE
							</p>
						</SypacText>
						<SypacText variant="overline-regular-large" className="mt-3">
							<p className="text-white">
								Latitude: 50.4, Longitude: 4.4333350° 24′ 0″ North, 4° 25′ 60″
								East
							</p>
						</SypacText>
					</div>
				</div>
			) : null}

			<MapContainer
				center={[center.lat, center.lng]}
				zoom={
					baseLocation?.radius?.isAdjustable ? initialZoom : initialZoom - 2
				}
				scrollWheelZoom={true}
				zoomControl={false}
				className="w-full h-full rounded-10 z-0"
				key={`${center.lat}${center.lng}${initialZoom}`}
			>
				<TileLayer
					url="https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=kfaiWdCWdSlyi1Ye96mu"
					attribution={`<div>Logo</div>`}
					tileSize={512}
					zoomOffset={-1}
				/>

				{currentMarker && currentMarker.variant ? (
					<MarkerLayer>
						<Marker
							position={[currentMarker.latitude, currentMarker.longitude]}
							size={[100, 32]}
							placement="center"
						>
							<div className="flex flex-col items-center -mt-5">
								{icon(currentMarker.variant, currentMarker?.truck?.status)}

								{currentMarker.variant === PinVariant.truck &&
								currentMarker?.truck ? (
									<>
										<div
											className={`py-[1px] px-4 rounded-3xl flex flex-row ${
												currentMarker?.truck?.status === 'not_available'
													? 'bg-texas-rose'
													: 'bg-mountain-meadow'
											}`}
										>
											<SypacText variant="body-regular-small">
												<p className="font-medium text-white max-w-[60px] truncate">
													{currentMarker.truck?.truckModel}
												</p>
											</SypacText>
											<SypacText variant="body-regular-small">
												<p className="font-medium text-white">
													&nbsp;#{currentMarker.truck?.id}
												</p>
											</SypacText>
										</div>
										<div className="p-3 flex flex-row bg-white rounded-10 mt-2 shadow-map-card">
											<div
												style={{
													backgroundImage: `url(${
														currentMarker.truck?.truckPhoto
															? currentMarker.truck?.truckPhoto
															: '/truck.jpg'
													})`,
												}}
												className="flex w-[58px] bg-center bg-cover bg-no-repeat h-[58px] rounded-md"
											/>
											<div className="ml-3 flex flex-col justify-center">
												<div className="flex gap-1.5 items-center">
													<SypacText variant="overline-regular-large">
														<p className="text-gray-90">
															{currentMarker.truck?.truckModel}
														</p>
													</SypacText>
													<SypacText variant="overline-regular-large">
														<p className="text-gray-90">
															#{currentMarker.truck?.id}
														</p>
													</SypacText>
												</div>
												<div className="bg-gray-10-opacity-50 rounded-md px-4 py-1 mt-2">
													{currentMarker.truck?.truckNumber}
												</div>
											</div>
										</div>
									</>
								) : null}
							</div>
						</Marker>
					</MarkerLayer>
				) : null}

				{markers?.length
					? (() => {
							if (target === OrderTarget.customer) {
								const groupedMarkers: GroupedMarkers = markers.reduce(
									(acc: GroupedMarkers, marker: MapMarker) => {
										const key = `${marker.latitude},${marker.longitude}`;
										if (!acc[key]) {
											acc[key] = [];
										}
										acc[key].push(marker);
										return acc;
									},
									{},
								);

								return Object.entries(groupedMarkers).map(
									([coords, markerGroup], index) => {
										const [latitude, longitude] = coords.split(',').map(Number);
										const firstMarker: MapMarker = markerGroup[0];
										const orderProductCount = markerGroup.filter(
											(m: MapMarker) => m.orderProduct,
										).length;
										const shouldShowCluster = orderProductCount >= 2;

										return (
											<MarkerLayer key={index}>
												<Marker
													position={[latitude, longitude]}
													size={[100, 32]}
													placement="center"
												>
													{renderMarkerContent(
														firstMarker,
														shouldShowCluster,
														orderProductCount,
													)}
												</Marker>
											</MarkerLayer>
										);
									},
								);
							}

							return markers.map((marker, index) => (
								<MarkerLayer key={index}>
									<Marker
										position={[marker.latitude, marker.longitude]}
										size={[100, 32]}
										placement="center"
									>
										{renderMarkerContent(marker)}
									</Marker>
								</MarkerLayer>
							));
					  })()
					: null}

				{polygons?.length
					? polygons?.map((polygon, index) => (
							<Polyline
								key={index}
								positions={polygon.coordinates}
								color={colorRoute(polygon.orderRoute)}
							/>
					  ))
					: null}

				{center ? <ChangeMapView coords={center} /> : null}

				{productLocation ? <RadiusCircle location={productLocation} /> : null}

				{baseLocation?.radius?.value ? (
					<>
						<RadiusCircle
							location={baseLocation!}
							radius={currentRadius ? currentRadius * 1000 : 1000}
						/>

						{baseLocation?.radius?.isAdjustable && leftEdge && rightEdge ? (
							<MarkerLayer>
								<Marker position={[center.lat, leftEdge.lng!]} size={[52, 52]}>
									<button
										type="button"
										onClick={() => handleRadiusChange('decrease')}
										className="w-[52px] h-[52px]  flex justify-center items-center bg-texas-rose border-2 border-solid border-white rounded-full cursor-pointer -mt-[32px]"
									>
										<span className="scale-[1.6] m-0 p-0 mt-[4px]">
											<MinusIcon color="white" />
										</span>
									</button>
								</Marker>

								<Marker position={[center.lat, rightEdge.lng!]} size={[52, 52]}>
									<button
										type="button"
										onClick={() => handleRadiusChange('increase')}
										className="w-[52px] h-[52px] flex justify-center items-center bg-mountain-meadow border-2 border-solid border-white rounded-full cursor-pointer -mt-[52px]"
									>
										<span className="scale-[1.6] m-0 p-0 mt-[4px]">
											<PlusIcon color="white" />
										</span>
									</button>
								</Marker>
							</MarkerLayer>
						) : null}
					</>
				) : null}
			</MapContainer>
		</div>
	);
};

export default Map;
