import React, { useState } from 'react';
import {
	SypacAvatar,
	SypacButton,
	SypacIcon,
	SypacText,
} from '@sypac/component-library-react';
import { T, useTranslate } from '@tolgee/react';
import SearchBar from '../../../components/SearchBar/SearchBar';
import Flag from 'react-world-flags';
import DotsDropdown from '../../../components/DotsDropdown/DotsDropdown';
import {
	activeFieldInterface,
	SettingUsersProps,
} from '../userSettings.interface';
import ModalManageMember from './ModalManageMember';

const SettingUsers: React.FC<SettingUsersProps> = ({ profiles, company }) => {
	const { t } = useTranslate();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [modalAction, setModalAction] = useState<activeFieldInterface>({
		type: null,
		value: '',
	});

	const editMember = () => {
		setModalAction({ type: 'edit', value: '' });
		setOpenModal(true);
	};

	const addNewMember = () => {
		setModalAction({ type: 'add', value: '' });
		setOpenModal(true);
	};

	const deleteMember = () => {};

	const handleCloseModal = () => {
		setOpenModal(false);
		setModalAction({
			type: null,
			value: '',
		});
	};

	const Proprieties: any = {
		options: [
			{
				id: 1,
				title: t('userSettings.editMember', 'Edit member'),
				action: () => editMember(),
			},
			{
				id: 2,
				title: t('truckCard.deleteMember', 'Delete member'),
				action: () => deleteMember(),
			},
		],
	};

	const isOwner = true;

	return (
		<>
			<div className={`flex flex-col ${false ? 'overflow-hidden' : ''}`}>
				<div className="flex flex-col gap-[14px] mb-[24px]">
					<SypacText variant="heading-4">
						<p className="text-xl text-gray-80">
							<T keyName="userSettings.members">Members</T>
						</p>
					</SypacText>

					<SypacText variant="heading-4">
						<p className="text-base text-gray-40">
							<T keyName="userSettings.addMembersToWorkspace">
								Add members to collaborate within a workspace.
							</T>
						</p>
					</SypacText>
				</div>

				<div className="flex gap-6 py-6 border-0 border-t border-b border-solid border-gray-10">
					<SearchBar
						placeholder={t('userSettings.searchByName', 'Search by name...')}
						// onClick={setSearchQuery}
						showButton={false}
						classNames="w-full"
					/>

					<SypacButton variant="secondary" size="small">
						<button
							type="button"
							className="h-[44px] flex gap-[6px] w-[219px] py-[6px] rounded-lg border-0 bg-primary-violet transition hover:bg-primary-violet/70"
							onClick={addNewMember}
						>
							<SypacIcon
								iconName="User Plus"
								size="custom"
								width="30px"
								height="30px"
								className="text-white"
							/>
							<SypacText variant="body-regular-medium">
								<p className="text-white mt-[2px]">
									<T keyName="userSettings.addNewUser">Add new user</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>

				<div
					className={`flex flex-col gap-6 pt-[24px] ${
						false ? 'overflow-y-scroll' : ''
					}`}
				>
					{profiles?.map((profile) => {
						return (
							<div className="flex gap-6 pb-[24px] border-0 border-b border-solid border-gray-10">
								<span className="scale-[1.25] ml-[7px]">
									<SypacAvatar
										size="md"
										initials={profile?.firstName + ' ' + profile?.lastName}
									>
										{profile?.photoUrl ? (
											<img
												src={profile?.photoUrl}
												alt={profile?.firstName + ' ' + profile?.lastName}
											/>
										) : null}
									</SypacAvatar>
								</span>

								<div className="flex flex-col gap-[10px]">
									<div className="flex gap-[20px]">
										<SypacText variant="body-regular-medium">
											<p className="text-[20px] text-gray-80">
												{profile?.firstName + ' ' + profile?.lastName}
											</p>
										</SypacText>

										<div
											className={`flex justify-center items-center px-[26.5px] rounded-full ${
												isOwner ? 'bg-primary-violet' : 'bg-texas-rose/30'
											}`}
										>
											{isOwner ? (
												<SypacText variant="body-regular-medium">
													<p className="text-xs text-white">
														<T keyName="userSettings.profileOwner">
															Profile owner
														</T>
													</p>
												</SypacText>
											) : (
												<SypacText variant="body-regular-medium">
													<p className="text-xs text-gray-80">
														<T keyName="userSettings.moderator">Moderator</T>
													</p>
												</SypacText>
											)}
										</div>
									</div>

									<div className="flex items-center gap-3">
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40">{profile?.email}</p>
										</SypacText>
										<div className="w-[2px] h-[2px] bg-gray-40 rounded-full" />

										<div className="flex gap-4">
											<Flag
												className="w-[20px] h-[14px] object-cover border border-solid border-gray-10 rounded"
												code={profile?.countryCode}
												width={22}
												height={16}
											/>
											<SypacText variant="body-regular-medium">
												<p className="text-sm text-gray-40">
													{profile?.phoneNumber?.phoneNumber}
												</p>
											</SypacText>
										</div>
									</div>
								</div>

								{!isOwner ? (
									<div className="flex gap-[20px] ml-auto">
										<SypacText variant="body-regular-medium">
											<p className="text-sm text-gray-40 mt-[2px]">
												<T keyName="userSettings.created">Created:</T> 12 May,
												2024
											</p>
										</SypacText>
										<DotsDropdown options={Proprieties.options} />
									</div>
								) : null}
							</div>
						);
					})}
				</div>
			</div>
			<ModalManageMember
				isOpen={openModal}
				onClose={handleCloseModal}
				modalAction={modalAction}
				company={company}
			/>
		</>
	);
};

export default SettingUsers;
