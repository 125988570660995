import React from 'react';
import {
	SypacButton,
	SypacInput,
	SypacText,
} from '@sypac/component-library-react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { T, useTranslate } from '@tolgee/react';
import Close from '../../../assets/Close';
import { ModalChangeRequestProps } from '../userSettings.interface';
import {
	ChangeRequest,
	CompanyService,
} from '../../../services/company.services';

const ModalChangeRequest: React.FC<ModalChangeRequestProps> = ({
	isOpen,
	onClose,
	activeField,
	company,
}) => {
	const { t } = useTranslate();

	const handleClose = () => {
		formik.resetForm();
		onClose();
	};

	const getValidationSchema = (fieldType: string) => {
		if (fieldType === 'companyName') {
			return Yup.object({
				newValue: Yup.string().required('New company name is required'),
			});
		}
		if (fieldType === 'primaryEmail') {
			return Yup.object({
				newValue: Yup.string()
					.email('Invalid email address')
					.required('New primary email is required'),
			});
		}
		return Yup.object({
			newValue: Yup.string().required('This field is required'),
		});
	};

	const formik = useFormik({
		initialValues: {
			newValue: '',
		},
		validationSchema: getValidationSchema(activeField?.type || ''),
		onSubmit: async (values) => {
			try {
				const body: ChangeRequest = {
					newEmail:
						activeField?.type === 'primaryEmail'
							? values.newValue
							: company?.contact?.email!,
					newName:
						activeField?.type === 'companyName'
							? values.newValue
							: company?.name!,
				};

				await CompanyService.myCompanyChangeRequest(company?.companyTypes[0], {
					...body,
				});
			} catch (e) {
			} finally {
				handleClose();
			}
		},
	});

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={handleClose}
			className="w-[660px] modal-inside outline-none box-border"
			overlayClassName="modal-overlay"
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<form onSubmit={formik.handleSubmit} className="flex flex-col">
				<div className="flex items-center justify-between p-3">
					<SypacText variant="body-regular-medium">
						<p className="text-gray-90">
							{activeField?.type === 'companyName' ? (
								<T keyName="modalChangeRequest.companyNameChangeRequest">
									Change company name request
								</T>
							) : (
								<T keyName="modalChangeRequest.primaryEmailChangeRequest">
									Change primary email request
								</T>
							)}
						</p>
					</SypacText>
					<SypacButton variant="subTitle">
						<button
							type="button"
							className="flex justify-center items-center h-[32px] w-[32px] p-0 bg-white border border-solid border-gray-10 transition hover:border-gray-60 rounded-lg"
							onClick={handleClose}
						>
							<Close width="10" height="10" />
						</button>
					</SypacButton>
				</div>
				<div className="border-0 border-solid border-t-[1px] border-gray-10" />

				<div className="flex flex-col gap-3 p-3">
					<div className="flex flex-col p-[10px] border border-dashed border-gray-20 rounded-10 bg-alabaster">
						<SypacInput disabled={true}>
							<SypacText className="mb-[4px]" variant="overline-normal-large">
								<p>
									{activeField?.type === 'companyName' ? (
										<T keyName="userSettings.companyName">Company Name</T>
									) : (
										<T keyName="userSettings.primaryEmailAddress">
											Primary email address
										</T>
									)}
								</p>
							</SypacText>
							<input
								readOnly={true}
								className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
								name={activeField?.type!}
								type="text"
								value={activeField?.value}
							/>
						</SypacInput>
					</div>

					<SypacInput
						error={!!(formik.touched.newValue && formik.errors.newValue)}
					>
						<SypacText className="mb-[4px]" variant="overline-normal-large">
							<p>
								{activeField?.type === 'companyName' ? (
									<T keyName="modalChangeRequest.newCompanyName">
										New company name
									</T>
								) : (
									<T keyName="modalChangeRequest.newPrimaryEmail">
										New primary email
									</T>
								)}{' '}
								<span className="text-red">*</span>
							</p>
						</SypacText>
						<input
							className="w-full py-[11px] pl-3 border rounded-lg placeholder:text-gray-22"
							name="newValue"
							type="text"
							placeholder={
								activeField?.type === 'companyName'
									? t(
											'modalChangeRequest.enterNewCompanyName',
											'Enter new company name',
									  )
									: t(
											'modalChangeRequest.enterNewPrimaryEmail',
											'Enter new primary email',
									  )
							}
							value={formik.values.newValue}
							onChange={formik.handleChange}
						/>
						{formik.touched.newValue && formik.errors.newValue ? (
							<span className="bottom-helper">{formik.errors.newValue}</span>
						) : null}
					</SypacInput>
				</div>

				<div className="flex w-full py-2.5 xl-2xl:py-3 gap-3 [&_button]:transition border border-solid border-gray-10 border-b-0 border-l-0 border-r-0 rounded-xl mt-auto">
					<SypacButton
						variant="secondary"
						size="small"
						className="w-full pl-2.5 xl-2xl:pl-3"
					>
						<button
							type="reset"
							className="w-full py-2.5 rounded-lg"
							onClick={handleClose}
						>
							<SypacText variant="body-regular-medium">
								<p>
									<T keyName="modalChangeRequest.cancel">Cancel</T>
								</p>
							</SypacText>
						</button>
					</SypacButton>
					<SypacButton
						variant="primary"
						size="small"
						className="w-full pr-2.5 xl-2xl:pr-3"
					>
						<button
							type="submit"
							disabled={formik.isSubmitting}
							className="w-full py-2.75 rounded-lg"
						>
							<SypacText variant="body-regular-medium">
								<p className="text-white">
									{formik.isSubmitting ? (
										<T keyName="modalChangeRequest.sending">Sending...</T>
									) : (
										<T keyName="modalChangeRequest.sendRequest">Send request</T>
									)}
								</p>
							</SypacText>
						</button>
					</SypacButton>
				</div>
			</form>
		</Modal>
	);
};

export default ModalChangeRequest;
