import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../../containers/NotFound';
import Products from '../../containers/Producer/Products';
import TopBar from '../../components/TopBar/TobBar';
import ProducerGuardedRoute from '../guards/ProducerGuardedRoute';
import OrdersList from '../../containers/Producer/OrdersList';
import ProducerBilling from '../../containers/Producer/ProducerBilling';
import { useTranslate } from '@tolgee/react';
import { SideMenu } from '../../components/SideBarMenu/SideMenu';
import UserSettings from '../../containers/UserSettings';
import ProductStores from '../../containers/Producer/ProductStores';
import SelectProductGroup from '../../containers/Producer/AddNewProduct/SelectProductGroup';
import SelectProductCategory from '../../containers/Producer/AddNewProduct/SelectProductCategory';
import SelectProduct from '../../containers/Producer/AddNewProduct/SelectProduct';

const ProducerRouter: React.FC = () => {
	const { t } = useTranslate();

	const PRODUCER_MENU = [
		{
			logo: 'Document Add',
			alt: t('producerRouter.orders', 'Orders'),
			count: true,
			url: '/orders',
		},
		{
			logo: 'Shop 2',
			alt: t('producerRouter.My stores', 'My stores'),
			url: '/stores',
		},
		{
			logo: 'Money Bag',
			alt: t('producerRouter.billing', 'Billing'),
			url: '/billing',
		},
	];

	return (
		<Routes>
			<Route
				path="/products/:storeId"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<Products />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="products/:storeId/groups"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<SelectProductGroup />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="products/:storeId/groups/:groupId/categories"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<SelectProductCategory />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/products/:storeId/groups/:groupId/categories/:categoryId"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<div className="h-full w-full flex flex-col items-center justify-center">
								<SelectProduct />
							</div>
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/orders"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<OrdersList />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/calendar"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<SideMenu groups={PRODUCER_MENU}>
								<div />
							</SideMenu>
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/billing"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<ProducerBilling />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/people"
				element={
					<ProducerGuardedRoute>
						<section className="flex flex-col gap-7.5 p-7.5 min-h-[calc(100vh-60px)] h-[calc(100vh-60px)]">
							<TopBar />
							<SideMenu groups={PRODUCER_MENU}>
								<div />
							</SideMenu>
						</section>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/settings"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<UserSettings />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route
				path="/stores"
				element={
					<ProducerGuardedRoute>
						<SideMenu groups={PRODUCER_MENU}>
							<ProductStores />
						</SideMenu>
					</ProducerGuardedRoute>
				}
			/>
			<Route path="/*" element={<NotFound />} />
		</Routes>
	);
};

export default ProducerRouter;
