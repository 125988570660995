import React, { useState } from 'react';
import {
	SypacText,
	SypacBookTabs,
	SypacTabBook,
} from '@sypac/component-library-react';
import { useTranslate, T } from '@tolgee/react';
import ProducerAndCarrierBilling from './components/ProducerAndCarrierBilling';
import ClientBillingForAdmin from './components/ClientBillingForAdmin';
import { Sorting } from '../../../components/Sorting/Sorting';
import { SortingItemInterface } from '../../../components/Sorting/Sorting.interface';

const Billing: React.FC = () => {
	const { t } = useTranslate();
	const [tab, setTab] = useState<string>('client');
	const sortOptions = [
		{
			title: t('adminProducts.poland', 'Poland'),
			value: 'PL',
		},
		{
			title: t('adminProducts.moldova', 'Moldova'),
			value: 'MD',
		},
	];
	const [sorting, setSorting] = useState<SortingItemInterface>();

	return (
		<>
			<div className="relative flex flex-col gap-5 w-[calc(100vw-67px)] xl-2xl:w-[calc(100vw-83px)] h-full">
				<div className="flex gap-6 items-center ml-[30px]">
					<SypacText variant="heading-4">
						<p className="text-gray-80">
							<T keyName="adminBilling.payments">Payments</T>
						</p>
					</SypacText>
					<SypacText variant="body-regular-medium">
						<p className="text-gray-10">|</p>
					</SypacText>
					<Sorting
						options={sortOptions}
						action={(item) => setSorting(item)}
						current={sorting}
						label={{
							title: 'Country:',
							key: 'country',
						}}
						customDefault={{
							title: 'All',
							key: 'all',
						}}
					/>
				</div>

				<div className="flex justify-between border-0 border-solid border-b-[1px] border-gray-10">
					<div className="w-[616px] ml-[30px]">
						<SypacBookTabs className="mt-3.5">
							<SypacTabBook
								label={t('billing.client', 'Buyer')}
								button-id="client"
								onClick={() => setTab('client')}
							/>
							<SypacTabBook
								label={t('billing.producer', 'Seller')}
								button-id="producer"
								onClick={() => setTab('producer')}
							/>
							<SypacTabBook
								label={t('billing.transporter', 'Carrier')}
								button-id="transporter"
								onClick={() => setTab('transporter')}
							/>
						</SypacBookTabs>
					</div>
				</div>

				<div className="flex h-full">
					{tab === 'transporter' ? (
						<ProducerAndCarrierBilling
							target="transporter"
							countryQuery={sorting?.value}
						/>
					) : tab === 'producer' ? (
						<ProducerAndCarrierBilling countryQuery={sorting?.value} />
					) : (
						<ClientBillingForAdmin countryQuery={sorting?.value} />
					)}
				</div>
			</div>
		</>
	);
};

export default Billing;
